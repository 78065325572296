import React,{useEffect, useState} from 'react'
import arrow_right from '../../assets/icons/arrow_blue_right.svg'
import bank_icon from '../../assets/icons/bank.svg'
import alert_circle from '../../assets/icons/alert-circle.svg'
import arrow_right_circle from '../../assets/icons/arrow-right-circle.svg'
import check_circle from '../../assets/icons/check.svg'
import { Form, Formik } from 'formik'
import { withdrawValidator } from '../../validationSchema/validator'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getWalletBalance, userWithdrawFunds } from '../../redux/Auth/authActions'
import { clearWithdrawSuccess } from '../../redux/Auth/auth'

const WithdrawPage = () => {

    const dispatch = useDispatch()
    const {accountDetails,countryTag, walletBalance, loading, withdrawSuccess} = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const [step, setStep] = useState(1)

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    const [selectedAcct, setSelectedAcct] = useState({})
    const handleAcctSelect = (id) =>{
        let res =  accountDetails.find((pro) => pro.id === id)
        console.log(res)
        setStep(2)
        setSelectedAcct(res)
    }

    const handleSubmit = (values) => {
        let res;
        let type = selectedAcct.accountType;

        if (type === "Bank") {
            res = { 
              bankAccountId: selectedAcct.id,
              accountType: selectedAcct.accountType,
              narration: values.narration,
              amount: parseFloat(values.amount),
              pin: parseInt(values.pin),
            };
          } else {
            res = {
              bankAccountId: selectedAcct.id,
              accountType: selectedAcct.accountType,
              narration: values.narration,
              amount: parseFloat(values.amount),
              pin: parseInt(values.pin),
            };
          }
        dispatch(userWithdrawFunds(res))
    }

    useEffect(()=>{
        if(withdrawSuccess){
            navigate('/transactions')
            setTimeout(() => {
                dispatch(clearWithdrawSuccess())
            }, 1000);
        }
    },[withdrawSuccess, dispatch, navigate])

    useEffect(()=>{
        dispatch(getWalletBalance())
    },[dispatch])



    return ( 
        <>
        {accountDetails && accountDetails.length > 0 ? (
            <>
          {
            step === 1 &&
        
             <div className='max-w-xl mx-auto mt-20'>
                <div className='text-center'>
                    <p className='text-[#FFFFFF] text-sm font-Gilroy500'>Your Wallet Balance is <span className='text-[#9CC031CC]'>{countryTag === "GH" ? "GH₵" : "NGN"} {walletBalance ? numberWithCommas(walletBalance): 0.00}</span> </p>
                </div>
                <div className='mt-4 flex justify-between items-center'>
                    <div>
                        <h6 className='text-[#F1F5F6] text-base'>Select account to withdraw into</h6>
                    </div>
                    <div className='flex gap-2'>
                           <div className='bg-[#FF8400] w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#454545] w-[10px] h-[10px] rounded-full'></div>
                    </div>
                </div>
                
                {/* accounts */}
                
                {
                accountDetails.map((val) =>(
                    <>
                     <div key={val.id} className='mt-4 bg-[#262422] shadow-[#F1F5F60A] flex justify-between items-center rounded-[10px] py-8 px-5'>
                    <div className='flex gap-3 items-center'>
                        <div className='bg-[#3B95FD] w-[50px] h-[50px] rounded-[10px] flex justify-center items-center'>
                            <img src={bank_icon} alt="bank" />
                        </div>
                        <div>
                            <h6 className='text-[#F1F5F6] text-sm font-Gilroy500'>{val.accountName}</h6>
                            <p className='text-[#F1F5F6] text-xs font-Gilroy400'>
                            {val.accountType === "Bank"
                                  ? val.accountNumber
                                  : val.mobileNumber
                                }
                            </p>
                            <p className='text-[#F1F5F6] text-xs font-Gilroy400'>
                            {val.accountType === "Bank"
                                  ? val.bankName
                                  : val.mobileNetwork
                                }
                            </p>
                        </div>
                    </div>
                    <div onClick={() => handleAcctSelect(val.id)} className='cursor-pointer'>
                        <img src={arrow_right} alt="arrow right" className='w-[30px] h-[30px]' />
                    </div>
                </div>
                    </>
                ))}
                
                <div className='mt-4 flex gap-2 items-center justify-center'>
                    <img src={alert_circle} alt="alert circle" className='w-[20px] h-[20px]' />
                    <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Preferred account does not exist yet..</p> 
                </div>

                <Link to="/account-details/add" className='flex justify-center mt-2 gap-2 cursor-pointer'>
                    <img src={arrow_right_circle} alt="arrow right" className='w-[20px] h-[20px]' />
                    <p className='text-[#3B95FD] text-sm font-Gilroy400'>Add Bank Account</p>
                </Link>


            </div>
            }

            {/* second step */}
          {
            step === 2 &&
            <div className='max-w-xl mx-auto mt-20'>
                <div className='text-center'>
                    <p className='text-[#FFFFFF] text-sm font-Gilroy500'>Your Wallet Balance is <span className='text-[#9CC031CC]'>{countryTag === "GH" ? "GH₵" : "NGN"} {walletBalance ? numberWithCommas(walletBalance): 0.00}</span> </p>
                </div>
                <div className='mt-4 flex justify-between items-center'>
                    <div>
                        <h6 className='text-[#F1F5F6] text-base'>Add withdrawal amount</h6>
                    </div>
                    <div className='flex gap-2'>
                           <div onClick={() => setStep(1)} className='bg-[#454545] cursor-pointer w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#FF8400] cursor-pointer  w-[10px] h-[10px] rounded-full'></div>
                    </div>
                </div>

                <p className='text-[#F1F5F6] text-sm font-Gilroy400 mt-6'>Account Selected</p>
                <div className='mt-1 bg-[#262422] shadow-[#F1F5F60A] flex justify-between items-center rounded-[10px] py-8 px-5'>
                    <div className='flex gap-3 items-center'>
                        <div className='bg-[#3B95FD] w-[50px] h-[50px] rounded-[10px] flex justify-center items-center'>
                            <img src={bank_icon} alt="bank" />
                        </div>
                        <div>
                            <h6 className='text-[#F1F5F6] text-sm font-Gilroy500'>{selectedAcct && selectedAcct.accountName ? selectedAcct.accountName: 'N/A'}</h6>
                            <p className='text-[#F1F5F6] text-xs font-Gilroy400'>
                           {selectedAcct && selectedAcct.accountType ? selectedAcct.accountType === "Bank"
                                  ? selectedAcct.bankName
                                  : selectedAcct.mobileNetwork
                                  :
                                  'N/A'
                                }
                            </p> 
                            <p className='text-[#F1F5F6] text-xs font-Gilroy400'>
                            {selectedAcct && selectedAcct.accountType ? selectedAcct.accountType === "Bank"
                                  ? selectedAcct.accountNumber
                                  : selectedAcct.mobileNumber
                                  :
                                  'N/A'
                                }
                            </p>
                        </div>
                    </div>
                    <div className='bg-[#9CC031CC] w-[30px] h-[30px] flex justify-center items-center rounded-[8px]'>
                        <img src={check_circle} alt="check circle" />
                    </div>
                </div>

                  <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={withdrawValidator}
                        initialValues={{
                            narration: "",
                            pin: "",
                            amount: ""
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                             <div>
                            <label htmlFor='amount' className="block mb-2 text-[#F1F5F6] text-xs">
                           Withdrawal Amount
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="amount"
                                placeholder="Enter your amount"
                                className={
                                    touched.amount && errors.amount
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='narration' className="block mb-2 text-[#F1F5F6] text-xs">
                            Narration
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="narration"
                                placeholder="Narration"
                                className={
                                    touched.narration && errors.narration
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.narration}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.narration && errors.narration ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.narration && errors.narration}
                                </small>
                            ) : null}
                            </div>
                               
                            <div className="mt-6">
                            <label htmlFor='pin' className="block mb-2 text-[#F1F5F6] text-xs">
                            Transaction Pin
                            </label>
                            <div className="relative">
                                <input
                                type="password"
                                name="pin"
                                placeholder="Enter your pin"
                                className={
                                    touched.pin && errors.pin
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.pin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.pin && errors.pin ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.pin && errors.pin}
                                </small>
                            ) : null}
                            </div>
                        
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                               Withdraw Funds
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>

                    <Link to="/security/reset-pin" className='mt-3 flex gap-2 items-center justify-center cursor-pointer'>
                        <img src={alert_circle} alt="alert circle" className='w-[20px] h-[20px]' />
                        <p className='text-[#3B95FD] text-sm font-Gilroy400'>Forgot Transaction Pin !</p> 
                    </Link>

            </div>
            }
            </>
         )
        :
        <>
         {/* no accounts added */}
         <div className='flex flex-col justify-center items-center min-h-[400px] md:min-h-[500px]'>
            <div className='bg-[#262422] shadow-[#F1F5F60A] border border-[#454545] border-dashed w-full md:w-3/5 mx-auto py-10 px-6 rounded-[10px] '>

                <div className='flex gap-2 items-center justify-center'>
                    <img src={alert_circle} alt="alert circle" className='w-[20px] h-[20px]' />
                    <p className='text-[#F1F5F6] text-sm font-Gilroy400'>You currently have no bank account added for withdrawal</p> 
                </div>

                <Link to="/account-details/add" className='flex justify-center mt-4 gap-2 cursor-pointer'>
                <img src={arrow_right_circle} alt="arrow right" className='w-[20px] h-[20px]' />
                <p className='text-[#3B95FD] text-sm font-Gilroy400'>Add Bank Account</p>
                </Link>
            </div>
         </div>
        </>
            }
        

      
        </>
     );
}
 
export default WithdrawPage;