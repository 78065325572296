import React, { useEffect, useState } from 'react'
import logo from '../../assets/icons/logo-white.svg'
import chevron_left from '../../assets/icons/chevron-left.svg'
import world from '../../assets/images/world.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { resetPasswordValidator } from '../../validationSchema/validator'
import { useDispatch, useSelector } from 'react-redux'
import { userResetPassword, verifyResetCode } from '../../redux/Auth/authActions'
import { clearResetSuccess } from '../../redux/Auth/auth'
import eye from '../../assets/icons/eye.svg'
import eye_off from '../../assets/icons/eye-slash.svg'

const ResetPasswordPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const search = useLocation().search;
    const {loading,isResetSuccess} = useSelector((state) => state.auth);

    const [passwordShown, setPasswordShown] = useState(false)
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const toggleConfirmPasswordVisiblity = () => {
        setPasswordConfirmShown(passwordConfirmShown ? false : true);
    };


    useEffect(()=>{
      const code = new URLSearchParams(search).get("code");
      if(code){
        dispatch(verifyResetCode({
            code: code
        }))
      }
    },[dispatch, search])

    const handleSubmit = (values) => {
        const code = new URLSearchParams(search).get("code");
        console.log(values)
        dispatch(userResetPassword({
            code,
            password: values.password
        }))
    }

    useEffect(()=>{
        if(isResetSuccess){
            navigate('/')
            setTimeout(()=>{
                dispatch(clearResetSuccess())
            },1000)
        }
    },[isResetSuccess, dispatch, navigate])

    return ( 
        <>
        <div className='bg-[#1B1A20] font-Gilroy400 min-h-screen'>
            <div className='py-6 px-4 container mx-auto'>
                <div className='flex items-center justify-between'>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                    <Link to="/" className='flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[8px] h-[8px]' />
                        <p className='text-[#EAECED80] text-sm'>Back to Home</p>
                    </Link>
                </div>

                <div className='grid md:grid-cols-2 mt-20 items-center gap-8'>
                    <div>
                        <h4 className='text-[#F1F5F6] text-xl md:text-3xl'>Create New Password</h4>
                        <p className='text-[#F1F5F6] text-sm'>
                        Create a new unique password
                        </p>

                        <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={resetPasswordValidator}
                        initialValues={{
                        password: "", 
                        confirm_password: ""
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>
                            <div className="">
                            <label htmlFor='password' className="block mb-2 text-[#F1F5F6] text-xs">
                                Password
                            </label>
                            <div className="relative">
                                <input
                                 type={passwordShown ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter your password"
                                className={
                                    touched.password && errors.password
                                        ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                  <img
                                onClick={togglePasswordVisiblity}
                                src={passwordShown ? eye_off : eye}
                                alt="eye"
                                className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                              
                            </div>
                            {touched.password && errors.password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.password && errors.password}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='confirm_password' className="block mb-2 text-[#F1F5F6] text-xs">
                                Confirm Password
                            </label>
                            <div className="relative">
                                <input
                                 type={passwordConfirmShown ? 'text' : 'password'}
                                name="confirm_password"
                                placeholder="Confirm your password"
                                className={
                                    touched.confirm_password && errors.confirm_password
                                        ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                  <img
                                onClick={toggleConfirmPasswordVisiblity}
                                src={passwordConfirmShown ? eye_off : eye}
                                alt="eye"
                                className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                              
                            </div>
                            {touched.confirm_password && errors.confirm_password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.confirm_password && errors.confirm_password}
                                </small>
                            ) : null}
                            </div>

                        
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                              Submit
                            </button>
                            </div>

                           
                            <div className='mt-6 text-center md:text-left'>
                                <p className='text-[#F1F5F6] text-sm'>Remember Password? <Link to="/" className='text-[#3B95FD] font-Satoshi500 text-sm'>Back to Login</Link> </p>
                            </div>

                        
                        </Form>
                        )}
                    </Formik>

                    </div>
                    <div className='hidden md:block'>
                        <div className='relative'>
                          <img src={world} alt="world" className='bg-opacity-50' />
                            <div className='max-w-sm mx-auto text-center absolute top-1/2 left-1/2 transform -translate-y-1/2  -translate-x-1/2'>
                                <h4 className='text-[#F1F5F6] text-3xl font-Gilroy700 leading-10'>Secure and Reliable <br/> Crypto Solutions.</h4>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div className='mt-28'>
                    <p className='text-[#F1F5F6] text-center md:text-left text-sm'>&copy; {new Date().getFullYear()} DartAfrica All rights reserved</p>
                </div>
            </div>
           
        </div>
        </>
     );
}
 
export default ResetPasswordPage;