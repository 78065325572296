import React, { useEffect } from 'react'
import logo from '../../assets/icons/logo-white.svg'
import chevron_left from '../../assets/icons/chevron-left.svg'
import world from '../../assets/images/world.svg'
import { Link,useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { registerValidator } from '../../validationSchema/validator'
import { useDispatch, useSelector } from 'react-redux'
import { userRegister } from '../../redux/Auth/authActions'
import { clearSignUpSuccess } from '../../redux/Auth/auth'

const RegisterPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {signup_success, loading} = useSelector((state) => state.auth);

    const handleSubmit = (values) => {
        console.log(values)
        dispatch(userRegister(values))
    }

    useEffect(()=>{
        if(signup_success){
            navigate('/verifyemail/auth/activate')
            setTimeout(()=>{
                dispatch(clearSignUpSuccess())
            },1000)
        }
    },[signup_success, navigate, dispatch])

    return ( 
        <>
        <div className='bg-[#1B1A20] font-Gilroy400 min-h-screen'>
            <div className='py-6 px-4 container mx-auto'>
                <div className='flex items-center justify-between'>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                    <Link to="/" className='flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[8px] h-[8px]' />
                        <p className='text-[#EAECED80] text-sm'>Back to Login</p>
                    </Link>
                </div>

                <div className='grid md:grid-cols-2 mt-16 md:mt-20 items-center gap-8'>
                    <div>
                        <h4 className='text-[#F1F5F6] text-xl md:text-3xl'>Sign Up</h4>
                        <p className='text-[#F1F5F6] text-sm'>Create New Account</p>

                        <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={registerValidator}
                        initialValues={{
                            userName: "",
                            email: "",
                            phoneNumber: "",
                            password: "",
                            confirm_password: "",   
                            countryTag: "GH",
                            acceptTerms: "false",
                            referralCode: ""
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        setFieldValue,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='userName' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Username
                            </label>
                            <input
                                type="text"
                                name="userName"
                                placeholder="Enter your username"
                                className={
                                touched.userName && errors.userName
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.userName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.userName && errors.userName ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.userName && errors.userName}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='email' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Email Address
                            </label>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter your email address"
                                className={
                                touched.email && errors.email
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.email && errors.email}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='countryTag' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Country
                            </label>
                            <select
                                type="text"
                                name="countryTag"
                                placeholder="Enter your username"
                                className={
                                touched.countryTag && errors.countryTag
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.countryTag}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="GH">Ghana</option>
                                <option value="NG">Nigeria</option>
                                </select>
                            {touched.countryTag && errors.countryTag ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.countryTag && errors.countryTag}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='phoneNumber' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Phone Number
                            </label>
                            <input
                                type="text"
                                name="phoneNumber"
                                placeholder="Enter your phone number"
                                className={
                                touched.phoneNumber && errors.phoneNumber
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.phoneNumber && errors.phoneNumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phoneNumber && errors.phoneNumber}
                                </small>
                            ) : null}
                            </div>


                            <div className="mt-6">
                            <label htmlFor='password' className="block mb-2 text-[#F1F5F6] text-xs">
                                Password
                            </label>
                            <div className="relative">
                                <input
                                type="password"
                                name="password"
                                placeholder="Enter your password"
                                className={
                                    touched.password && errors.password
                                        ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.password && errors.password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.password && errors.password}
                                </small>
                            ) : null}
                            </div>


                            <div className="mt-6">
                            <label htmlFor='confirm_password' className="block mb-2 text-[#F1F5F6] text-xs">
                                Confirm Password
                            </label>
                            <div className="relative">
                                <input
                                type="password"
                                name="confirm_password"
                                placeholder="Confirm your password"
                                className={
                                    touched.confirm_password && errors.confirm_password
                                        ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.confirm_password && errors.confirm_password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.confirm_password && errors.confirm_password}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='referralCode' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Referral Code (Optional)
                            </label>
                            <input
                                type="text"
                                name="referralCode"
                                placeholder="Enter referral code"
                                className={
                                touched.referralCode && errors.referralCode
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.referralCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.referralCode && errors.referralCode ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.referralCode && errors.referralCode}
                                </small>
                            ) : null}
                            </div>
                                
                            <div className="flex items-center mt-4">
                                <input 
                                 id="acceptTerms" 
                                 values={values.acceptTerms}
                                 name="acceptTerms"
                                 onBlur={handleBlur}
                                 onChange={(e) =>
                                   setFieldValue(
                                     "acceptTerms",
                                     e.target.checked.toString()
                                   )
                                 }
                                type="checkbox" value="" className="w-4 h-4 text-[#407BFF] bg-transparent border-[#407BFF] rounded focus:ring-blue-500 focus:ring-0" />
                                <label
                                  htmlFor="acceptTerms"
                                  className={
                                    (errors.acceptTerms && touched.acceptTerms
                                      ? "ml-2 text-sm text-[#DB2424] font-[300]"
                                      : "ml-2 text-sm text-[#F1F5F6]")
                                  }
                                   >
                                I Agree to DartAfrica <Link to="https://dartafrica.io/term-of-use/" target='_blank'  className='text-[#407BFF]'>Terms</Link>
                                </label>
                            </div>


                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Sign Up
                            </button>
                            </div>

                           
                            <div className='mt-6 text-center md:text-left'>
                                <p className='text-[#F1F5F6] text-sm'>Already have an account? <Link to="/" className='text-[#3B95FD] font-Satoshi500 text-sm'>Sign in now</Link> </p>
                            </div>

                        
                        </Form>
                        )}
                    </Formik>

                    </div>
                    <div className='hidden md:block'>
                        <div className='relative'>
                          <img src={world} alt="world" className='bg-opacity-50' />
                            <div className='max-w-sm mx-auto text-center absolute top-1/2 left-1/2 transform -translate-y-1/2  -translate-x-1/2'>
                                <h4 className='text-[#F1F5F6] text-3xl font-Gilroy700 leading-10'>The Future is here, <br/> are you Ready?</h4>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div className='mt-28'>
                    <p className='text-[#F1F5F6] text-center md:text-left text-sm'>&copy; {new Date().getFullYear()} DartAfrica All rights reserved</p>
                </div>
            </div>
           
        </div>
        </>
     );
}
 
export default RegisterPage;