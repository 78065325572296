import React,{useEffect, useState} from 'react'
import illustration_icon from '../../assets/images/illustration.svg'
import arrow_right from '../../assets/icons/arrow-right-circle.svg'
import trending_up from '../../assets/icons/trending-up.svg'
import trending_down from '../../assets/icons/trending-down.svg'
import layers_icon from '../../assets/icons/layers.svg'
import arrow_up from '../../assets/icons/arrow-up.svg'
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux'
import { fetchTrades } from '../../redux/Trade/tradeActions'
import Moment from "react-moment";
import moment from "moment";
import ModalComponent from '../../components/Modals/modal'
import arrow_blockchain from '../../assets/icons/arrow_blockchain.svg'
import { Link, useNavigate } from 'react-router-dom'
import { getUserSummary, getWalletBalance } from '../../redux/Auth/authActions'
import {useFormik} from 'formik'
import { getCoins, sellCoinTrade } from '../../redux/Rates/rateActions'
import { rateValidator } from '../../validationSchema/validator'
import chevron_down from '../../assets/icons/chevron_down.svg'
import { clearCoinValues, CoinRateCalculation, CoinUSDRateCalculation } from '../../redux/Rates/rate'


const DashboardPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading, trades} = useSelector((state) => state.trade);
    const {countryTag, walletBalance, pendingTrade, userSummary} = useSelector((state) => state.auth);
    const {coins,usdAmount,coinAmount, ngAmount, ghAmount, loader, tradeSuccess} = useSelector((state) => state.rate);
    const [tradeModal, setTradeModal] = useState(false)

    const showTradeModal = () =>{
        setTradeModal(!tradeModal)
      }

      const {
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        handleChange,
        handleSubmit,
      } = useFormik({
        initialValues: {
          coin: "",
          amount: coinAmount ? coinAmount : "",
          usdAmount: usdAmount ? usdAmount : "",
        },
        onSubmit(values, { setSubmitting }) {
          handSubmit(values, setSubmitting);
        },
        validationSchema: rateValidator,
      });

      const [coinError, setcoinError] = useState(false)

      const handSubmit = (values) => {
        // check for usd amount if it is lesser than $2
        var coinId = values.coin;
        var coinType = coins.find((pro) => pro.id === coinId).coinType;
        let creds = {
            coinType: coinType,
            narration: "Trade Coin",
            amounttInCoin: parseFloat(values.amount.toFixed(6)),
            amountInLocalCurrency: countryTag === "GH" ? ghAmount.toFixed(2) : ngAmount.toFixed(2),
            country: countryTag === "GH" ? "GH" : "NG",
            amountInUsd: parseFloat(values.usdAmount.toFixed(2))
        }
            dispatch(sellCoinTrade(creds))
        
   }


   useEffect(()=>{
        if(tradeSuccess){
            navigate('/sellcoin/complete')
        }
    },[tradeSuccess, navigate])

   const handleCoinCalc = (amount, coin) =>{
    var pattern = /^[0-9]*.?[0-9]*$/;
    var amountValid = pattern.test(amount);
    if (coin !== "") {
      if (amountValid && amount !== "") {
        dispatch(CoinRateCalculation({
            amount,
            id:coin
        }))
      }
    }
    else{
        setcoinError(true)
    } 
}

const handleRateCalc = (amount, coin) =>{
    var pattern = /^[0-9]*.?[0-9]*$/;
    var amountValid = pattern.test(amount);
    if (coin !== "") {
      if (amountValid && amount !== "") {
        dispatch(CoinUSDRateCalculation({
            amount,
            id:coin
        }))
      }
    }
    else{
        setcoinError(true)
    }   
}

useEffect(() => {
    setFieldValue("usdAmount", usdAmount);
    setFieldValue("amount", coinAmount)
  }, [usdAmount, coinAmount, setFieldValue]);



  const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Created":
            result = "pending-badge";
            break;
          case "Confirmed":
            result = "success-badge";
            break;
          case "Failed":
            result = "failed-badge";
            break;
          default:
            result = "failed-badge";
            break;
        }
        return result;
      };

      const columns = [
        {
            name: 'Coin',
            selector: (row) => <span>{`${row.amounttInCoin} ${row.coinType}`}</span>,
        },
        {
            name: 'Amount Due',
            cell: (row) => <span>{`${countryTag === "GH" ? "GH₵" : "NGN"} ${row.amountInLocalCurrency}`}</span>,
        },
        {
            name: 'Date',
            cell: (row) => (
                <span>
                  <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
                </span>
            )
        },
        {
            name: 'Status',
            cell: (row) => (
                <span className={getStatusColor(row.tradeStatus)}>{`${row.tradeStatus}`}</span>
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <button
                onClick={() => {
                    ViewTrade(row.id);
                  }}
                className="border transition-all hover:bg-[#FF8400] hover:text-white border-[#FF8400] text-[#FF8400] rounded-[4px] font-Gilroy500 text-xs py-2 px-4"
                >
                View
                </button>
            ),
        },
    ];

    const [tradeInfo, setTradeInfo] = useState({})

    const ViewTrade = (id) =>{
        setTradeModal(true)
        let res = trades.find((val)=> val.id === id)
        setTradeInfo(res)
        console.log(res)
    }
    
    useEffect(()=>{
        dispatch(fetchTrades())
        dispatch(getWalletBalance())
        dispatch(getUserSummary())
        dispatch(getCoins())
        dispatch(clearCoinValues())
    },[dispatch])

    


    return ( 
        <>

          {/* Trade details Modal */}
          <ModalComponent title="Trade Details" show={tradeModal} showModal={showTradeModal}>
            <div className='mt-10'>
                <div className='flex justify-between'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Trade Reference</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.id ? tradeInfo.id: 'N/A'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Date</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.createdAt ? moment(tradeInfo.createdAt).format("MMMM Do, YYYY"): 'N/A'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Currency Traded</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.coinType ? tradeInfo.coinType: 'N/A'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Trade Type</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>Sell</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Amount Traded</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.amounttInCoin ? tradeInfo.amounttInCoin: 'N/A'} {tradeInfo && tradeInfo.coinType ? tradeInfo.coinType: 'N/A'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Amount</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{countryTag === "GH" ? "GH₵" : "NGN"} {tradeInfo && tradeInfo.amountInLocalCurrency ? tradeInfo.amountInLocalCurrency: '0.00'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Wallet Address</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.walletAddress ? tradeInfo.walletAddress: 'N/A'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Trade Status</h6>
                        <div className='flex mt-1'>
                            <p className={tradeInfo && tradeInfo.tradeStatus ? getStatusColor(tradeInfo.tradeStatus) : 'pending-badge'}>{tradeInfo && tradeInfo.tradeStatus ? tradeInfo.tradeStatus: 'Created'}</p>
                        </div>

                    </div>
                   {
                    tradeInfo && tradeInfo.hostedUrl && tradeInfo.hostedUrl !== "" ?
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Block Confirmation</h6>
                        <a href={tradeInfo && tradeInfo.hostedUrl ? tradeInfo.hostedUrl : ""} target="_blank" rel="noreferrer" className='flex justify-end gap-1 mt-1 items-center cursor-pointer'>
                            <p className='text-[#3B95FD] text-xs font-Gilroy400'>View Blockchain</p>
                            <img src={arrow_blockchain} alt="arrow blockchain" />
                        </a>
                    </div>
                    :
                    ""
                    }
                </div>

                <div className="mt-10">
                        <Link
                        to="/sellcoin"
                            className="bg-[#FF8400] 
                            disabled:bg-[#FF8400] 
                            disabled:opacity-[0.7]
                            text-sm flex justify-center
                            rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                        >
                            Start Another Trade
                        </Link>
                        </div>
                 </div>
        </ModalComponent>

           <div className='grid md:grid-cols-3 gap-4'>
                <div className='md:col-span-2 bg-[#262422] shadow-[#F1F5F60A] rounded-[10px] py-3 px-6'>
                    <p className='text-[#FF8400] font-Gilroy500 text-base'>Quick Trade</p>

                    <form className="mt-6" onSubmit={handleSubmit}>
                        <div className='grid md:grid-cols-4 gap-4'>

                            <div className="">
                                <label htmlFor='coin' className="block mb-2 text-[#F1F5F6] text-xs">
                                  I want to sell
                                </label>
                                <div className='relative'>
                                <select
                                    name="coin"
                                    className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                    id="coin"
                                    onChange={(e) => {
                                        dispatch(clearCoinValues())
                                        handleChange(
                                          e,
                                          setFieldValue("amount", ''),
                                          setFieldValue("usdAmount",''),
                                          setcoinError(false)
                                        );
                                      }}
                                    onBlur={handleBlur}
                                    values={values.coin}
                                >
                                <option selected value="" disabled>
                                    Select a coin
                                    </option>
                                    {coins.map((opt, index) => {
                                        return (
                                        <option  key={index} value={opt.id}>
                                            {opt.coinType}
                                        </option>
                                        );
                                    })}
                                </select>
                                <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                                </div>
                            
                                {(touched.coin && errors.coin) || coinError ? (
                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                           Coin is required
                                        </small>
                                    ) : null}    
                            </div>

                            <div className="">
                            <label htmlFor='amount' className="block mb-2 text-[#F1F5F6] text-xs">
                           Coin Amount 
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="amount"
                                placeholder="0.00"
                                className={
                                    touched.amount && errors.amount
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.amount}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleCoinCalc(e.currentTarget.value, values.coin);
                                  }}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>
                            

                            <div className="">
                            <label htmlFor='usdAmount' className="block mb-2 text-[#F1F5F6] text-xs">
                            USD Amount
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="usdAmount"
                                placeholder="0.00"
                                className={
                                    touched.usdAmount && errors.usdAmount
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.usdAmount}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleRateCalc(e.currentTarget.value, values.coin);
                                  }}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.usdAmount && errors.usdAmount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.usdAmount && errors.usdAmount}
                                </small>
                            ) : null}
                            </div>
                            

                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loader}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Sell
                            </button>
                            </div>

                        </div>  
                      </form>

                      <div className='flex justify-center mt-4'>
                            <p className='text-[#F1F5F6] md:text-base text-sm font-Gilroy500'>You get: {countryTag === "GH" ? "GH₵" : "NGN"} {countryTag === "GH" ? ghAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ngAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                      </div>
                         

                </div>
               <div className='bg-[#262422] flex gap-6 items-center shadow-[#F1F5F614] rounded-[10px] py-4 px-8'>
                    <div>
                        <img src={illustration_icon} alt="illustration" className='flex-shrink-0' />
                    </div>
                    <div>
                        <h4 className='text-[#F1F5F6] font-Gilroy700 text-lg'>Manage your account</h4>
                        <p className='text-[#F1F5F6] font-Gilroy400 opacity-[0.7] mt-1 text-sm'>
                        You can easily manage and update your account.
                        </p>
                        <Link to="/account-details" className='flex gap-2 items-center mt-4 cursor-pointer'>
                            <img src={arrow_right} alt="arrow right" />
                            <p className='text-[#3B95FD] font-Gilroy400 text-sm '>Manage Account</p>
                        </Link>
                    </div>
               </div>
           </div>
           <div className='grid md:grid-cols-4 gap-4 mt-6'>
                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#09C1DD] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={trending_up} alt="trending" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Total Balance</h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{countryTag === "GH" ? "GH₵" : "NGN"} {walletBalance ? numberWithCommas(walletBalance): 0.00}</h4>
                            </div>
                    </div>
                    <div className='bg-[#02B15A26] rounded-[10px] py-1 px-4 h-fit'>
                            <p className='text-[#02B15A] text-xs font-Gilroy400'>+1.29%</p>
                    </div>  
                </div>

                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#E41414CC] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={trending_down} alt="trending" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Total Withdrawal</h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{countryTag === "GH" ? "GH₵" : "NGN"} {userSummary ? userSummary.totalWithdraw : 0}</h4>
                            </div>
                    </div>
                     
                </div>

                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#3B95FD] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={layers_icon} alt="layers " />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>
                                Pending Trades
                                </h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{pendingTrade ? pendingTrade : 0}</h4>
                            </div>
                    </div>
                    <Link to="/trades" className='cursor-pointer'>
                      <img src={arrow_right} alt="arrow right" />
                    </Link>  
                </div>

                <Link to="/withdraw" className='bg-[#262422] flex items-end justify-between cursor-pointer shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex items-center gap-4'>
                            <div className='bg-[#9CC031CC] w-[45px] h-[45px] flex justify-center items-center rounded-full'>
                                 <img src={arrow_up} alt="arrow_up" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Withdraw Funds</h6>
                            </div>
                    </div>         
                </Link>

           </div>

           <div className='bg-[#262422] rounded-[12px] mt-5 py-4 px-4'>

            <div className=''>
                <DataTable
                    title="Recent Trades"
                    pagination
                    progressPending={loading}
                    persistTableHead
                        columns={columns}
                        data={trades}
                    />
            </div>
               
           </div>
        </>
     );
}
 
export default DashboardPage;