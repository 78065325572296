import React,{ useEffect, useState} from 'react'
import alert_circle from '../../assets/icons/alert-circle_orange.svg'
import copy_icon from '../../assets/icons/content-copy.svg'
import { useDispatch, useSelector } from 'react-redux'
import { clearTradeSuccess, clearTradeValues } from '../../redux/Rates/rate'
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cogoToast from 'cogo-toast'
import ModalComponent from '../../components/Modals/modal'
import tick_icon from '../../assets/icons/tick.svg'
import { useNavigate } from 'react-router'

const SellCoinCompletePage = () => {
    const dispatch = useDispatch()
    const {ghAmount,ngAmount, walletAddress, trade_display} = useSelector((state) => state.rate);
    const {countryTag} = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const [show, setShow] = useState(false)


    const showModal = () =>{
        setShow(!show)
      }

    const [completePayment, setCompletePayment] = useState(false)

    const handleCheck = (val) =>{
        console.log(val)
        setCompletePayment(!completePayment)
        if(val){
            setShow(true)
        }
    }


    const handleCopy = () => {
        cogoToast.success("Copied to clipboard");
    };


      const viewTradeHistory = () =>{
        dispatch(clearTradeValues())
        navigate("/trades")
      }

      useEffect(()=>{
        dispatch(clearTradeSuccess())
      },[dispatch])

    return (
        <>

   {/* Modal */}
   <ModalComponent title="" show={show} showModal={showModal}>
            <div className='mt-6'>
                <div className='flex justify-center'>
                    <div className='flex justify-center items-center bg-[#2D2A28] rounded-full w-[100px] h-[100px]'>
                        <img src={tick_icon} alt="danger icon" className='w-[80px] h-[80px]' />
                    </div>
                </div>
        
                <div className='flex justify-center mt-4'>
                    <p className='text-[#F1F5F6] text-sm text-center font-Gilroy400'>Trade Submitted! <br/> Your wallet will be credited soon..</p>
                </div>

              <div className='flex justify-center mt-8 mb-5'> 
                  <button
                    type="submit"
                    disabled={false}
                    onClick={viewTradeHistory}
                    className="bg-[#FF8400] 
                    disabled:bg-[#FF8400] 
                    disabled:opacity-[0.7]
                    text-sm
                    rounded-md px-10 py-3 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                >
                    View Trade History
                </button>           
             </div>

            </div>
        </ModalComponent>

        <div className='max-w-4xl mx-auto mt-5'>

        <div className='bg-[#262422] min-h-[150px] shadow-[#F1F5F60A] border-dashed border border-[#FF8400] rounded-[10px] py-6 px-8'>
                <p className='text-[#FF8400] font-Gilroy500 text-base'>Sell Coin</p>

            
                <form className="mt-6">
                <div className='grid md:grid-cols-3 gap-4'>

                    <div className="">
                        <label htmlFor='coin' className="block mb-2 text-[#F1F5F6] text-xs">
                        Coin Type
                        </label>
                        <div className="relative">
                        <input
                        type="text"
                        name="coin"
                        placeholder="Coin Type e.g BTC"
                        disabled
                        className='appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                        value={
                            trade_display && trade_display.coinType
                              ? trade_display.coinType
                              : "e.g BTC"
                          }
                        />
                        </div>    
                    </div>

                    <div className="">
                    <label htmlFor='amount' className="block mb-2 text-[#F1F5F6] text-xs">
                      Coin Amount 
                    </label>
                    <div className="relative">
                        <input
                        type="text"
                        name="amount"
                        placeholder="0.00"
                        disabled
                        className='appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                        value={
                            trade_display && trade_display.amounttInCoin
                              ? trade_display.amounttInCoin
                              : "e.g 0.00"
                          }
                        />
                    </div>
                 </div>
                    

                    <div className="">
                    <label htmlFor='usdAmount' className="block mb-2 text-[#F1F5F6] text-xs">
                    USD Amount
                    </label>
                    <div className="relative">
                        <input
                        type="text"
                        name="usdAmount"
                        placeholder="0.00"
                        disabled
                        className='appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                        value={
                            trade_display && trade_display.amountInUsd
                              ? trade_display.amountInUsd
                              : "e.g 0.00"
                          }
                        />  
                    </div>
                  
                    </div>
                
                </div>  
        </form>
                


        </div>
        <div className='flex gap-2 items-center justify-center mt-4'>
            <img src={alert_circle} alt="alert circle" className='w-[20px] h-[20px]' />
            <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Note your wallet will be credited immediately your transaction is confirmed</p> 
        </div>

        <div className='mt-3 text-center'>
            <h6 className='text-[#F1F5F6] font-Gilroy700 text-xl md:text-3xl'>{countryTag === "GH" ? "GH₵" : "NGN"} {countryTag === "GH" ? ghAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ngAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
            <p className=' text-[#F1F5F6CC] font-Gilroy400 text-base'>Total amount to be credited after trade</p>
        </div>

        <div className='bg-[#262422] shadow-[#F1F5F60A] rounded-[10px] py-10 px-8 mt-6'>
                
            <div className='text-center mt-5'>
                <p className='text-[#F1F5F6] font-Gilroy400 text-[15px]'>You can send any amount of coins to the wallet below, the system will credit you based on the amount recieved.</p>
            </div>

            <div className='mt-5 relative'>
                <input
                    type="text"
                    name="email"
                    value={walletAddress ? walletAddress : "3B8z7QdartAfricaTestAddress"}
                    placeholder="3B8z7QdsnfRv79G3a1GfmsUqDZgssktSaK"
                    className='appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F6] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                    />

                <div className='bg-[#FF8400] w-[54px] h-full rounded-[10px] absolute right-0 top-0 flex justify-center items-center cursor-pointer'>
                        <CopyToClipboard
                        text={walletAddress ? walletAddress : "3B8z7QdartAfricaTestAddress"}
                        onCopy={() => handleCopy()}
                        >
                    <img src={copy_icon} alt="copy icon" />
                    </CopyToClipboard>
                </div>
            </div>

            <div className='mt-6 text-center'>
                <p className='text-[#F1F5F6] text-sm font-Gilroy400'>you can also scan this QR code.</p>
            </div>

            <div className='flex justify-center mt-6'>
                <QRCode
                    title="Wallet Address"
                    size="250"
                    value={walletAddress ? walletAddress : "3B8z7QdartAfricaTestAddress"}
                />
            </div>

            <div className='mt-5 text-center'>
                <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Your wallet will be credited instantly after 2 block confirmations.</p>
            </div>

            <div className="flex justify-center items-center mt-2">
                    <input 
                        id="acceptTerms" 
                        name="acceptTerms"
                        type="checkbox"
                        value={completePayment}
                        onChange={(e) => handleCheck(e.target.checked)}
                        className="w-4 h-4 text-[#FF8400] bg-transparent border-[#FF8400] rounded focus:ring-[#FF8400] focus:ring-0" />
                    <label
                        htmlFor="acceptTerms"
                        className='ml-2 text-sm text-[#F1F5F6] font-Gilroy400'
                        >
                        I have completed the payment
                    </label>
                </div>
        </div>
        

        </div>
        </>
      );
}
 
export default SellCoinCompletePage;