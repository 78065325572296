import React, { useEffect, useState } from 'react'
import security_bg from '../../assets/images/security_bg.svg'
import chevron_left from '../../assets/icons/chevron_left.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { ResetPinValidator, ResetTokenValidator } from '../../validationSchema/validator'
import { useSelector, useDispatch } from 'react-redux'
import { userForgetPin, userResetPin, verifyResetPinCode } from '../../redux/Auth/authActions'
import { clearForgetPinSuccess, clearResetPinSuccess, clearValidToken } from '../../redux/Auth/auth'
import PinInput from "react-pin-input";


const ResetPinPage = () => {

    const dispatch = useDispatch()
    const {loading, forgetpinsuccess, userInfo, validtoken, pinresetsuccess} = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const [step, setStep] = useState(1)
    const [valid, setValid] = useState(false)

    const maskEmail = (email = "") => {
        const [name, domain] = email.split("@");
        const { length: len } = name;
        const maskedName = name.substring(0,5) + "****" + name[len - 1];
        const maskedEmail = maskedName + "@" + domain;
        return maskedEmail;
      };

      const [pin, setPin] = useState("");
      const [confirmPin, setConfirmPin] = useState("");
  
      const handlePin = (val) => {
          setPin(val);
          setValid(false)
      };
      
      const handleConfirmPin = (val) => {
          setConfirmPin(val);
      };

    const handleSubmit = (values) => {
        dispatch(userForgetPin({
            email: values.email,
            phoneNumber: values.phoneNumber
        }))
    }

    const handleTokenSubmit = (values) =>{
        dispatch(verifyResetPinCode({
            code: values.code
        }))
    }

    const handleSetPin = () =>{
        if(pin !== ""){
            dispatch(userResetPin({
                pin: pin
            }))
        }else{
            setValid(true)
        }
    }

    useEffect(()=>{
        if(forgetpinsuccess){
            setStep(2)
            dispatch(clearForgetPinSuccess())
        }
    },[forgetpinsuccess, dispatch])

    useEffect(()=>{
        if(validtoken){
            setStep(3)
            dispatch(clearValidToken())
        }
    },[validtoken, dispatch])

    useEffect(() =>{
        if(pinresetsuccess){
            setStep(1)
            setTimeout(()=>{
                dispatch(clearResetPinSuccess())
                navigate('/security')
            },1000)
           
        }
    },[pinresetsuccess, dispatch, navigate])
    



    return (
        <>
             <div>
                <img src={security_bg} alt="security bg" />
            </div> 
            <div className='mt-10'>
                <div className='flex flex-row justify-between items-center'>
                    <p className='text-[#F1F5F6] font-Gilroy700 text-lg'>Secure your account</p>
                    <Link to="/security" className='cursor-pointer flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[10px] h-[10px]' />
                        <p className='text-[#EAECED80] text-sm font-Gilroy500'>Back</p>
                    </Link>
                </div>

             { step === 1 && 
               <div className='max-w-lg mx-auto mt-12'>
                    <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Reset Pin </h5>
                   
                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={ResetPinValidator}
                        initialValues={{
                            email: "",
                            phoneNumber: "",
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='email' className="block mb-2 text-[#F1F5F6] text-xs">
                            Email Address
                            </label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your current email"
                                className={
                                touched.email && errors.email
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.email && errors.email}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='phoneNumber' className="block mb-2 text-[#F1F5F6] text-xs">
                            Phone Number
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="phoneNumber"
                                placeholder="Enter your phone number"
                                className={
                                    touched.phoneNumber && errors.phoneNumber
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.phoneNumber && errors.phoneNumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phoneNumber && errors.phoneNumber}
                                </small>
                            ) : null}
                            </div>
                            
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Next
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>

                    <div className='flex justify-end mt-4'>
                        <div className='flex gap-2 items-center'>
                            <div className='bg-[#FF8400] w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#454545] w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#454545] w-[10px] h-[10px] rounded-full'></div>
                        </div>
                    </div>

                </div>
                    }

              {step === 2 && 
               <div className='max-w-lg mx-auto mt-12'>
                    <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Enter Token</h5>
                    <p className='text-center text-[#F1F5F6] text-sm font-Gilroy400 mt-3'>Please provide the token sent to {userInfo ? maskEmail(userInfo.email): ""}</p>
                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleTokenSubmit(values, setSubmitting)
                        }
                        validationSchema={ResetTokenValidator}
                        initialValues={{
                            code: "",
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='code' className="block mb-2 text-[#F1F5F6] text-xs">
                            Token
                            </label>
                            <input
                                type="text"
                                name="code"
                                placeholder="Enter token"
                                className={
                                touched.code && errors.code
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.code && errors.code ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.code && errors.code}
                                </small>
                            ) : null}
                            </div>
                        
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Next
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>

                    <div className='flex justify-end mt-4'>
                        <div className='flex gap-2 items-center'>
                            <div className='bg-[#454545] w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#FF8400] w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#454545] w-[10px] h-[10px] rounded-full'></div>
                        </div>
                    </div>

                </div>
                }

                {step === 3 &&
                   <div className='max-w-xs mx-auto mt-8 md:mt-12'>
                   <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Set Transaction Pin</h5>
                  
                   <div className='mt-6'>
                      <div className='flex justify-center'>
                          <div>
                               <p className='block mb-2 text-[#F1F5F6] text-xs'>Set Pin</p>
                              <PinInput
                              length={4}
                              initialValue={pin}
                              focus
                              secret
                              type="numeric"
                              onChange={handlePin}
                              inputStyle={{ borderColor: "#454545", color: '#F1F5F680' }}
                              inputFocusStyle={{ borderColor: "#454545" }}
                              />
                               {valid &&
                               <small style={{ color: "rgb(220, 53, 69)" }}>
                                  Pin is required!
                                  </small>
                                  }
                          </div>
                      </div>
                   
                  </div>

                  <div className='mt-6'>
                      <div className='flex justify-center'>
                          <div>
                                  <p className='block mb-2 text-[#F1F5F6] text-xs'>Confirm Pin</p>
                              <PinInput
                              length={4}
                              initialValue={confirmPin}
                              focus
                              secret
                              type="numeric"
                              onChange={handleConfirmPin}
                              inputStyle={{ borderColor: "#454545", color: '#F1F5F680' }}
                              inputFocusStyle={{ borderColor: "#454545" }}
                              />
                          </div>
                      </div>
                     
                  </div>
                       
                   <div className="mt-6">
                      <button
                          type="submit"
                          onClick={handleSetPin}
                          disabled={loading}
                          className="bg-[#FF8400] 
                          disabled:bg-[#FF8400] 
                          disabled:opacity-[0.7]
                          text-sm
                          rounded-md w-full px-6 py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                      >
                          Set Pin
                      </button>
                   </div>

                   <div className='text-center mt-2'>
                      <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Your pin will be used to complete all withdrawal request
                          Please keep it safe.</p>
                   </div>

              </div>
              }

            </div>
        </>
      );
}
 
export default ResetPinPage;