import React, { useEffect } from 'react'
import logo from '../../assets/icons/logo-white.svg'
import chevron_left from '../../assets/icons/chevron-left.svg'
import world from '../../assets/images/world.svg'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { TwoFaCodeValidator } from '../../validationSchema/validator'
import { useDispatch, useSelector } from 'react-redux'
import { verifyTwoFAEmail } from '../../redux/Auth/authActions'
import { Logout } from '../../redux/Auth/auth'

const TwoFaEmailPage = () => {

    const {twoAuthVerified, loading, isSetPin,} = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = (values) => {
        console.log(values)
        dispatch(verifyTwoFAEmail(values))
    }

    const handleBack = () =>{
        dispatch(Logout())
        navigate('/')
    }

    useEffect(()=>{
        if(twoAuthVerified){
            if(isSetPin){
                navigate('/dashboard')  
            }
            else{
                navigate('/security/onboarding-complete')   
            } 
        }
    },[twoAuthVerified, isSetPin, navigate])

    return ( 
        <>
        <div className='bg-[#1B1A20] font-Gilroy400 min-h-screen'>
            <div className='py-6 px-4 container mx-auto'>
                <div className='flex items-center justify-between'>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                    <div onClick={handleBack} className='flex gap-3 items-center cursor-pointer'>
                        <img src={chevron_left} alt="chevron left" className='w-[8px] h-[8px]' />
                        <p className='text-[#EAECED80] text-sm'>Back to Login</p>
                    </div>
                </div>

                <div className='grid md:grid-cols-2 mt-20 items-center gap-8'>
                    <div>
                        <h4 className='text-[#F1F5F6] text-xl md:text-3xl'>
                        Two-step verification
                        </h4>
                        <p className='text-[#F1F5F6] text-sm'>
                        Please input the code to your email. This code will be valid for 15 minutes.
                        </p>

                        <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={TwoFaCodeValidator}
                        initialValues={{
                        code: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='code' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Verification Code
                            </label>
                            <input
                                type="text"
                                name="code"
                                placeholder="Enter verification code"
                                className={
                                touched.code && errors.code
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.code && errors.code ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.code && errors.code}
                                </small>
                            ) : null}
                            </div>

                        
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Verify
                            </button>
                            </div>    
                        </Form>
                        )}
                    </Formik>

                    </div>
                    <div className='hidden md:block'>
                        <div className='relative'>
                          <img src={world} alt="world" className='bg-opacity-50' />
                            <div className='max-w-sm mx-auto text-center absolute top-1/2 left-1/2 transform -translate-y-1/2  -translate-x-1/2'>
                                <h4 className='text-[#F1F5F6] text-3xl font-Gilroy700 leading-10'>Secure and Reliable <br/> Crypto Solutions.</h4>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div className='mt-28'>
                    <p className='text-[#F1F5F6] text-center md:text-left text-sm'>&copy; {new Date().getFullYear()} DartAfrica All rights reserved</p>
                </div>
            </div>
           
        </div>
        </>
     );
}
 
export default TwoFaEmailPage;