import React, { useEffect, useState } from 'react'
import {useFormik} from 'formik'
import { rateValidator } from '../../validationSchema/validator'
import arrow_right_circle from '../../assets/icons/arrow-right-circle.svg'
import { Link } from 'react-router-dom'
import chevron_down from '../../assets/icons/chevron_down.svg'
import logo from '../../assets/icons/logo-white.svg'
import chevron_left from '../../assets/icons/chevron-left.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getCoins } from '../../redux/Rates/rateActions'
import { clearCoinValues, CoinRateCalculation, CoinUSDRateCalculation } from '../../redux/Rates/rate'

const CoinCalculatorPage = () => {

    const dispatch = useDispatch()
    const {coins, ghAmount,ngAmount, usdAmount, coinAmount} = useSelector((state) => state.rate);

    const {
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleChange,
        handleSubmit,
      } = useFormik({
        initialValues: {
          coin: "",
          amount: coinAmount ? coinAmount : "",
          usdAmount: usdAmount ? usdAmount : "",
        },
        onSubmit(values, { setSubmitting }) {
          handSubmit(values, setSubmitting);
        },
        validationSchema: rateValidator,
      });

    const [coinError, setcoinError] = useState(false)

    const handSubmit = (values) => {
        console.log(values)
    }

    const handleCoinCalc = (amount, coin) =>{
        var pattern = /^[0-9]*.?[0-9]*$/;
        var amountValid = pattern.test(amount);
        if (coin !== "") {
          if (amountValid && amount !== "") {
            dispatch(CoinRateCalculation({
                amount,
                id:coin
            }))
          }
        }
        else{
            setcoinError(true)
        } 
    }

    const handleRateCalc = (amount, coin) =>{
        var pattern = /^[0-9]*.?[0-9]*$/;
        var amountValid = pattern.test(amount);
        if (coin !== "") {
          if (amountValid && amount !== "") {
            dispatch(CoinUSDRateCalculation({
                amount,
                id:coin
            }))
          }
        }
        else{
            setcoinError(true)
        } 
      
    }

    useEffect(()=>{
        dispatch(getCoins())
        dispatch(clearCoinValues())
    },[dispatch])


  useEffect(() => {
    setFieldValue("usdAmount", usdAmount);
    setFieldValue("amount", coinAmount)
  }, [usdAmount, coinAmount, setFieldValue]);

    return ( 
        <>
             <div className='bg-[#1B1A20] font-Gilroy400 min-h-screen'>
                <div className='py-6 px-4 container mx-auto'>

                 <div className='flex items-center justify-between'>
                        <div>
                            <img src={logo} alt="logo" />
                        </div>
                        <Link to="/" className='flex gap-3 items-center'>
                            <img src={chevron_left} alt="chevron left" className='w-[8px] h-[8px]' />
                            <p className='text-[#EAECED80] text-sm'>Back to Login</p>
                        </Link>
                    </div>
                    
                    <div className='bg-[#242322] rounded-[10px] py-6 flex justify-center mt-10'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-xl'>Coin Rate Calculator</h6>
                    </div>

                    <div className='max-w-lg mx-auto mb-6'>
                            <form className="mt-10 md:mt-12" onSubmit={handleSubmit}>

                                <div>
                                <label htmlFor='coin' className="block mb-2 text-[#F1F5F6] text-xs">
                                Select Coin
                                </label>
                                <div className='relative'>
                                <select
                                    name="coin"
                                    className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                    id="coin"
                                    onChange={(e) => {
                                        dispatch(clearCoinValues())
                                        handleChange(
                                          e,
                                          setFieldValue("amount", ''),
                                          setFieldValue("usdAmount",''),
                                          setcoinError(false)
                                        );
                                      }}
                                    onBlur={handleBlur}
                                >
                                   <option selected value="" disabled>
                                        Select a coin
                                    </option>
                                    {coins.map((opt, index) => {
                                        return (
                                        <option key={index} value={opt.id}>
                                            {opt.coinType}
                                        </option>
                                        );
                                    })}
                                </select>
                                <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                                </div>
                       
                                    {(touched.coin && errors.coin) || coinError ? (
                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                           Coin is required
                                        </small>
                                    ) : null}
                                </div>

                                <div className="mt-6">
                                    <label htmlFor='amount' className="block mb-2 text-[#F1F5F6] text-xs">
                                    Enter amount to calculate coin
                                    </label>
                                    <div className="relative">
                                        <input
                                        type="text"
                                        name="amount"
                                        placeholder="0.00"
                                        className={
                                            touched.amount && errors.amount
                                                ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                                : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                            }
                                        value={values.amount}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleCoinCalc(e.currentTarget.value, values.coin);
                                          }}
                                        onBlur={handleBlur}
                                        />
                                    
                                    </div>
                                    {touched.amount && errors.amount ? (
                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                            {touched.amount && errors.amount}
                                        </small>
                                    ) : null}
                                </div>

                                <div className="mt-6">
                                    <label htmlFor='usdAmount' className="block mb-2 text-[#F1F5F6] text-xs">
                                    USD Amount
                                    </label>
                                    <div className="relative">
                                        <input
                                        type="text"
                                        name="usdAmount"
                                        placeholder="0.00"
                                        className={
                                            touched.usdAmount && errors.usdAmount
                                                ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                                : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                            }
                                        value={values.usdAmount}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleRateCalc(e.currentTarget.value, values.coin);
                                          }}
                                        onBlur={handleBlur}
                                        />
                                    
                                    </div>
                                    {touched.usdAmount && errors.usdAmount ? (
                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                            {touched.usdAmount && errors.usdAmount}
                                        </small>
                                    ) : null}
                                </div>
                                
                                {/* <div className="mt-6">
                                <button
                                    type="submit"
                                    disabled={false}
                                    className="bg-[#FF8400] 
                                    disabled:bg-[#FF8400] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                                >
                                Calculate
                                </button>
                                </div> */}
                            
                            </form>
                        

                        <div className='mt-10 bg-[#262422] flex flex-col justify-center items-center shadow-[#F1F5F60A] border-dashed border border-[#FF8400] rounded-[4px] py-4 px-8'>
                                <h6 className='text-[#F1F5F6] text-lg font-Gilroy500'>You Get:</h6>
                                <h2 className='text-[#3B95FD] text-2xl font-Gilroy600'>GHS {ghAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                                <p className='text-[#EAECED80] text-sm font-Gilroy500'>Or</p>
                                <h2 className='text-[#3B95FD] text-2xl font-Gilroy600'>NGN {ngAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                       </div>

                       <Link to="/" className='flex justify-center mt-4 gap-2 cursor-pointer'>
                            <img src={arrow_right_circle} alt="arrow right" className='w-[20px] h-[20px]' />
                            <p className='text-[#3B95FD] text-sm font-Gilroy400'>Proceed to trade</p>
                        </Link>

                    </div>

                </div>
             </div>
        </>
     );
}
 
export default CoinCalculatorPage;