import React, { useEffect, useState } from 'react'
import chevron_left from '../../assets/icons/chevron_left.svg'
import chevron_down from '../../assets/icons/chevron_down.svg'
import { Link,useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik'
import { bankAccountValidatorGH, bankAccountValidatorNG, mobileAccountValidator } from '../../validationSchema/validator'
import { useDispatch, useSelector } from 'react-redux'
import { addAccountDetails, getAccountName, getBankAccounts, getGhanaBankBranches, getGhanaMobileMoneyAccounts } from '../../redux/Auth/authActions';
import { clearAccountAdded } from '../../redux/Auth/auth';

const AddAccountPage = () => {
    const dispatch = useDispatch()
    const {banks, branches, countryTag, loading,accountAdded,accountName} = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const handleBankBranch = (val) =>{
        console.log(val)
        dispatch(getGhanaBankBranches({
            code: val
        }))
    }

    const [type, setType] = useState("Mobile")

    const handleType = (val) =>{
        setType(val)
        if (val === "Mobile") {
            dispatch(getGhanaMobileMoneyAccounts())
        }
        else{
            dispatch(getBankAccounts())
        }
    }

    const handleVerifyAcct = (banknumber, code) =>{
        console.log(banknumber)
        console.log(code)
        let re = /^-?[0-9]+(.[0-9]{1-7})?$/;
        if(re.test(banknumber)){
                if(banknumber.length === 10 && code !== ""){
                    dispatch(getAccountName({
                        account_bank: code,
                        account_number: banknumber,  
                    }))  
             }
        }
    }

    const handleSubmit = (values) => {
        let accountType = values.accountType
        let res;
        console.log(values)

      if(accountType === "Bank"){
         //  //  get bank Name
      var bankId = values.bankName;
      var bankName = banks.find((pro) => pro.id === parseInt(bankId)).name;

      // get bank code
      var bankCode = banks.find((pro) => pro.id === parseInt(bankId)).code;
     

          // get branch Name
       var branch = values.bankBranch;
        var branchName = branches.find(
            (pro) => pro.branch_code === branch
          ).branch_name;

        res = {
            accountType: "Bank",
            bankName: bankName,
            bankCode: bankCode,
            bankBranch: values.bankBranch,
            accountNumber: values.accountNumber,
            accountName: values.accountName,
            branchName: branchName,
          };
      }
      else{

        // get mobile wallet identifier
        let identifier = banks.find((pro) => pro.name === values.mobileNetwork).code;

        res = {
            accountType: "Mobile",
            mobileNetwork: values.mobileNetwork,
            mobileNumber: values.accountNumber,
            accountName: values.accountName,
            identifier: identifier
          };
      }
      console.log(res)
      dispatch(addAccountDetails(res))
    }

    const addNGAccount = (values) =>{
        console.log(values)
           //  //  get bank Name
      var bankCode = values.bankName;
      var bankName = banks.find((pro) => pro.code === bankCode).name;


      let res = {
        accountType: "Bank",
        bankName: bankName,
        bankCode: bankCode,
        bankBranch: "Nigeria",
        accountNumber: values.accountNumber,
        accountName: accountName,
        branchName: bankName,
      };
      console.log(res)
      dispatch(addAccountDetails(res))
    }

    useEffect(()=>{
        if(countryTag === "NG"){
            dispatch(getBankAccounts())
        }
    },[dispatch, countryTag])

    useEffect(()=>{
        if(accountAdded){
            navigate('/account-details')
            setTimeout(() => {
                dispatch(clearAccountAdded())
            }, 1000);
        }
    },[accountAdded, dispatch, navigate])


    return (  
        <>
            <div className='flex justify-end mt-5'>
                <Link to="/account-details" className='cursor-pointer flex gap-3 items-center'>
                    <img src={chevron_left} alt="chevron left" className='w-[10px] h-[10px]' />
                    <p className='text-[#EAECED80] text-sm font-Gilroy500'>Accounts</p>
                </Link>
            </div>

            <div className='max-w-lg mx-auto mt-12'>
                <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Add Bank Account</h5>


             {countryTag === "GH" && 
                <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                    }
                    validationSchema={ type === "Bank" ? bankAccountValidatorGH : mobileAccountValidator}
                    initialValues={{
                        accountType: "",
                        bankName: "",
                        bankBranch: "",
                        accountNumber: "",
                        accountName: "",
                        mobileNetwork: "",
                        
                    }}
                >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    setFieldValue,
                    errors,
                    }) => (
                    <Form className="mt-8" onSubmit={handleSubmit}>
                        <div>
                        <label htmlFor='accountType' className="block mb-2 text-[#F1F5F6] text-xs">
                        Select Account Type
                        </label>
                        <div className='relative'>
                        <select
                            name="accountType"
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            id="accountType"
                            onChange={(e) => {
                                handleChange(e);
                                handleType(e.currentTarget.value);
                                setFieldValue('accountNumber', "")
                                setFieldValue('accountName', "")
                                setFieldValue('mobileNetwork', "")
                            }}
                            onBlur={handleBlur}
                            values={values.accountType}
                        >
                              <option selected value="" disabled>
                              Select an account type
                            </option>
                             {/* <option
                            value="Bank"
                            >
                            Ghana Accounts
                            </option> */}
                            <option
                            value="Mobile"
                            >
                            Ghana Mobile Money
                            </option>
                           
                        </select>
                         <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                        </div>
                       
                            {touched.accountType && errors.accountType ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.accountType && errors.accountType}
                                </small>
                            ) : null}
                        </div>

                       
                       {type === "Bank" &&
                        <div className="mt-6">
                        <label htmlFor='bankName' className="block mb-2 text-[#F1F5F6] text-xs">
                        Select Bank
                        </label>
                        <div className='relative'>
                        <select
                            name="bankName"
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            id="bankName"
                            onChange={(e) => {
                                handleChange(e);
                                handleBankBranch(e.currentTarget.value);
                            }}
                            onBlur={handleBlur}
                            values={values.bankName}
                        >
                         <option selected value="" disabled>
                              Select your bank
                            </option>
                            {banks.map((opt, index) => {
                              return (
                                <option key={index} value={opt.id}>
                                  {opt.name}
                                </option>
                              );
                            })}
                        </select>
                        <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                        </div>
                        
                            {touched.bankName && errors.bankName ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.bankName && errors.bankName}
                                </small>
                            ) : null}
                        
                        </div>
                        }

                     {type === "Mobile" && 
                      <div className="mt-6">
                        <label htmlFor='mobileNetwork' className="block mb-2 text-[#F1F5F6] text-xs">
                        Select Mobile Network
                        </label>
                        <div className='relative'>
                        <select
                            name="mobileNetwork"
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            id="mobileNetwork"
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            values={values.mobileNetwork}
                        >
                         <option selected value="" disabled>
                              Select your network
                            </option>
                            {banks.map((opt, index) => {
                              return (
                                <option key={index} value={opt.name}>
                                  {opt.name}
                                </option>
                              );
                            })}
                        </select>
                        <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                        </div>
                        
                            {touched.mobileNetwork && errors.mobileNetwork ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.mobileNetwork && errors.mobileNetwork}
                                </small>
                            ) : null}
                        
                        </div>
                        }

                       {type === "Bank" &&
                        <div className="mt-6">
                        <label htmlFor='bankBranch' className="block mb-2 text-[#F1F5F6] text-xs">
                        Bank Branch
                        </label>
                        <div className='relative'>
                        <select
                            name="bankBranch"
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            id="bankBranch"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            values={values.bankBranch}
                        >
                             <option selected value="" disabled>
                              Select a branch
                            </option>
                            {branches.map((opt, index) => {
                              return (
                                <option key={index} value={opt.branch_code}>
                                  {opt.branch_name}
                                </option>
                              );
                            })}
                        </select>
                        <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                        </div>
                        
                            {touched.bankBranch && errors.bankBranch ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.bankBranch && errors.bankBranch}
                                </small>
                            ) : null}
                        
                        </div>
                        }

                        <div className="mt-6">
                        <label htmlFor='accountNumber' className="block mb-2 text-[#F1F5F6] text-xs">
                                   {type === "Bank" ? 'Account Number' : 'Mobile Number'}
                         </label>
                        <div className="relative">
                            <input
                            type="text"
                            name="accountNumber"
                            placeholder= {type === "Bank" ? 'Account Number' : 'Mobile Number'}
                            className={
                                touched.accountNumber && errors.accountNumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                            value={values.accountNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                        
                        </div>
                        {touched.accountNumber && errors.accountNumber ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                {touched.accountNumber && errors.accountNumber}
                            </small>
                        ) : null}
                        </div>

                    
                        <div className="mt-6">
                        <label htmlFor='accountName' className="block mb-2 text-[#F1F5F6] text-xs">
                        Account Name
                        </label>
                        <div className="relative">
                            <input
                            type="text"
                            name="accountName"
                            placeholder="Account Name"
                            className={
                                touched.accountName && errors.accountName
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                            value={values.accountName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                        
                        </div>
                        {touched.accountName && errors.accountName ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                {touched.accountName && errors.accountName}
                            </small>
                        ) : null}
                        </div>
                        
                        <div className="mt-6">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-[#FF8400] 
                            disabled:bg-[#FF8400] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                        >
                            Add Account
                        </button>
                        </div>
                    
                    </Form>
                    )}
                </Formik>
            }

                {countryTag === "NG" && 
                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        addNGAccount(values, setSubmitting)
                        }
                        validationSchema={bankAccountValidatorNG}
                        initialValues={{
                            bankName: "",
                            accountNumber: "",
                        
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                        
                            <div className="mt-6">
                            <label htmlFor='bankName' className="block mb-2 text-[#F1F5F6] text-xs">
                            Select Bank
                            </label>
                            <div className='relative'>
                            <select
                                name="bankName"
                                className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                id="bankName"
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                values={values.bankName}
                            >
                            <option selected value="" disabled>
                                Select your bank
                                </option>
                                {banks.map((opt, index) => {
                                return (
                                    <option key={index} value={opt.code}>
                                    {opt.name}
                                    </option>
                                );
                                })}
                            </select>
                            <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                            </div>
                            
                                {touched.bankName && errors.bankName ? (
                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                        {touched.bankName && errors.bankName}
                                    </small>
                                ) : null}        
                            </div>

                            <div className="mt-6">
                            <label htmlFor='accountNumber' className="block mb-2 text-[#F1F5F6] text-xs">
                            Account Number
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="accountNumber"
                                placeholder="Account Number"
                                className={
                                    touched.accountNumber && errors.accountNumber
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.accountNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleVerifyAcct(e.currentTarget.value,values.bankName);
                                 }}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.accountNumber && errors.accountNumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.accountNumber && errors.accountNumber}
                                </small>
                            ) : null}
                            </div>
        
                            <div className="mt-6">
                            <label htmlFor='accountName' className="block mb-2 text-[#F1F5F6] text-xs">
                                Account Name
                                </label>
                                <div className="relative">
                                    <input
                                    type="text"
                                    name="accountName"
                                    disabled
                                    placeholder="Account Name"
                                    className='appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    value={accountName}
                                    />   
                                </div>
                            </div>
                            
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Add Account
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>
                }


             </div>
        </>
    );
}
 
export default AddAccountPage;