import * as Yup from 'yup'

export const loginValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const registerValidator = Yup.object({
  userName: Yup.string().required("Username is required"),
  phoneNumber: Yup.string()
    .min(6, "Phone number cannot be less than 6 digits")
    .required("Phonenumber is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password cannot be less than 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  countryTag: Yup.string(),
  acceptTerms: Yup.bool().oneOf(
    [true],
    "Accept Terms & Conditions is required"
  ),
  referralCode: Yup.string()
});

export const forgotValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required')
})

export const resetPasswordValidator = Yup.object({
  password: Yup.string()
    .min(6, "Password cannot be less than 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const TwoFaCodeValidator = Yup.object({
  code: Yup.string()
    .min(6, "Pin cannot be less than 6 characters")
    .max(6, "Pin cannot be more than 6 characters")
    .required("Code is required")
});

export const ChangePinValidator = Yup.object({
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  newpin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Enter a new pin")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  confirm_pin: Yup.string()
    .required("Pin must match")
    .oneOf([Yup.ref("newpin"), null], "Pin must match"),
});

export const ChangePasswordValidator = Yup.object({
  password: Yup.string().required("Password is required"),
  newpassword: Yup.string().required("Enter a new password"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
});


export const ResetPinValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
    phoneNumber: Yup.string()
    .required("Phonenumber is required"),
});

export const ResetTokenValidator = Yup.object({
  code: Yup.string().required("Token is required"),
});

export const SetPinValidator = Yup.object({
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  confirm_pin: Yup.string()
    .required("Pin must match")
    .oneOf([Yup.ref("pin"), null], "Pin must match"),
});

export const withdrawValidator = Yup.object({
  narration: Yup.string(),
  amount: Yup.string()
    .required("Enter an amount")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
});

export const filterValidator = Yup.object({
  status: Yup.string().required("Select a status"),
  amount: Yup.string()
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
});

export const mobileAccountValidator = Yup.object({
  accountType: Yup.string().required("Account Type is required"),
  accountNumber: Yup.string()
    .min(7, "Mobile number cannot be less than 7 digits")
    .max(11, "Exceeded characters for mobile number")
    .required("Mobile Number is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid mobile number"),
  mobileNetwork: Yup.string().required("Mobile Network is required"),
  accountName: Yup.string().required("Enter your account name"),
});

export const bankAccountValidatorGH = Yup.object({
  accountType: Yup.string().required("Account Type is required"),
  bankName: Yup.string().required("Bank Name is required"),
  bankBranch: Yup.string().required("Bank Branch is required"),
  accountNumber:  Yup.string()
  .min(10, 'Account number cannot be less than 10 digits')
  .max(14, 'Exceeded characters for Account Number')
  .required("Account Number is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid Account Number"),
  accountName: Yup.string().required("Enter your account name"),
});

export const bankAccountValidatorNG = Yup.object({
  bankName: Yup.string().required("Bank Name is required"),
  accountNumber:  Yup.string()
  .min(10, 'Account number cannot be less than 10 digits')
  .max(10, 'Exceeded characters for Account Number')
  .required("Account Number is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid Account Number"),
});

export const rateValidator = Yup.object({
  coin: Yup.string().required("Coin is required"),
  amount: Yup.string()
    .required("Enter an amount")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
    usdAmount: Yup.string()
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
}); 


export const kycUpgrade2Validator = Yup.object({
  // dob: Yup.string().required("Date Of Birth is required"),
  firstname: Yup.string().required("Firstname is required"),
  lastname: Yup.string().required("Lastname is required"),
  bvn:  Yup.string()
  .min(11, 'BVN number cannot be less than 11 digits')
  .max(11, 'Exceeded characters for BVN Number')
  .required("BVN Number is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid BVN Number"),
}); 

export const kycUpgrade3Validator = Yup.object({
  idType: Yup.string().required("Select an identification type"),
  idNumber: Yup.string().required("Enter an ID Number"),
}); 