import React,{useEffect, useState} from 'react'
import alert_circle from '../../assets/icons/alert-circle_orange.svg'
// import qrcode from '../../assets/images/qrcode.svg'
import {useFormik} from 'formik'
import { rateValidator } from '../../validationSchema/validator'
import chevron_down from '../../assets/icons/chevron_down.svg'
import spin_circle from '../../assets/icons/spin-circle.svg'
import { getCoins, sellCoinTrade } from '../../redux/Rates/rateActions'
import { useDispatch, useSelector } from 'react-redux'
import { clearCoinValues, CoinRateCalculation, CoinUSDRateCalculation } from '../../redux/Rates/rate'
import { useNavigate } from 'react-router'


const SellCoinPage = () => {

    const dispatch = useDispatch()
    const {coins,usdAmount,coinAmount,ghAmount,ngAmount, loader, tradeSuccess} = useSelector((state) => state.rate);
    const {countryTag} = useSelector((state) => state.auth);
    const navigate = useNavigate()

    const {
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        handleChange,
        handleSubmit,
      } = useFormik({
        initialValues: {
          coin: "",
          amount: coinAmount ? coinAmount : "",
          usdAmount: usdAmount ? usdAmount : "",
        },
        onSubmit(values, { setSubmitting }) {
          handSubmit(values, setSubmitting);
        },
        validationSchema: rateValidator,
      });

      const [coinError, setcoinError] = useState(false)

    const handSubmit = (values) => {
          // check for usd amount if it is lesser than $20
        var coinId = values.coin;
        var coinType = coins.find((pro) => pro.id === coinId).coinType;
        let creds = {
            coinType: coinType,
            narration: "Trade Coin",
            amounttInCoin: parseFloat(values.amount.toFixed(6)),
            amountInLocalCurrency: countryTag === "GH" ? ghAmount.toFixed(2) : ngAmount.toFixed(2),
            country: countryTag === "GH" ? "GH" : "NG",
            amountInUsd: parseFloat(values.usdAmount.toFixed(2))
        }
        dispatch(sellCoinTrade(creds))
    }

    const handleCoinCalc = (amount, coin) =>{
        var pattern = /^[0-9]*.?[0-9]*$/;
        var amountValid = pattern.test(amount);
        if (coin !== "") {
          if (amountValid && amount !== "") {
            dispatch(CoinRateCalculation({
                amount,
                id:coin
            }))
          }
        }
        else{
            setcoinError(true)
        } 
    }

    const handleRateCalc = (amount, coin) =>{
        var pattern = /^[0-9]*.?[0-9]*$/;
        var amountValid = pattern.test(amount);
        if (coin !== "") {
          if (amountValid && amount !== "") {
            dispatch(CoinUSDRateCalculation({
                amount,
                id:coin
            }))
          }
        }
        else{
            setcoinError(true)
        } 
      
    }

    useEffect(() => {
        setFieldValue("usdAmount", usdAmount);
        setFieldValue("amount", coinAmount)
      }, [usdAmount, coinAmount, setFieldValue]);


    useEffect(()=>{
        dispatch(getCoins())
        dispatch(clearCoinValues())
    },[dispatch])

    useEffect(()=>{
        if(tradeSuccess){
            navigate('/sellcoin/complete')
        }
    },[tradeSuccess, navigate])

    return (  
        <>
    
            <div className='max-w-4xl mx-auto mt-5'>

                <div className='bg-[#262422] min-h-[150px] shadow-[#F1F5F60A] border-dashed border border-[#FF8400] rounded-[10px] py-6 px-8'>
                        <p className='text-[#FF8400] font-Gilroy500 text-base'>Sell Coin</p>

                      
                        <form className="mt-6" onSubmit={handleSubmit}>
                        <div className='grid md:grid-cols-4 gap-4'>

                            <div className="">
                                <label htmlFor='coin' className="block mb-2 text-[#F1F5F6] text-xs">
                                  I want to sell
                                </label>
                                <div className='relative'>
                                <select
                                    name="coin"
                                    className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                    id="coin"
                                    onChange={(e) => {
                                        dispatch(clearCoinValues())
                                        handleChange(
                                          e,
                                          setFieldValue("amount", ''),
                                          setFieldValue("usdAmount",''),
                                          setcoinError(false)
                                        );
                                      }}
                                    onBlur={handleBlur}
                                    values={values.coin}
                                >
                                <option selected value="" disabled>
                                    Select a coin
                                    </option>
                                    {coins.map((opt, index) => {
                                        return (
                                        <option key={index} value={opt.id}>
                                            {opt.coinType}
                                        </option>
                                        );
                                    })}
                                </select>
                                <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                                </div>
                            
                                {(touched.coin && errors.coin) || coinError ? (
                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                           Coin is required
                                        </small>
                                    ) : null}    
                            </div>

                            <div className="">
                            <label htmlFor='amount' className="block mb-2 text-[#F1F5F6] text-xs">
                           Coin Amount 
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="amount"
                                placeholder="0.00"
                                className={
                                    touched.amount && errors.amount
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.amount}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleCoinCalc(e.currentTarget.value, values.coin);
                                  }}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>
                            

                            <div className="">
                            <label htmlFor='usdAmount' className="block mb-2 text-[#F1F5F6] text-xs">
                            USD Amount
                            </label>
                            <div className="relative">
                                <input
                                type="text"
                                name="usdAmount"
                                placeholder="0.00"
                                className={
                                    touched.usdAmount && errors.usdAmount
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] disabled:bg-[#2D2A28CC] disabled:border-none text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.usdAmount}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleRateCalc(e.currentTarget.value, values.coin);
                                  }}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.usdAmount && errors.usdAmount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.usdAmount && errors.usdAmount}
                                </small>
                            ) : null}
                            </div>
                            

                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loader}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Sell
                            </button>
                            </div>

                        </div>  
                 </form>
                         


                </div>
                <div className='flex gap-2 items-center justify-center mt-4'>
                    <img src={alert_circle} alt="alert circle" className='w-[20px] h-[20px]' />
                    <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Note your wallet will be credited immediately your transaction is confirmed</p> 
                </div>

                <div className='mt-3 text-center'>
                    <h6 className='text-[#F1F5F6] font-Gilroy700 text-xl md:text-3xl'>{countryTag === "GH" ? "GH₵" : "NGN"} {countryTag === "GH" ? ghAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ngAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                    <p className=' text-[#F1F5F6CC] font-Gilroy400 text-base'>Total amount to be credited after trade</p>
                </div>

                {!loader &&  !tradeSuccess &&
              <div className='bg-[#262422] shadow-[#F1F5F60A] rounded-[10px] mt-6 px-6 md:px-0'>
                    <div className='flex flex-col min-h-[400px] items-center justify-center'>
                            <div className='mt-5 flex justify-center'>
                              <p className='text-[#FFFFFF] font-Gilroy500 text-[15px] text-center'>
                              Enter how much coins you would like to sell, to  generate<br/> wallet for payment.
                              </p>
                            </div>
                        </div>
                </div>
                }

              {loader &&  
              <div className='bg-[#262422] shadow-[#F1F5F60A] rounded-[10px] mt-6 px-6 md:px-0'>
                    <div className='flex flex-col min-h-[400px] items-center justify-center'>
                            <img src={spin_circle} className="animate-spin" alt="spin cirlce" />
                            <div className='mt-5 flex justify-center'>
                              <p className='text-[#FFFFFF] font-Gilroy500 text-[15px] text-center'>Generating personalized wallet for your transaction.<br/> Please Wait!</p>
                            </div>
                        </div>
                </div>
                }

            </div>
            
        </>
    );
}
 
export default SellCoinPage;