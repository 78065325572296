import React,{useEffect} from 'react'
import logo from '../../assets/icons/logo-white.svg'
import chevron_left from '../../assets/icons/chevron-left.svg'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { TwoFaCodeValidator } from '../../validationSchema/validator'
import { useDispatch, useSelector } from 'react-redux'
import { resendVerifyEmail, verifyEmail } from '../../redux/Auth/authActions'
import { useNavigate } from 'react-router-dom'
import { clearUserVerification } from '../../redux/Auth/auth'

const VerifyEmailPage = () => {

    const {loading, useremail, isVerified} = useSelector((state) => state.auth);
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const handleSubmit = (values) => {
        console.log(values)
        dispatch(verifyEmail(values))
    }

    useEffect(()=>{
        if(isVerified){
            navigate('/security/onboarding-complete')
            setTimeout(()=>{
                dispatch(clearUserVerification())
            },1000)
        }
    },[isVerified, navigate, dispatch])


    const resendVerifyLink = () =>{
        dispatch(resendVerifyEmail({
            email: useremail ? useremail : ''
        }))
    }


    return ( 
        <>
         <div className='bg-[#1B1A20] font-Gilroy400 min-h-screen'>
             <div className='py-6 px-4 container mx-auto'>
                    <div className='flex items-center justify-between'>
                        <div>
                            <img src={logo} alt="logo" />
                        </div>
                        <Link to="/" className='flex gap-3 items-center'>
                            <img src={chevron_left} alt="chevron left" className='w-[8px] h-[8px]' />
                            <p className='text-[#EAECED80] text-sm'>Back to Login</p>
                        </Link>
                    </div>

                    <div className='md:border md:border-[#FF8400] rounded-2xl bg-[#222122] mt-20 max-w-xl mx-auto py-8 md:py-10 px-8 md:px-16'>

                        <div className='text-center'>
                            <h6 className='text-[#F1F5F6] font-Gilroy700 text-xl md:text-2xl'>Verify your Account</h6>
                            <p className='text-[#F1F5F6] text-sm mt-2'>
                            Account verification code has been sent to the e-mail address you provided
                            </p>
                        </div>

                        <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={TwoFaCodeValidator}
                        initialValues={{
                        code: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-6 md:max-w-lg" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='code' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Verification Code
                            </label>
                            <input
                                type="text"
                                name="code"
                                placeholder="Enter verification code"
                                className={
                                touched.code && errors.code
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.code && errors.code ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.code && errors.code}
                                </small>
                            ) : null}
                            </div>
                        
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Verify
                            </button>
                            </div>    
                        </Form>
                        )}
                    </Formik>

                    <div className='text-center mt-10'>
                    <p className='text-[#F1F5F6] text-sm'>Didn’t get the mail? <button disabled={loading}
                         onClick={resendVerifyLink} className='text-[#3B95FD] disabled:opacity-[0.7]'>Send it again</button></p>
                    </div>
                           

                    </div>

                 </div>
             </div>
        </>
     );
}
 
export default VerifyEmailPage;