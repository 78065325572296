import { createSlice } from '@reduxjs/toolkit'
import { addAccountDetails, deleteAccountDetails, disableTwoFA, enableTwoFA, getAccountName, getBankAccounts, getGhanaBankBranches, getGhanaMobileMoneyAccounts, getKYCLevel, getKYCSettings, getUserSummary, getWalletBalance, resendVerifyEmail, userChangePassword, userChangePhoto, userChangePin, userCreatePin, userDocsUpload, userForgetPin, userForgot, userLogin, userRegister, userResetPassword, userResetPin, userWithdrawFunds, verifyAddress, verifyBVN, verifyEmail, verifyIDSelfie, verifyResetCode, verifyResetPinCode, verifyTwoFAAuth, verifyTwoFAEmail } from './authActions'

const initialState = {
  value: 0,
  loading: false,
  token: "",
  isAuthenticated: false,
  userInfo: null,
  useremail: "",
  userVerified: false,
  isSetPin: false,
  isVerified: false,
  isTwoFaEnabled: false,
  twoFaType: "",
  twoAuthVerified: false,
  signup_success: false,
  isResetSuccess: false,
  countryTag: "NG",
  accountDetails: [],
  walletBalance: 0,
  pendingTrade: 0,
  completedTrade: 0,
  imageUrl: "",
  withdrawSuccess: false,
  forgetpinsuccess: false,
  token_value: "",
  validtoken: false,
  pinresetsuccess: false,
  banks: [],
  branches: [],
  accountAdded: false,
  accountName: "",
  userSummary: {},
  TwoFaSuccess: false,
  TwoFaInfo: null,
  userKYCLevel: null,
  kycSettings: [],
  upgradeLoader: false,
  selfieUrl: null,
  idUrl: null,
  isKYCVerified: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    UserEmail : (state, {email}) =>{
      state.useremail = email
    },
    VALID_TOKEN : (state, {code}) =>{
      state.token_value = code
    },
    clearValidToken : (state, {code}) =>{
      state.validtoken = false
    },
    clearResetPinSuccess: (state) =>{
      state.pinresetsuccess = false
      state.token_value = ""  
    },
    clearSignUpSuccess: (state) => {
        state.signup_success = false
    },
    clearUserVerification: (state) => {
      state.userVerified = false
   },
   clearResetSuccess: (state) => {
    state.isResetSuccess = false
  },
  clearWithdrawSuccess: (state) => {
    state.withdrawSuccess = false
  },
  clearAccountAdded: (state) =>{
    state.accountAdded = false
  },
  clearForgetPinSuccess: (state) => {
    state.forgetpinsuccess = false
 },
 clearTwoFaSuccess: (state) =>{
  state.TwoFaSuccess = false
  state.TwoFaInfo = null
 },
 clearisKYCVerified: (state) =>{
  state.isKYCVerified = false
 },
    Logout: (state) =>{
        state.isAuthenticated = false
        localStorage.setItem("token", "")
    }
  },
  extraReducers:{
       // login user -------------------- ---------------------------------------------------------------
       [userLogin.pending]: (state) => {
        state.loading = true
      },
      [userLogin.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.token = payload.token
        state.isAuthenticated = true
        localStorage.setItem("token", payload.token);
        state.userInfo = payload.profile
        state.isSetPin = payload.profile.isSetPin
        state.isVerified = payload.profile.isVerified
        state.countryTag = payload.profile.countryTag
        state.accountDetails = payload.profile.accountDetails
        state.walletBalance = payload.profile.walletBalance
        state.imageUrl = payload.profile.imageUrl
        state.isTwoFaEnabled = payload.profile.isTwoFaEnabled
        state.twoFaType = payload.profile.twoFaType
      },
      [userLogin.rejected]: (state, { payload }) => {
        state.loading = false
        state.isAuthenticated = false
      },

      // forgot password -------------------- ---------------------------------------------------------------
      [userForgot.pending]: (state) =>{
        state.loading = true
      },
      [userForgot.fulfilled]: (state) =>{
        state.loading = false
      },
      [userForgot.rejected]: (state) =>{
        state.loading = false
      },

      // Verify reset code sent to email ------- -------
      [verifyResetCode.pending]: (state) =>{
          state.loading = true
      },
      [verifyResetCode.fulfilled]: (state) =>{
          state.loading = false
      },
      [verifyResetCode.rejected] : (state) =>{
          state.loading = false
      },

      // reset password ----------------------------------- ----------------------------------------------------
      [userResetPassword.pending]: (state) =>{
        state.loading = true
      },
      [userResetPassword.fulfilled]: (state) =>{
        state.loading = false
        state.isResetSuccess = true
      },
      [userResetPassword.rejected]: (state) =>{
        state.loading = false
      },

    //   Register user --------------------------- ------------------------------
    [userRegister.pending]: (state) =>{
        state.loading = true
    },
    [userRegister.fulfilled]: (state,{payload}) =>{
        console.log(payload)
        state.loading = false
        state.signup_success = true
    },
    [userRegister.rejected]: (state) =>{
        state.loading = false
    },
    
    //  Verify Email ---------------- ----------------------------
    [verifyEmail.pending]: (state) =>{
      state.loading = true
    },
    [verifyEmail.fulfilled]: (state,{payload}) =>{
      state.loading = false
      state.userVerified = true
      state.isVerified = true
      state.token = payload.token
      state.isAuthenticated = true
      localStorage.setItem("token", payload.token);
      state.userInfo = payload.profile
      state.isSetPin = payload.profile.isSetPin
      state.countryTag = payload.profile.countryTag
      state.accountDetails = payload.profile.accountDetails
      state.walletBalance = payload.profile.walletBalance
      state.imageUrl = payload.profile.imageUrl
      state.isTwoFaEnabled = payload.profile.isTwoFaEnabled
      state.twoFaType = payload.profile.twoFaType
    },
    [verifyEmail.rejected]: (state) =>{
      state.loading = false
    },

    // resend verification link ------- ----------
    [resendVerifyEmail.pending]: (state) =>{
      state.loading = true
    },
    [resendVerifyEmail.fulfilled]: (state) =>{
      state.loading = false
    },
    [resendVerifyEmail.rejected]: (state) =>{
      state.loading = false
    },

    // get wallet balance -------- --------
    [getWalletBalance.pending]: (state) =>{

    },
    [getWalletBalance.fulfilled]: (state,{payload}) =>{
      state.walletBalance = payload.walletBalance
      state.pendingTrade = payload.pendingTrade
      state.completedTrade = payload.completedTrade
    },
    [getWalletBalance.rejected]: (state) =>{

    },

    // get user summary
    [getUserSummary.pending]: (state) =>{

    },
    [getUserSummary.fulfilled]: (state, {payload}) =>{
      state.userSummary = payload
    },
    [getUserSummary.rejected]: (state) =>{

    },
    // ADD ACCOUNT DETAILS --------------------
    [addAccountDetails.pending]: (state) =>{
      state.loading = true
    },
    [addAccountDetails.fulfilled]: (state, {payload}) =>{
      state.loading = false
      state.accountAdded = true
      state.accountDetails = payload.accountDetails
    },
    [addAccountDetails.rejected]: (state) =>{
       state.loading = false
    },

    // Delete account details ------ ---------
    [deleteAccountDetails.pending]: (state) =>{
      state.loading = true

    },
    [deleteAccountDetails.fulfilled]: (state, {payload}) =>{
      state.accountDetails = payload.accountDetails
      state.loading = false
    },
    [deleteAccountDetails.rejected]: (state) =>{
      state.loading = false

    },

    // create pin --------
    [userCreatePin.pending]: (state) =>{
      state.loading = true
    },
    [userCreatePin.fulfilled]: (state) =>{
        state.loading = false
        state.isSetPin = true
    },
    [userCreatePin.rejected]: (state) =>{
      state.loading = false
    },

    // reset pin --- -----------------
    [userForgetPin.pending] :(state) =>{
      state.loading = true
    },
    [userForgetPin.fulfilled]: (state) =>{
      state.loading = false
      state.forgetpinsuccess = true
    },
    [userForgetPin.rejected]: (state) =>{
       state.loading = false
    },

    // verify reset pin ------ --------
    [verifyResetPinCode.pending]: (state) =>{
        state.loading = true
    },
    [verifyResetPinCode.fulfilled]: (state) =>{
        state.loading = false
        state.validtoken = true
    },
    [verifyResetPinCode.rejected]: (state) =>{
       state.loading = false
    },

    // user reset pin ------ 
    [userResetPin.pending] :  (state) =>{
      state.loading = true
    },
    [userResetPin.fulfilled]: (state) =>{
      state.loading = false
      state.pinresetsuccess = true
    },
    [userResetPin.rejected]: (state) =>{
        state.loading = false
    },

    // change pin ---- -- ------------
    [userChangePin.pending]: (state) =>{
      state.loading = true
    },
    [userChangePin.fulfilled]: (state) =>{
      state.loading = false
    },
    [userChangePin.rejected]: (state) =>{
      state.loading = false
    },

    // change password ---------- ----------
    [userChangePassword.pending]: (state) =>{
      state.loading = true
    },
    [userChangePassword.fulfilled]: (state) =>{
      state.loading = false
    },
    [userChangePassword.rejected]: (state) =>{
      state.loading = false
    },

    // change photo ---- ------------
    [userChangePhoto.pending]: (state) =>{
        state.loading = true
    },
    [userChangePhoto.fulfilled]: (state, {payload}) =>{
      state.loading = false
      state.imageUrl = payload.imageUrl
    },
    [userChangePhoto.rejected]: (state) =>{
      state.loading = false
    },

    // userDocsUpload
    [userDocsUpload.pending]: (state) =>{
      state.loading = true
    },
    [userDocsUpload.fulfilled]: (state, {payload})=>{
      state.loading = false
      console.log(payload)
      if(payload.type === 'selfie'){
        state.selfieUrl = payload.resp.imageUrl
      }
      else{
        state.idUrl = payload.resp.imageUrl
      }
    },
    [userDocsUpload.rejected]: (state) =>{
      state.loading = false
    },

    // user withdraw funds -----  ------------
    [userWithdrawFunds.pending]: (state) =>{
      state.loading = true
    },
    [userWithdrawFunds.fulfilled]: (state) =>{
      state.loading = false
      state.withdrawSuccess = true
    },
    [userWithdrawFunds.rejected]: (state) =>{
      state.loading = false
    },

    // ---- get banks accounts based on country
    [getBankAccounts.pending]: (state) =>{

    },
    [getBankAccounts.fulfilled]: (state,{payload}) =>{
      state.banks = payload
    },
    [getBankAccounts.rejected]: (state) =>{

    },

    // resolve account name
    [getAccountName.pending]: (state) =>{
      
    },
    [getAccountName.fulfilled]: (state, {payload}) =>{
      state.accountName = payload.message.account_name
    },
    [getAccountName.rejected]: (state) =>{
      state.accountName = ""
    },
    // get ghana mobile accounts
    [getGhanaMobileMoneyAccounts.pending]: (state) =>{

    },
    [getGhanaMobileMoneyAccounts.fulfilled]: (state,{payload}) =>{
      state.banks = payload
    },
    [getGhanaMobileMoneyAccounts.rejected]: (state) =>{

    },

    // get ghana banks branches
    [getGhanaBankBranches.pending]: (state) =>{

    },
    [getGhanaBankBranches.fulfilled]: (state,{payload}) =>{
      state.branches = payload
    },
    [getGhanaBankBranches.rejected]: (state) =>{

    },

  
    // enable 2fa
    [enableTwoFA.pending]: (state) =>{
      state.loading = true
    },
    [enableTwoFA.fulfilled]:(state,{payload}) =>{
      console.log(payload)
      state.loading = false
      state.TwoFaSuccess = true
      state.TwoFaInfo = payload.message
      state.isTwoFaEnabled = true
    },
    [enableTwoFA.rejected]:(state) =>{
      state.loading = false
    },
    // disable 2fa
    [disableTwoFA.pending]: (state) =>{
        state.loading = true
    },
    [disableTwoFA.fulfilled]:(state,{payload}) =>{
      state.loading = false
      state.isTwoFaEnabled = false
    },
    [disableTwoFA.rejected]: (state) =>{
      state.loading = false
    },
    // verify google auth code
    [verifyTwoFAAuth.pending]: (state) =>{
        state.loading = true
    },
    [verifyTwoFAAuth.fulfilled]:(state,{payload}) =>{
      state.loading = false
      state.twoAuthVerified = true

    },
    [verifyTwoFAAuth.rejected]: (state) =>{
      state.loading = false

    },
    // verify email account
    [verifyTwoFAEmail.pending]: (state) =>{
      state.loading = true

    },
    [verifyTwoFAEmail.fulfilled]: (state) =>{
      state.loading = false
      state.twoAuthVerified = true
    },
    [verifyTwoFAEmail.rejected]: (state) =>{
      state.loading = false
    },
    // get KYC Levels
    [getKYCLevel.pending]: (state) =>{

    },
    [getKYCLevel.fulfilled]: (state, {payload}) =>{
        state.userKYCLevel = payload.message.kycSettingId.level
    },
    [getKYCLevel.rejected]: (state)=>{

    },
    // get kyc settings
    [getKYCSettings.pending]: (state) =>{

    },
    [getKYCSettings.fulfilled]: (state, {payload}) =>{
      state.kycSettings = payload.message
    },
    [getKYCSettings.rejected]: (state) =>{
      
    },
    // verify BVN
    [verifyBVN.pending]: (state) =>{
      state.upgradeLoader = true
    },
    [verifyBVN.fulfilled]: (state) =>{
      state.upgradeLoader = false
      state.isKYCVerified = true
    },
    [verifyBVN.rejected]: (state) =>{
      state.upgradeLoader = false
    },
    // verify selfie
    [verifyIDSelfie.pending]: (state) =>{
      state.upgradeLoader = true
    },
    [verifyIDSelfie.fulfilled]: (state) =>{
      state.upgradeLoader = false
      state.isKYCVerified = true
    },
    [verifyIDSelfie.rejected]: (state) =>{
      state.upgradeLoader = false
    },
    // verify address
    [verifyAddress.pending]: (state) =>{
      state.upgradeLoader = true
    },
    [verifyAddress.fulfilled]: (state) =>{
      state.upgradeLoader = false
      state.isKYCVerified = true
    },
    [verifyAddress.rejected]:(state) =>{
      state.upgradeLoader = false
    }


  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, Logout,clearSignUpSuccess,
   clearUserVerification, clearResetSuccess, clearWithdrawSuccess,clearForgetPinSuccess, VALID_TOKEN, clearValidToken, clearResetPinSuccess, clearAccountAdded, clearTwoFaSuccess,clearisKYCVerified } = authSlice.actions

export default authSlice.reducer