import React,{useState} from 'react'
import security_bg from '../../assets/images/security_bg.svg'
import arrow_right from '../../assets/icons/arrow-right-circle.svg'
import danger_icon from '../../assets/icons/danger.svg'
import { Link } from 'react-router-dom'
import ModalComponent from '../../components/Modals/modal'
import { useSelector, useDispatch } from 'react-redux'
import { disableTwoFA } from '../../redux/Auth/authActions'

const SecurityPage = () => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const { isTwoFaEnabled, loading} = useSelector((state) => state.auth);


    const showModal = () =>{
        setShow(!show)
      }

      const handleDeactivate = () =>{
        dispatch(disableTwoFA())

        setTimeout(()=>{
            setShow(false)
        },2000)
      } 

    return (
        <>
          {/* Modal */}
          <ModalComponent title="" show={show} showModal={showModal}>
            <div className='mt-6'>
                <div className='flex justify-center'>
                    <div className='flex justify-center items-center bg-[#2D2A28] rounded-full w-[100px] h-[100px]'>
                        <img src={danger_icon} alt="danger icon" className='w-[40px] h-[40px]' />
                    </div>
                </div>
        
                <div className='flex justify-center mt-4'>
                    <p className='text-[#F1F5F6] text-sm text-center font-Gilroy400'>Please Confirm! <br/> You are about to deactivate 2FA verification on your Account.</p>
                </div>

              <div className='flex justify-center mt-8 mb-5'>
               <div className='flex gap-3'>
                  <button
                     type="submit"
                     onClick={() => setShow(false)}
                     disabled={false}
                     className="bg-[#1B1A20] 
                           disabled:bg-[#1B1A20] 
                           rounded-lg px-10 py-3 text-white text-sm font-Gilroy500 border border-[#3B95FD] transition-all"
                  >
                     Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    onClick={handleDeactivate}
                    className="bg-[#FF8400] 
                    disabled:bg-[#FF8400] 
                    disabled:opacity-[0.7]
                    text-sm
                    rounded-md px-10 py-3 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                >
                    Confirm
                </button>
             </div>
               
             </div>

            </div>
        </ModalComponent>

            <div>
                <img src={security_bg} alt="security bg" />
            </div>
            <div className='mt-10'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center'>
                    <p className='text-[#F1F5F6] font-Gilroy700 text-lg'>Secure your account</p>
                    <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Always keep your pin and password safe.</p>
                </div>

                <div className='mt-10 max-w-3xl'>
                    <h6 className='text-[#F1F5F6] font-Gilroy500 text-base'>Transaction Pin</h6>
                    <div className='flex flex-col md:flex-row mt-4 gap-2'>
                        <div className='basis-[65%]'>
                            <p className='text-[#F1F5F6] font-Gilroy400 text-sm max-w-xs'>Do you feel your pin is compromised? <br/> Tap to change pin</p>
                        </div>
                        <Link to="/security/change-pin" className='flex gap-2 mt-1 md:mt-0 items-center cursor-pointer basis-[35%]'>
                            <img src={arrow_right} alt="arrow right" />
                            <p className='text-[#3B95FD] font-Gilroy400 text-sm'>Change Pin</p>
                        </Link>
                     </div>

                     <div className='flex flex-col md:flex-row gap-2 mt-8'>
                        <div className='basis-[65%]'>
                            <p className='text-[#F1F5F6] font-Gilroy400 text-sm max-w-xs'>Can’t remember your transaction pin!<br/> Tap to change pin</p>
                        </div>
                        <Link to="/security/reset-pin" className='flex gap-2 mt-1 md:mt-0 basis-[35%] items-center cursor-pointer'>
                            <img src={arrow_right} alt="arrow right" />
                            <p className='text-[#3B95FD] font-Gilroy400 text-sm'>Reset Pin</p>
                        </Link>
                     </div>

                     <div className='mt-8 bg-[#F1F5F6] bg-opacity-[30%] w-full h-[1px]'></div>

                     <h6 className='text-[#F1F5F6] font-Gilroy500 text-base mt-8'>Account Password </h6>
                     <div className='flex flex-col md:flex-row  gap-2 mt-4'>
                        <div className='basis-[65%]'>
                            <p className='text-[#F1F5F6] font-Gilroy400 text-sm max-w-xs'>Do you feel your DartAfrica password is compromised? Tap to change password now.</p>
                        </div>
                        <Link to="/security/change-password" className='flex mt-1 md:mt-0 gap-2 basis-[35%] items-center cursor-pointer'>
                            <img src={arrow_right} alt="arrow right" />
                            <p className='text-[#3B95FD] font-Gilroy400 text-sm'>Change Password</p>
                        </Link>
                     </div>

                     <div className='mt-8 bg-[#F1F5F6] bg-opacity-[30%] w-full h-[1px]'></div>

                    <div className='flex  mt-8 items-center'>
                        <div className='basis-[65%]'>
                          <h6 className='text-[#F1F5F6] font-Gilroy500 text-base'>2FA Authentication</h6>
                        </div>
                       {
                        isTwoFaEnabled 
                        ?
                          <div className='flex  bg-[#9CC0311A] rounded-[100px] py-1 px-5 text-[#9CC031] text-xs font-Gilroy400'>
                                 <p>Active</p>
                            </div>
                            :
                         <div className='flex  bg-[#EB001B26] rounded-[100px] py-1 px-5 text-[#E41414] text-xs font-Gilroy400'>
                            <p>Inactive</p>
                        </div>
                       }
                      
                    </div>
                    
                     <div className='flex flex-col md:flex-row  gap-2 mt-4'>
                        <div className='basis-[65%]'>
                            <p className='text-[#F1F5F6] font-Gilroy400 text-sm max-w-xs'>Activate 2FA authentication for more security. Tap to activate 2FA authentication now.</p>
                        </div>
                        {
                        isTwoFaEnabled 
                        ?
                        <div onClick={() => showModal()} className='flex mt-1 md:mt-0 gap-2 basis-[35%] items-center cursor-pointer'>
                            <img src={arrow_right} alt="arrow right" />
                            <p className='text-[#3B95FD] font-Gilroy400 text-sm'>Deactivate 2FA Authentication</p>
                        </div>
                        :
                         <Link to="/security/2fasetup" className='flex mt-1 md:mt-0 gap-2 basis-[35%] items-center cursor-pointer'>
                         <img src={arrow_right} alt="arrow right" />
                         <p className='text-[#3B95FD] font-Gilroy400 text-sm'>Setup 2FA Authentication</p>
                     </Link>
                        }
                     </div>

                </div>

            </div>
        </>
      );
}
 
export default SecurityPage;