import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from './components/UserSidebar/sidebar';
import ProtectedRoute from './components/PrivateRoutes/userRoute'
import AccountDetailsPage from './pages/Accounts/accounts';
import AddAccountPage from './pages/Accounts/addAccount';
import TwoFaEmailPage from './pages/Auth/2fa-email';
import TwoFaGooglePage from './pages/Auth/2fa-google';
import ForgotPasswordPage from './pages/Auth/forgot';
import LoginPage from './pages/Auth/login';
import RegisterPage from './pages/Auth/register';
import ResetPasswordPage from './pages/Auth/reset';
import VerifyEmailPage from './pages/Auth/verifyEmail';
import DashboardPage from './pages/Dashboard/dashboard';
import ProfilePage from './pages/Profile/profile';
import CoinCalculatorPage from './pages/Rates';
import SecurityPage from './pages/Security';
import TwoFASetupPage from './pages/Security/2faSetup';
import ChangePasswordPage from './pages/Security/changePassword';
import ChangePinPage from './pages/Security/changePin';
import ResetPinPage from './pages/Security/resetPin';
import ResetPinTokenPage from './pages/Security/resetPinToken';
import SetNewPinPage from './pages/Security/setNewPin';
import SetPinPage from './pages/Security/setPin';
import SellCoinPage from './pages/SellCoin';
import TradePage from './pages/Trades/trade';
import TransactionsPage from './pages/Transactions/transactions';
import WithdrawPage from './pages/Withdraw';
import SellCoinCompletePage from './pages/SellCoin/SellCoinComplete';
import DojahComponent from './components/Dojah/DojahComponent';
import DojahComp from './components/Dojah/Dojah3';
// import Dojah from './components/Dojah/Dojah2';

function App() {

 

  return (
    <div>
       <Router>
         <Routes>
            <Route path="/" exact element={<LoginPage />} />
            <Route path="/register"  element={<RegisterPage />} />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/2fa-google" element={<TwoFaGooglePage />} />
            <Route path="/2fa-email" element={<TwoFaEmailPage />} />
            <Route path="/verifyemail/auth/activate" element={<VerifyEmailPage />} />
            <Route path="/rates" element={<CoinCalculatorPage />} />
            <Route path="/dashboard" element={
                  <ProtectedRoute>
                     <Sidebar title="Dashboard" children={<DashboardPage />} />
                  </ProtectedRoute>
             } />
             <Route path="/trades" element={
                 <ProtectedRoute>
                     <Sidebar title="Trades" children={<TradePage />} />
                  </ProtectedRoute>
             } />
               <Route path="/transactions" element={
                  <ProtectedRoute>
                    <Sidebar title="Transactions" children={<TransactionsPage />} />
                  </ProtectedRoute>
             } />
               <Route path="/account-details" element={
                  <ProtectedRoute>
                  <Sidebar title="Accounts" children={<AccountDetailsPage />} />
                  </ProtectedRoute>
             } />
              <Route path="/account-details/add" element={
                     <ProtectedRoute>
                       <Sidebar title="Add Account" children={<AddAccountPage />} />
                      </ProtectedRoute>
             } />
               <Route path="/withdraw" element={
                  <ProtectedRoute>
                     <Sidebar title="Withdraw" children={<WithdrawPage />} />
                  </ProtectedRoute>
             } />
              <Route path="/sellcoin" element={
                     <ProtectedRoute>
                        <Sidebar title="Sell Coins" children={<SellCoinPage
                        />} />
                    </ProtectedRoute>
             } />
               <Route path="/sellcoin/complete" element={
                        <Sidebar title="Sell Coins" children={<SellCoinCompletePage
                        />} />
                   
             } />
              <Route path="/profile" element={
                 <ProtectedRoute>
                    <Sidebar title="Profile" children={<ProfilePage />} />
                  </ProtectedRoute>
             } />
                <Route path="/security" element={
                   <ProtectedRoute>
                      <Sidebar title="Security" children={<SecurityPage />} />
                  </ProtectedRoute>
               } />
                  <Route path="/security/onboarding-complete" element={
                     <ProtectedRoute>
                       <Sidebar title="Security" children={<SetPinPage />} />
                    </ProtectedRoute>
               } />
              <Route path="/security/change-pin" element={
                     <ProtectedRoute>
                         <Sidebar title="Security" children={<ChangePinPage />} />
                   </ProtectedRoute>
               } />
                <Route path="/security/change-password" element={
                     <ProtectedRoute>
                           <Sidebar title="Security" children={<ChangePasswordPage />} />
                     </ProtectedRoute>
               } />
              <Route path="/security/reset-pin" element={
                  <ProtectedRoute>
                     <Sidebar title="Security" children={<ResetPinPage />} />
                  </ProtectedRoute>
               } />
             <Route path="/security/reset-pin/token" element={
                     <ProtectedRoute>
                       <Sidebar title="Security" children={<ResetPinTokenPage />} />
                     </ProtectedRoute>
               } />
             <Route path="/security/reset-pin/set" element={
                     <ProtectedRoute>
                       <Sidebar title="Security" children={<SetNewPinPage />} />
                    </ProtectedRoute>
               } />
               <Route path="/security/2fasetup" element={
                  <ProtectedRoute>
                     <Sidebar title="Security" children={<TwoFASetupPage />} />
                </ProtectedRoute>
               } />
                 <Route path="/verify-address" element={
                  <ProtectedRoute>
                     <DojahComponent />
                </ProtectedRoute>
               } />
                 <Route path="/verify-add" element={
                  <ProtectedRoute>
                     <DojahComp />
                </ProtectedRoute>
               } />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
