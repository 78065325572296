import { createSlice } from '@reduxjs/toolkit'
import { fetchTrades, filterTrades } from './tradeActions'

const initialState = {
  value: 0,
  loading: false,
  trades: []
}

export const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
  },
  extraReducers:{
       // fetch trades  -------------------- ---------------------------------------------------------------
       [fetchTrades.pending]: (state) => {
        state.loading = true
      },
      [fetchTrades.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.trades = payload.message
      },
      [fetchTrades.rejected]: (state, { payload }) => {
        state.loading = false
      },
        // filter trades  -------------------- ---------------------------------------------------------------
        [filterTrades.pending]: (state) => {
          state.loading = true
        },
        [filterTrades.fulfilled]: (state, { payload }) => {
          state.loading = false
          state.trades = payload.message
        },
        [filterTrades.rejected]: (state, { payload }) => {
          state.loading = false
        },
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, } = tradeSlice.actions

export default tradeSlice.reducer