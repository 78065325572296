import React from 'react'
import security_bg from '../../assets/images/security_bg.svg'
import chevron_left from '../../assets/icons/chevron_left.svg'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { ResetTokenValidator } from '../../validationSchema/validator'


const ResetPinTokenPage = () => {

    const handleSubmit = (values) => {
        console.log(values)
    }

    return ( 
        <>
             <div>
                <img src={security_bg} alt="security bg" />
            </div> 
            <div className='mt-10'>
                <div className='flex flex-row justify-between items-center'>
                    <p className='text-[#F1F5F6] font-Gilroy700 text-lg'>Secure your account</p>
                    <Link to="/security" className='cursor-pointer flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[10px] h-[10px]' />
                        <p className='text-[#EAECED80] text-sm font-Gilroy500'>Back</p>
                    </Link>
                </div>

                <div className='max-w-lg mx-auto mt-12'>
                    <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Enter Token</h5>
                    <p className='text-center text-[#F1F5F6] text-sm font-Gilroy400 mt-3'>Please provide the token sent to tomiczi***a@gmail.com</p>
                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={ResetTokenValidator}
                        initialValues={{
                            code: "",
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='code' className="block mb-2 text-[#F1F5F6] text-xs">
                            Token
                            </label>
                            <input
                                type="text"
                                name="code"
                                placeholder="Enter token"
                                className={
                                touched.code && errors.code
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.code && errors.code ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.code && errors.code}
                                </small>
                            ) : null}
                            </div>
                        
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={false}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Next
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>

                    <div className='flex justify-end mt-4'>
                        <div className='flex gap-2 items-center'>
                            <div className='bg-[#454545] w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#FF8400] w-[10px] h-[10px] rounded-full'></div>
                            <div className='bg-[#454545] w-[10px] h-[10px] rounded-full'></div>
                        </div>
                    </div>

                </div>


             </div>
        </>
     );
}
 
export default ResetPinTokenPage;