import React,{useState, useEffect} from 'react'
import security_bg from '../../assets/images/security_bg.svg'
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from 'react-redux';
import { userCreatePin } from '../../redux/Auth/authActions';
import { useNavigate } from 'react-router-dom';

const SetPinPage = () => {

    const dispatch = useDispatch()
    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const {loading,isSetPin } = useSelector((state) => state.auth);
    const [valid, setValid] = useState(false)
    const [confirmValid, setConfirmValid] = useState(false)
    const navigate = useNavigate()


    const handlePin = (val) => {
        setValid(false)
        setPin(val);
    };
    
    const handleConfirmPin = (val) => {
        setConfirmValid(false)
        setConfirmPin(val);
    };

    const handleSetPin = () =>{
        if(pin === ""){
            setValid(true)
        }
        else{
            if(pin !== "" && pin === confirmPin){
                dispatch(userCreatePin({
                    pin: pin
                }))
            }else{
                setConfirmValid(true)
            }
        }
       
    }


    useEffect(() =>{
        if(isSetPin){
            navigate('/dashboard')
        }
    },[isSetPin, navigate])
    

    return ( 
        <>
         <div>
            <img src={security_bg} alt="security bg" />
         </div>
            <div className='mt-10'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center'>
                    <p className='text-[#F1F5F6] font-Gilroy700 text-lg'>Secure your account</p>
                </div>

                <div className='max-w-xs mx-auto mt-8 md:mt-12'>
                     <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Set Transaction Pin</h5>
                    
                     <div className='mt-6'>
                        <div className='flex justify-center'>
                            <div>
                                 <p className='block mb-2 text-[#F1F5F6] text-xs'>Pin</p>
                                <PinInput
                                length={4}
                                initialValue={pin}
                                focus
                                secret
                                type="numeric"
                                onChange={handlePin}
                                inputStyle={{ borderColor: "#454545", color: '#F1F5F680' }}
                                inputFocusStyle={{ borderColor: "#454545" }}
                                />
                                {valid && 
                                <small style={{ color: "rgb(220, 53, 69)" }}>
                                    Pin is required!
                                    </small>
                                    }
                            </div>
                        </div>
                     
                    </div>

                    <div className='mt-6'>
                        <div className='flex justify-center'>
                            <div>
                                    <p className='block mb-2 text-[#F1F5F6] text-xs'>Confirm Pin</p>
                                <PinInput
                                length={4}
                                initialValue={confirmPin}
                                focus
                                secret
                                type="numeric"
                                onChange={handleConfirmPin}
                                inputStyle={{ borderColor: "#454545", color: '#F1F5F680' }}
                                inputFocusStyle={{ borderColor: "#454545" }}
                                />
                                 {confirmValid && 
                                <small style={{ color: "rgb(220, 53, 69)" }}>
                                    Pin must match!
                                    </small>
                                    }
                            </div>
                        </div>
                       
                    </div>
                         
                     <div className="mt-6">
                        <button
                            type="submit"
                            disabled={loading}
                            onClick={handleSetPin}
                            className="bg-[#FF8400] 
                            disabled:bg-[#FF8400] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-md w-full px-6 py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                        >
                            Set Pin
                        </button>
                     </div>

                     <div className='text-center mt-2'>
                        <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Your pin will be used to complete all withdrawal request
                            Please keep it safe.</p>
                     </div>

                </div>

            </div>
        </>
     );
}
 
export default SetPinPage;