import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from 'cogo-toast'

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const userLogin = createAsyncThunk(
    'login',
    async ({ email, password }, { rejectWithValue, dispatch }) => {
      dispatch({type: 'auth/UserEmail', email})
      try {
        const res = await axios.post(
          `${apiUrl}login`,
          { 
            email: email, 
            password,
          }
        )
        if(res.status === 200){
          let role = res.data.message.profile.role;
          if(role === 'Seller'){
               cogoToast.success("Login Successful!",{
                position: 'bottom-right'
              })
            return res.data.message
          }
            else{
                cogoToast.error("User not authorized to access this page!");
                return rejectWithValue()
            }
        }
      } catch (error) {
        if(error.response.status === 401){
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
        else{
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
      }
    }
 )

export const userForgot = createAsyncThunk(
    'forgot',
    async ({ email }, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.post(
          `${apiUrl}forgotpassword`,
          { 
            email: email, 
          }
        )
        if(res.status === 201){
          cogoToast.success(
            "Check your email for password reset instructions!",
            {
              position: "top-center",
            }
          );
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if(error.response.status === 400){
          cogoToast.error('Kindly check that the credentials entered is valid!')
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

  export const verifyResetCode = createAsyncThunk(
    'verifyResetCode',
    async ({ code }, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.post(
          `${apiUrl}link/verify/forgotpasswordcode`,
          { 
            code: code,
          }
        )
        if(res.status === 200){
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if(error.response.status === 400){
          cogoToast.error('Oops, looks like the link has either expired or is invalid, please request again.')
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

export const userResetPassword = createAsyncThunk(
    'reset',
    async ({ code, password }, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.post(
          `${apiUrl}resetpassword`,
          { 
            code: code,
            password 
          }
        )
        if(res.status === 200){
          cogoToast.success(
            "Your password has been changed successfully, Kindly login again!",
            {
              position: "top-center",
            }
          );
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if(error.response.status === 400){
          cogoToast.error('Oops, looks like the link has either expired or is invalid, please request again.')
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

export const userRegister = createAsyncThunk(
  'register',
  async ({ email, password, userName, phoneNumber, countryTag, referralCode }, { rejectWithValue, dispatch }) => {
    dispatch({type: 'auth/UserEmail', email})
    try {
      const res = await axios.post(
        `${apiUrl}user/register`,
        { 
            username: userName,
            phoneNumber: phoneNumber,
            email: email,
            password: password,
            country: countryTag === "GH" ? 'Ghana' : 'Nigeria',
            countryTag: countryTag,
            refferedBy: referralCode
        }
      )
      if(res.status === 201){
          cogoToast.success("Registration Successful!, Kindly login to proceed");
          return res.data.message
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
          cogoToast.error('Oops! Looks like a user already exist with this credentials.')
        return rejectWithValue(error.response.data.error)
      } else {
          cogoToast.error('Oops! Looks like a user already exist with this credentials.')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const verifyEmail = createAsyncThunk(
  'verifyEmail',
  async ({code}, {rejectWithValue, dispatch}) => {

    try {
      const res = await axios.post(
        `${apiUrl}verifyuser`,
        {
          verificationCode: code
        }
      )
      if(res.status === 200){
        console.log(res.data)
         cogoToast.success('Email verified successfully!')
          return res.data.data
      }
      
    } catch (error) {
      console.log(error)
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        cogoToast.error('Oops, looks like the code has either expired or is invalid, please request again.')
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const resendVerifyEmail = createAsyncThunk(
  'resendVerifyEmail',
  async ({email}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}resendverificationlink`,
        {
          email:  email
        },
      )
      if(res.status === 200){
        cogoToast.success(
          "A verification email has been sent to you, Check to verify your DartAfrica Account.",
          {
            position: "top-center",
          }
        );
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('Oops, there was an error making this request!')
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getWalletBalance = createAsyncThunk(
  'getWalletBalance',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}walletbalance`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.message
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getUserSummary = createAsyncThunk(
  'getUserSummary',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}seller/summary`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.message
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const addAccountDetails = createAsyncThunk(
  'addAccountDetails',
  async (val, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}accountdetails`,
        { 
           ...val
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success("Account details added successfully!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error("Error while adding account details")
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error("Error while adding account details")
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)


export const deleteAccountDetails = createAsyncThunk(
  'deleteAccountDetails',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(
        `${apiUrl}accountdetails/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success("Account details deleted successfully!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error("Error while deleting account details")
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error("Error while deleting account details")
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const userCreatePin = createAsyncThunk(
  'userCreatePin',
  async ({ pin }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}createpin`,
        { 
          pin: parseInt(pin)
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
        cogoToast.success("Pin created successfully!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const userChangePin = createAsyncThunk(
  'userChangePin',
  async ({ pin, newpin }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}updatepin`,
        { 
          oldPin: Number(pin),
          newPin: Number(newpin)
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success("Pin changed successfully!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const userForgetPin = createAsyncThunk(
  'userForgetPin',
  async ({ email, phoneNumber }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}forgotpin`,
        { 
          email: email,
          phoneNumber: phoneNumber
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success("Request completed, please proceed!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error("Check that the details entered are correct!");
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error("Check that the details entered are correct!");
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const verifyResetPinCode = createAsyncThunk(
  'verifyResetPinCode',
  async ({ code }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}link/verify/pincode`,
        { 
          code: code,
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        dispatch({type: 'auth/VALID_TOKEN', code})
        cogoToast.success("Token valid, please proceed to set your new pin!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error("This token is either invalid or has expired!");
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error("This token is either invalid or has expired!");
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const userResetPin = createAsyncThunk(
  'userResetPin',
  async ({ pin }, { rejectWithValue, dispatch, getState }) => {
    let code =  getState().auth.token_value
    try {
      const res = await axios.post(
        `${apiUrl}resetpin`,
        { 
          code: code,
          pin: parseInt(pin)
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success("Pin reset successful!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error("Error while resetting pin!");
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error("Error while resetting pin!");
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const userChangePassword = createAsyncThunk(
  'userChangePassword',
  async ({ password, newpassword }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}changepassword`,
        { 
          oldPassword: password,
          newPassword: newpassword
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        localStorage.setItem("token", "");
         dispatch({type: 'auth/Logout'})
         cogoToast.success('Password updated successfully! Kindly Login again.', { position: 'bottom-right', })
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Check that your old password is correct!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Check that your old password is correct!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const userChangePhoto = createAsyncThunk(
  'userChangePhoto',
  async ({ file }, { rejectWithValue, dispatch }) => {
    let formData = new FormData()
    formData.append("file", file);
    try {
      const res = await axios({      
        method: "post",
        url: `${apiUrl}profileimage`,
        data: formData,
        headers: { 
          "Content-Type": "multipart/form-data",
          'Authorization': `${getToken()}`
        },
      
    })
      if(res.status === 201){
        cogoToast.success("Profile image updated successfully!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Error while uploading image!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Error while uploading image!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const userWithdrawFunds = createAsyncThunk(
  'userWithdrawFunds',
  async ( val, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}withdraw`,
        { 
         ...val
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
         cogoToast.success("Request Successful, Your payment is on the way!");
          return res.data
      }
    } catch (error) {
      let msg = error.response.data ? error.response.data.message : "Ooops!, There seems to be an error processing this request!"
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${msg}`);
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error(`${msg}`);
        return rejectWithValue(error.response.data.error)
        
      }
    }
  }
)

export const getBankAccounts = createAsyncThunk(
  'getBankAccounts',
  async (arg, { rejectWithValue, dispatch, getState }) => {
    let countryCode = getState().auth.countryTag
    try {
      const res = await axios.get(
        `${apiUrl}banks/code/${countryCode}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.message
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getAccountName = createAsyncThunk(
  'getAccountName',
  async ({ account_bank,account_number }, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${apiUrl}bank/accountname`,
        { 
          account_bank: account_bank,
          account_number: account_number,
          country: "NG"
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success("Account name fetched!");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error("Error while resolving account name!");
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error("Error while resolving account name!");
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)


export const getGhanaMobileMoneyAccounts = createAsyncThunk(
  'getGhanaMobileMoneyAccounts',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}mobilewallet/code`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.message
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getGhanaBankBranches = createAsyncThunk(
  'getGhanaBankBranches',
  async ({code}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}bank/branch/${code}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.message
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const enableTwoFA = createAsyncThunk(
  'enableTwoFA',
  async ({ type }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}enable/twofa`,
        { 
          type: type,
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 201 || res.status === 200){
        cogoToast.success('2fa authentication activated successfully!', { position: 'bottom-right' })
         return res.data
        
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Oops an error occured enabling your 2fa!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Oops an error occured enabling your 2fa!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const disableTwoFA = createAsyncThunk(
  'disableTwoFA',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(
        `${apiUrl}deactivate/twofa`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success('2fa authentication deactivated successfully!', { position: 'bottom-right' })
         return res.data
        
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Oops an error occured deactivating your 2fa!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Oops an error occured deactivating your 2fa!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const verifyTwoFAEmail = createAsyncThunk(
  'verifyTwoFAEmail',
  async ({ code }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}verify/email-code/twofa`,
        { 
          verificationCode: code,
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success('Code validated successfully!', { position: 'bottom-right' })
         return res.data
        
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Please check that the code entered is valid!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Please check that the code entered is valid!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const verifyTwoFAAuth = createAsyncThunk(
  'verifyTwoFAAuth',
  async ({ code }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}verify/auth/code`,
        { 
          token: code,
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        cogoToast.success('Code validated successfully!', { position: 'bottom-right' })
         return res.data
        
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Please check that the code entered is valid!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Please check that the code entered is valid!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const getKYCLevel = createAsyncThunk(
  'getKYCLevel',
  async (arg, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${apiUrl}kyc/user`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getKYCSettings = createAsyncThunk(
  'getKYCSettings',
  async (arg, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${apiUrl}kyc/settings`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const verifyBVN = createAsyncThunk(
  'verifyBVN',
  async ({ firstname, lastname, dob,bvn, kycSettingId
   }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/verify-bvn`,
        { 
          firstname,
          lastname,
          dob,
          bvn,
          kycSettingId
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
         cogoToast.success('KYC Level upgraded successfully!', { position: 'bottom-right', })
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('Oops,we could not verify this credentials!')
        return rejectWithValue(error.response)
      }
      else{
        cogoToast.error('Ooops, an error occured upgrading your level, kindly contact the admin!')
        return rejectWithValue(error.response)
      }
    }
  }
)

export const verifyIDSelfie = createAsyncThunk(
  'verifyIDSelfie',
  async ({ idNumber, idType, selfieUrl,idUrl, kycSettingId
   }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/id-selfie`,
        { 
          idNumber,
          idType,
          selfieUrl,
          idUrl,
          kycSettingId
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
         cogoToast.success('KYC Level upgraded successfully!', { position: 'bottom-right', })
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('Oops, we could not verify the documents/images uploaded, Please check that the document is clear or properly cropped!')
        return rejectWithValue(error.response)
      }
      else{
        cogoToast.error('Ooops, an error occured upgrading your level, kindly contact the admin!')
        return rejectWithValue(error.response)
      }
    }
  }
)

export const userDocsUpload = createAsyncThunk(
  'userDocsUpload',
  async ({ file,type }, { rejectWithValue, dispatch }) => {
    let formData = new FormData()
    formData.append("file", file);
    try {
      const res = await axios({      
        method: "post",
        url: `${apiUrl}link/image`,
        data: formData,
        headers: { 
          "Content-Type": "multipart/form-data",
          'Authorization': `${getToken()}`
        },
      
    })
      if(res.status === 201){
        cogoToast.success("Documents uploaded successfully!", { position: 'bottom-right', });
          return {
            type,
            resp: res.data
          }
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Error while uploading image!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Error while uploading image!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const verifyAddress = createAsyncThunk(
  'verifyAddress',
  async ({ data,metadata, kycSettingId,verificationUrl,referenceId
   }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/address`,
        { 
          data: data,
          metadata,
          verificationUrl,
          referenceId,
          kycSettingId
        
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
         cogoToast.success('KYC Level upgraded successfully!', { position: 'bottom-right', })
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error('Ooops, an error occured upgrading your level, kindly contact the admin!')
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error('Ooops, an error occured upgrading your level, kindly contact the admin!')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)


