import React from 'react'
import security_bg from '../../assets/images/security_bg.svg'
import chevron_left from '../../assets/icons/chevron_left.svg'
import { Form, Formik } from 'formik'
import { ChangePinValidator } from '../../validationSchema/validator'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userChangePin } from '../../redux/Auth/authActions'


const ChangePinPage = () => {
    
    const {loading} = useSelector((state) => state.auth);

    const dispatch = useDispatch()
    const handleSubmit = (values) => {
        console.log(values)
        dispatch(userChangePin({
            pin: values.pin,
            newpin: values.newpin,
        }))
    }
    
    return (  
        <>
            <div>
                <img src={security_bg} alt="security bg" />
            </div>
            <div className='mt-10'>
                <div className='flex flex-row justify-between items-center'>
                    <p className='text-[#F1F5F6] font-Gilroy700 text-lg'>Secure your account</p>
                    <Link to="/security" className='cursor-pointer flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[10px] h-[10px]' />
                        <p className='text-[#EAECED80] text-sm font-Gilroy500'>Back</p>
                    </Link>
                </div>

                <div className='max-w-lg mx-auto mt-12'>

                    <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Change Transaction Pin</h5>
                  <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={ChangePinValidator}
                        initialValues={{
                            newpin: "",
                            pin: "",
                            confirm_pin: "",
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='pin' className="block mb-2 text-[#F1F5F6] text-xs">
                            Current Transaction Pin
                            </label>
                            <input
                                type="password"
                                name="pin"
                                placeholder="Enter current pin"
                                className={
                                touched.pin && errors.pin
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.pin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.pin && errors.pin ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.pin && errors.pin}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='newpin' className="block mb-2 text-[#F1F5F6] text-xs">
                            New Pin
                            </label>
                            <div className="relative">
                                <input
                                type="password"
                                name="newpin"
                                placeholder="Enter your new pin"
                                className={
                                    touched.newpin && errors.newpin
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.newpin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.newpin && errors.newpin ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.newpin && errors.newpin}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='confirm_pin' className="block mb-2 text-[#F1F5F6] text-xs">
                            Confirm Pin
                            </label>
                            <div className="relative">
                                <input
                                type="password"
                                name="confirm_pin"
                                placeholder="Confirm Pin"
                                className={
                                    touched.confirm_pin && errors.confirm_pin
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.confirm_pin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.confirm_pin && errors.confirm_pin ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.confirm_pin && errors.confirm_pin}
                                </small>
                            ) : null}
                            </div>
                               
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Change Pin
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </>
    );
}
 
export default ChangePinPage;