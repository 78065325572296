import React,{useEffect, useState} from 'react'
import arrow_right from '../../assets/icons/arrow-right-circle.svg'
import trending_up from '../../assets/icons/trending-up.svg'
import layers_icon from '../../assets/icons/layers.svg'
import trending_down from '../../assets/icons/trending-down.svg'
import arrow_up from '../../assets/icons/arrow-up.svg'
import align_left_icon from '../../assets/icons/align-left.svg'
import DataTable from "react-data-table-component";
import ModalComponent from '../../components/Modals/modal'
import { Form, Formik } from 'formik'
import { filterValidator } from '../../validationSchema/validator'
import chevron_down from '../../assets/icons/chevron_down.svg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux'
import { fetchTransactions, filterTransactions } from '../../redux/Transactions/transactionActions'
import Moment from "react-moment";
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getUserSummary, getWalletBalance } from '../../redux/Auth/authActions'


const TransactionsPage = () => {

    const {loading, transactions} = useSelector((state) => state.transaction);
    const {countryTag, walletBalance, userSummary} = useSelector((state) => state.auth);


    const [show, setShow] = useState(false)
    const [transactionModal, setTransactionModal] = useState(false)
    const dispatch = useDispatch()

    const showModal = () =>{
        setShow(!show)
      }

      const showTransactionModal = () =>{
        setTransactionModal(!transactionModal)
      }


      const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
     }


      const [startDate, setStartDate] = useState(new Date());

      const [endDate, setEndDate] = useState(new Date());

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending-badge";
            break;
          case "Successful":
            result = "success-badge";
            break;
          case "Failed":
            result = "failed-badge";
            break;
          default:
            result = "pending-badge";
            break;
        }
        return result;
      };

    const columns = [
        {
            name: 'Type',
            selector: row => row.type,
        },
        {
            name: 'Amount',
            cell: (row) => <span>{`${countryTag === "GH" ? "GH₵" : "NGN"} ${row.amount}`}</span>,
        },
        {
            name: 'Narration',
            selector: row => row.narration,
        },
        {
            name: 'Date',
            cell: (row) => (
                <span>
                  <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
                </span>
            )
        },
        {
            name: 'Status',
            cell: (row) => (
                <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <button
                onClick={() => {
                    ViewTransaction(row.id);
                  }}
                className="border transition-all hover:bg-[#FF8400] hover:text-white border-[#FF8400] text-[#FF8400] rounded-[4px] font-Gilroy500 text-xs py-2 px-4"
                >
                View
                </button>
            ),
        },
    ];

    const [transactionInfo, setTransactionInfo] = useState({})

    const ViewTransaction = (id) =>{
        setTransactionModal(true)
        let res = transactions.find((val)=> val.id === id)
        setTransactionInfo(res)
        console.log(res)
    }
    
   

    const formatDate = (date) => {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
    };

    const handleSubmit = (values) => {
        let res;
        res = {
          from_date: formatDate(startDate),
          to_date: formatDate(endDate),
          status: values.status,
        };
        setShow(false)
        dispatch(filterTransactions({
            status: res.status,
            from_date: res.from_date,
            to_date: res.to_date
        }))
    }

    useEffect(()=>{
        dispatch(fetchTransactions())
        dispatch(getWalletBalance())
        dispatch(getUserSummary())
    },[dispatch])


    return ( 
        <>
          {/* Modal */}
          <ModalComponent title="Filter" show={show} showModal={showModal}>
            <div className='mt-6'>
               
                  <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={filterValidator}
                        initialValues={{
                        status: '',
                        amount: ''
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>

                        <div className='grid grid-cols-2 gap-4'>
                           <div>
                            <label htmlFor='startDate' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Start Date
                            </label>
                                <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                dateFormat="yyyy-MM-dd"
                            />
                            </div>

                            <div>
                            <label htmlFor='endDate' className="block mb-2 text-[#F1F5F6] text-xs">
                                  End Date
                            </label>
                            <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            dateFormat="yyyy-MM-dd"
                          />
                            </div>
                        </div>
                           
                        <div className="mt-6">
                        <label htmlFor='status' className="block mb-2 text-[#F1F5F6] text-xs">
                              Status
                        </label>
                        <div className='relative'>
                            <select
                                name="status"
                                className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                id="status"
                                values={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="" disabled selected>--Select--</option>
                                <option
                                value="All"
                                >
                                All
                                </option>
                                <option
                                value="Pending"
                                >
                                Pending
                                </option>
                                <option
                                value="Successful"
                                >
                                Successful
                                </option>
                                <option
                                value="Failed"
                                >
                                Failed
                                </option>
                            </select>
                            <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                        </div>
                            {touched.status && errors.status ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.status && errors.status}
                                </small>
                            ) : null}
                        
                        </div>

                          
                            
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={false}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Filter
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>
            </div>
        </ModalComponent>

        {/* transaction details Modal */}
        <ModalComponent title="Transaction Details" show={transactionModal} showModal={showTransactionModal}>
            <div className='mt-10'>
                <div className='flex justify-between'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Transaction Reference</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo && transactionInfo.id ? transactionInfo.id: 'N/A'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Date</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo && transactionInfo.createdAt ? moment(transactionInfo.createdAt).format("MMMM Do, YYYY"): 'N/A'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                     <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Amount</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{countryTag === "GH" ? "GH₵" : "NGN"} {transactionInfo && transactionInfo.amount ? transactionInfo.amount: '0.00'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Trade Type</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo && transactionInfo.type ? transactionInfo.type: 'N/A'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Narration</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo && transactionInfo.narration ? transactionInfo.narration: 'N/A'}</p>
                    </div>
                {
                transactionInfo && transactionInfo.hasOwnProperty("accountType") &&
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Account Type</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo && transactionInfo.accountType ? transactionInfo.accountType: 'N/A'}</p>
                    </div>
                    }
                </div>
            
                {
                transactionInfo && transactionInfo.hasOwnProperty("accountType") &&
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Account Name</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo && transactionInfo.accountName ? transactionInfo.accountName: 'N/A'}</p>
                    </div>
                
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>
                            {transactionInfo.accountType === "Bank" ? 'Account Number' : 'Mobile Number' }
                        </h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo 
                        && transactionInfo.accountType ? (transactionInfo.accountType === "Bank" ? transactionInfo.accountNumber : transactionInfo.mobileNumber ) : 'N/A'}</p>
                    </div>   
                </div>
                }
                
                <div className='flex justify-between mt-6'>
                {
                transactionInfo && transactionInfo.hasOwnProperty("accountType") &&
                    <div className=''>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Bank Name</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{transactionInfo && transactionInfo.accountType ? (transactionInfo.accountType === "Bank" ? transactionInfo.bankName : transactionInfo.mobileNetwork ): 'N/A'}</p>
                    </div>
                    }
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Status</h6>
                        <div className='flex mt-1'>
                            <p className={transactionInfo && transactionInfo.status ? getStatusColor(transactionInfo.status) : 'pending-badge'}>{transactionInfo && transactionInfo.status ? transactionInfo.status: 'Pending'}</p>
                        </div>

                    </div>
                
                </div>

                <div className="mt-10">
                        <Link
                        to="/sellcoin"
                            className="bg-[#FF8400] 
                            disabled:bg-[#FF8400] 
                            disabled:opacity-[0.7]
                            text-sm flex justify-center
                            rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                        >
                            Start Another Trade
                        </Link>
                        </div>
                 </div>
        </ModalComponent>

             <div className='grid md:grid-cols-4 gap-4'>
                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#09C1DD] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={trending_up} alt="trending" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Total Balance</h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{countryTag === "GH" ? "GH₵" : "NGN"} {walletBalance ? numberWithCommas(walletBalance): 0.00}</h4>
                            </div>
                    </div>
                    <div className='bg-[#02B15A26] rounded-[10px] py-1 px-4 h-fit'>
                            <p className='text-[#02B15A] text-xs font-Gilroy400'>+1.29%</p>
                    </div>  
                </div>

                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#E41414CC] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={trending_down} alt="trending" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Total Withdrawal</h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{countryTag === "GH" ? "GH₵" : "NGN"} {userSummary ? userSummary.totalWithdraw : 0}</h4>
                            </div>
                    </div>
                    
                </div>

                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#3B95FD] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={layers_icon} alt="layers " />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>
                                Pending Transactions
                                </h6>
                                <h4 className='text-[#FFFFFF] text-lg'>0</h4>
                            </div>
                    </div>
                    <Link to="/transactions" className='cursor-pointer'>
                      <img src={arrow_right} alt="arrow right" />
                    </Link>  
                </div>

                <Link to="/withdraw" className='bg-[#262422] cursor-pointer flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex items-center gap-4'>
                            <div className='bg-[#9CC031CC] w-[45px] h-[45px] flex justify-center items-center rounded-full'>
                                 <img src={arrow_up} alt="arrow_up" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Withdraw Funds</h6>
                            </div>
                    </div>
                     
                </Link>

           </div>

           <div className='bg-[#262422] rounded-[12px] mt-5 py-6 px-4'>
                <div className='flex flex-col md:flex-row md:justify-end gap-4 md:items-center'>
                    <div>
                    <input
                        type="text"
                        name="text"
                        placeholder="Search by amount, type"
                        className='appearance-none w-full md:min-w-[400px] placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-3 px-4'
                        />
                    </div>
                    <div>
                        <button onClick={() => showModal()} className='flex w-full justify-center items-center gap-2 border border-[#FF8400] text-[#F1F5F6] font-Gilroy500 text-sm py-3 px-6 rounded-[5px]'>
                            <img className='w-[16px] h-[16px]' src={align_left_icon} alt="align left" />
                            <span>Filter By</span>
                        </button>
                    </div>
                </div>

                <div className='mt-4 md:mt-2'>
                    <DataTable
                        title="Transactions"
                        pagination
                        progressPending={loading}
                        persistTableHead
                        columns={columns}
                        data={transactions}
                        />
                </div>
            </div>

        </>
     );
}
 
export default TransactionsPage;