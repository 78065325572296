
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const fetchTrades = createAsyncThunk(
    'fetchTrades',
    async (arg, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.get(
          `${apiUrl}seller/trades`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if(error.response.status === 400){
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

  export const filterTrades = createAsyncThunk(
    'filterTrades',
    async ({status, from_date, to_date}, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.get(
          `${apiUrl}seller/trades?status=${status}&from_date=${from_date}&to_date=${to_date}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if(error.response.status === 400){
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )