
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast"

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getCoins = createAsyncThunk(
    'getCoins',
    async (arg, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.get(
          `${apiUrl}rates`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `flipdart55`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if(error.response.status === 400){
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )


export const sellCoinTrade = createAsyncThunk(
  'sellCoinTrade',
  async (val, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}trade`,
        { 
           ...val
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
        cogoToast.success("Trade created successfully!");
        dispatch({type: 'rate/Trade_Display', val})
        return res.data.message
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error("Error while creating Trade!");
        return rejectWithValue(error.response.data.error)
      } else {
        cogoToast.error("Error while creating Trade!");
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)
