import React,{useState} from 'react'
import alert_circle from '../../assets/icons/alert-circle.svg'
import delete_icon from '../../assets/icons/delete_icon.svg'
import arrow_right_circle from '../../assets/icons/arrow-right-circle.svg'
import bank_icon from '../../assets/icons/bank.svg'
import danger_icon from '../../assets/icons/danger.svg'
import { Link } from 'react-router-dom'
import ModalComponent from '../../components/Modals/modal'
import { useSelector, useDispatch } from 'react-redux'
import { deleteAccountDetails } from '../../redux/Auth/authActions'

const AccountDetailsPage = () => {

    const [show, setShow] = useState(false)
    const {accountDetails, loading} = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const showModal = () =>{
        setShow(!show)
      }

      const [deleteId, setDeleteId] = useState(null)
      const toggleDeleteModal = (id) =>{
        setShow(true)
        setDeleteId(id)
      }

      const handleDeleteAccount = () =>{
            dispatch(deleteAccountDetails({
                id: deleteId
            }))
            setTimeout(()=>{
                setShow(false)
            },2000)
      }

    return ( 
        <>
        {/* Modal */}
        <ModalComponent title="" show={show} showModal={showModal}>
            <div className='mt-6'>
                <div className='flex justify-center'>
                    <div className='flex justify-center items-center bg-[#2D2A28] rounded-full w-[100px] h-[100px]'>
                        <img src={danger_icon} alt="danger icon" className='w-[40px] h-[40px]' />
                    </div>
                </div>
        
                <div className='flex justify-center mt-4'>
                    <p className='text-[#F1F5F6] text-sm text-center font-Gilroy400'>Please Confirm! <br/> You are about to delete your bank account?</p>
                </div>

              <div className='flex justify-center mt-8 mb-5'>
               <div className='flex gap-3'>
                  <button
                     type="submit"
                     onClick={() => setShow(false)}
                     disabled={false}
                     className="bg-[#1B1A20] 
                           disabled:bg-[#1B1A20] 
                           rounded-lg px-10 py-3 text-white text-sm font-Gilroy500 border border-[#3B95FD] transition-all"
                  >
                     Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    onClick={handleDeleteAccount}
                    className="bg-[#FF8400] 
                    disabled:bg-[#FF8400] 
                    disabled:opacity-[0.7]
                    text-sm
                    rounded-md px-10 py-3 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                >
                    Confirm
                </button>
             </div>
               
             </div>

            </div>
        </ModalComponent>

        {accountDetails && accountDetails.length > 0 ? (
             <div className='max-w-lg mx-auto mt-16'>
             <h6 className='text-[#F1F5F6] text-base'>Added Accounts</h6>
             {
                accountDetails.map((val) =>(
                    <>
                    <div key={val.id} className='mt-4 bg-[#262422] shadow-[#F1F5F60A] flex justify-between items-center rounded-[10px] py-8 px-5'>
                     <div className='flex gap-3 items-center'>
                         <div className='bg-[#3B95FD] w-[50px] h-[50px] rounded-[10px] flex justify-center items-center'>
                             <img src={bank_icon} alt="bank" />
                         </div>
                         <div>
                             <h6 className='text-[#F1F5F6] text-sm font-Gilroy500'>{val.accountName}</h6>
                             <p className='text-[#F1F5F6] text-xs font-Gilroy400'>
                                {val.accountType === "Bank"
                                  ? val.accountNumber
                                  : val.mobileNumber
                                }
                             </p>
                             <p className='text-[#F1F5F6] text-xs font-Gilroy400'>
                             {val.accountType === "Bank"
                                  ? val.bankName
                                  : val.mobileNetwork
                                }
                             </p>
                         </div>
                     </div>
                     <div onClick={() => toggleDeleteModal(val.id)} className='cursor-pointer bg-[#E41414CC] w-[30px] h-[30px] rounded-[6px] flex justify-center items-center'>
                         <img src={delete_icon} alt="arrow right" className='' />
                     </div>
                  </div> 
                </>
                ))
             }
            {/* <div className='mt-4 flex gap-2 items-center justify-center'>
                   <img src={alert_circle} alt="alert circle" className='w-[20px] h-[20px]' />
                   <p className='text-[#F1F5F6] text-sm font-Gilroy400'>Preferred account does not exist yet..</p> 
               </div> */}

               <Link to="/account-details/add" className='flex justify-center mt-4 gap-2 cursor-pointer'>
                   <img src={arrow_right_circle} alt="arrow right" className='w-[20px] h-[20px]' />
                   <p className='text-[#3B95FD] text-sm font-Gilroy400'>Add Bank Account</p>
               </Link>
           </div>
        )
        :
        <div className='flex flex-col justify-center items-center min-h-[400px] md:min-h-[500px]'>
                <div className='bg-[#262422] shadow-[#F1F5F60A] ] border border-[#454545] border-dashed w-full md:w-3/5 mx-auto py-10 px-8 rounded-[10px] '>
                <div className='flex gap-2 items-center justify-center'>
                <img src={alert_circle} alt="alert circle" className='w-[20px] h-[20px]' />
                <p className='text-[#F1F5F6] text-sm font-Gilroy400'>You currently have no bank account added</p> 
            </div>

            <Link to="/account-details/add" className='flex justify-center mt-4 gap-2 cursor-pointer'>
                <img src={arrow_right_circle} alt="arrow right" className='w-[20px] h-[20px]' />
                <p className='text-[#3B95FD] text-sm font-Gilroy400'>Add Bank Account</p>
            </Link>
          </div>
        </div>
       
            }
        
           
        </>
     );
}
 
export default AccountDetailsPage;