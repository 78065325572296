import React,{useRef, useEffect, useState} from 'react'
import profile_bg from '../../assets/images/profile_bg.jpg'
import user from '../../assets/images/_header.svg'
import camera_icon from '../../assets/icons/camera.svg'
import arrow_right from '../../assets/icons/arrow-right.svg'
import export_icon from '../../assets/icons/export.svg'
import info_icon from '../../assets/icons/info-circle.svg'
import camera_upload from '../../assets/icons/camera_upload.svg'
import IDlabel from '../../assets/icons/IDlabel.svg'
import chevron_down from '../../assets/icons/chevron_down.svg'
import circle from '../../assets/images/Ellipse 455.svg'
import { useSelector, useDispatch } from 'react-redux'
import { getKYCLevel, getKYCSettings, getWalletBalance, userChangePhoto, userDocsUpload, verifyBVN, verifyIDSelfie } from '../../redux/Auth/authActions'
import moment from "moment";
import KYCModalComponent from '../../components/Modals/KycModal'
import ModalComponent from '../../components/Modals/modal'
import { Form, Formik } from 'formik'
import { kycUpgrade2Validator, kycUpgrade3Validator } from '../../validationSchema/validator'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom'
// import Dojah from '../../components/Dojah/Dojah2'
import Dojah from 'react-dojah';
import cogoToast from 'cogo-toast'

const ProfilePage = () => {

    const fileRef = useRef(null);
    const selfieRef = useRef(null);
    const IDRef = useRef(null);
    const [selfiePicName, setSelfiePicName] = useState(null);
    const [IDPicName, setIDPicName] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {imageUrl, userInfo, loading, completedTrade, userKYCLevel,kycSettings,selfieUrl,idUrl,upgradeLoader,isKYCVerified,countryTag} = useSelector((state) => state.auth);
    const userId = useSelector((state) => state.auth.userInfo.id);

    const [show, setShow] = useState(false)
    const [showLevel2, setShowLevel2] = useState(false)
    const [showLevel3, setShowLevel3] = useState(false)
    const [showLevel4, setShowLevel4] = useState(false)
    const [bvnInfo, setShowBVNInfo] = useState(false)

    const showModal = () =>{
        setShow(!show)
    }
    const showLevel2Modal = () =>{
        setShowLevel2(!showLevel2)
    }

    const showLevel3Modal = () =>{
        setShowLevel3(!showLevel3)
    }

    const showLevel4Modal = () =>{
        setShowLevel4(!showLevel4)
    }

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }


    const showBVNInfoModal = () =>{
        setShowBVNInfo(!bvnInfo)
    }

    const handleUpgrade2 = () =>{
        setShow(false)
        setTimeout(()=>{
            setShowLevel2(!showLevel2)
        },500)
    }

    // const handleUpgrade3 = () =>{
    //     setShow(false)
    //     setTimeout(()=>{
    //         setShowLevel3(!showLevel3)
    //     },500)
    // }

    // const handleUpgrade4 = () =>{
    //     setShow(false)
    //     setTimeout(()=>{
    //         setShowLevel4(!showLevel4)
    //     },500)
    // }


    const handleUpgradeAction = (level) =>{
        switch(level){
            case 1:
               return handleUpgrade2()
            case 2:
                cogoToast.info('Kindly upgrade to this level via our mobile app on playstore or app store for an effective upgrade.')
                // return handleUpgrade3()
                break;
            case 3:
                cogoToast.warn('Upgrading to level 4 would be available soon, Please bear with us!')
                // return handleUpgrade4()
                break;
            case 4:
                break;
            default:
                break;
        }
    }

    const [startDate, setStartDate] = useState(new Date());

    const formatDate = (date) => {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
    };


    const handleBVNSubmit = (values) => {
        let res;
        let userKYCID = kycSettings.find((val) => val.level === 2).id
        res = {
            ...values,
            dob: formatDate(startDate)
        }
        dispatch(verifyBVN({
            firstname: res.firstname,
            lastname: res.lastname,
            dob: res.dob,
            bvn: res.bvn,
            kycSettingId: userKYCID
        }))
    }

    const handleIDSubmit = (values) =>{
        let res;
        let userKYCID = kycSettings.find((val) => val.level === 3).id
        res={
            ...values,
            selfieUrl: selfieUrl,
            idUrl: idUrl,
            userKYCID
        }
        dispatch(verifyIDSelfie({
            idNumber: res.idNumber,
            idType: res.idType,
            selfieUrl: res.selfieUrl,
            idUrl: res.idUrl,
            kycSettingId: userKYCID
        }))

    }

    const changedHandler = () => {
        let files = fileRef.current.files[0];
        dispatch(userChangePhoto({
            file: files
        }))
    }

   const changedSelfieHandler = () => {
        let files = selfieRef.current.files[0];
        console.log(files)
        setSelfiePicName(files.name)
        dispatch(userDocsUpload({
            file: files,
            type: 'selfie'
        }))
    }

    const changedIDHandler = () =>{
        let files = IDRef.current.files[0];
        console.log(files)
        setIDPicName(files.name)
        dispatch(userDocsUpload({
            file: files,
            type: 'id'
        }))
    }

    const [showComponent, setShowComponent] = useState(false)

    const handleAddress = () =>{
        setShowLevel4(false)
        setShowComponent(!showComponent)

        // setShowLevel4(false)
        // setTimeout(()=>{
        //     navigate('/verify-address')
        // },500)
    }


    const dojahAppID = '653a35025f5c380040bbc95e';
    const dojahPublicKey = 'test_pk_pFqz8c1LsZy7wSbb2RIkCPCzt';
    const dojahWidgetType = 'custom';      

    const configDojah = {
        widget_id: "653a35215f5c380040bbcd68",
    };  

    const userData = {
        first_name: "test",
        last_name:  "user",
      };
    
      const metadata = {
        user_id: userId ? userId : "",
      };

      const response = (type, data) => {

        if (type === "success") {
            //  let userKYCID = kycSettings.find((val) => val.level === 4).id
        //   dispatch(
        //     verifyAddress({
        //       metadata: data.metadata,
        //       data: data.data,
        //       verificationUrl: data.verificationUrl,
        //       referenceId: data.referenceId,
        //       kycSettingId: userKYCID
        //     })
        //   );
          console.log(data);
          navigate("/profile");
        } else if (type === "error") {
            navigate("/profile");
        //   cogoToast.error('Oops! there was an error')
        } else if (type === "close") {
            navigate("/profile");
        } else if (type === "begin") {
            console.log('begin')
        } else if (type === "loading") {
            console.log('loading')
        }
      };
   

    useEffect(()=>{
        if(isKYCVerified){
            setShowLevel2(false)
            setShowLevel3(false)
            setShowLevel4(false)
            dispatch(getKYCLevel())
        }
    },[isKYCVerified, dispatch])

    useEffect(()=>{
        dispatch(getWalletBalance())
        dispatch(getKYCLevel())
        dispatch(getKYCSettings())
    },[dispatch])

    return (
        <>

            {showComponent && 
                <Dojah
                response={response}
                appID={dojahAppID}
                publicKey={dojahPublicKey}
                type={dojahWidgetType}
                config={configDojah}
                userData={userData}
                metadata={metadata}
            />
             }

         {/* KYC LEVELS MODAL */}
         <KYCModalComponent title="KYC Levels" show={show} showModal={showModal}>
            <div className='mt-8 mb-4'>

                <div className='flex flex-col md:flex-row flex-wrap gap-6'>

                    {
                        kycSettings && kycSettings.length ?
                        kycSettings.map((val)=>(
                               <div key={val.id} className='bg-[#262422] min-w-[330px] items-center flex justify-between rounded-bl-[10px]  rounded-tr-[10px] py-5 px-3 relative'>
                              <img src={circle} alt="circle" className='absolute right-0' />
                          <div>
                                 <div className='flex gap-1 items-center'>
                                <div className='bg-[#211E1D] rounded-[25px] py-1 px-3 cursor-pointer'>
                                    <p className='text-[#FF8400] text-xs font-Gilroy500'>Level {val.level}</p>
                                </div>
                               {userKYCLevel === val.level &&
                                <div className='cursor-pointer'>
                                    <p className='text-white text-xs font-Gilroy400 italic'>Current</p>
                                </div>
                                }
                             </div>
                             <div>
                                <h4 className='text-[#797979] text-sm font-Gilroy400 mt-1'>Withdrawal Limit</h4>
                                <h2 className='text-white font-Gilroy700 text-xl mt-1'>N{val ? numberWithCommas(val.dailyWithdrawerLimit): 0}</h2>
                             </div>
                        </div>
                       {userKYCLevel === val.level && val.level !==4 &&
                        <div className='z-[2]'>
                           <div>
                              <button
                                    type="submit"
                                    disabled={false}
                                    onClick={() => handleUpgradeAction(val.level)}
                                    className="bg-[#FF8400] 
                                        disabled:bg-[#1B1A20] 
                                        rounded-[4px] px-4 py-2 text-white text-sm font-Gilroy400 border border-[#FF8400] transition-all"
                                >
                                <div className='flex gap-2 items-center'>
                                     <p>Upgrade</p>
                                    <img src={export_icon} alt="export icon" />
                                </div>
                             </button>
                            </div>
                        </div>
                        }
                                </div> 
                        ))
                        : null
                    }
                </div>

            </div>
        </KYCModalComponent>

        {/* Level 2 Modal Component */}
        <ModalComponent
         title="Upgrade Account" show={showLevel2} showModal={showLevel2Modal}>
            <div className='mt-8 mb-4'>
                
                <p className='text-center text-[#797979] text-sm font-Gilroy400'>Upgrade your account with more personal information</p>
                    
                <Formik
                onSubmit={(values, { setSubmitting }) =>
                handleBVNSubmit(values, setSubmitting)
                }
                validationSchema={kycUpgrade2Validator}
                initialValues={{
                    bvn: '',
                    dob: '',
                    lastname: '',
                    firstname: ''
                }}
            >
                {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
                }) => (
                <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>

                        <div>
                            <label htmlFor='firstname' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Firstname
                            </label>
                            <input
                                type="text"
                                name="firstname"
                                placeholder="Enter your firstname"
                                className={
                                touched.firstname && errors.firstname
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.firstname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.firstname && errors.firstname ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.firstname && errors.firstname}
                                </small>
                            ) : null}
                     </div>

                        <div>
                            <label htmlFor='lastname' className="block mt-4 mb-2 text-[#F1F5F6] text-xs">
                                  Lastname
                            </label>
                            <input
                                type="text"
                                name="lastname"
                                placeholder="Enter your lastname"
                                className={
                                touched.lastname && errors.lastname
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.lastname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.lastname && errors.lastname ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.lastname && errors.lastname}
                                </small>
                            ) : null}
                     </div>
                        <div>
                            <label htmlFor='bvn' className="block mt-4 mb-2 text-[#F1F5F6] text-xs">
                                  Bank Verification Number
                            </label>
                            <input
                                type="text"
                                name="bvn"
                                placeholder="Enter 11-digit BVN"
                                className={
                                touched.bvn && errors.bvn
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.bvn}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.bvn && errors.bvn ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.bvn && errors.bvn}
                                </small>
                            ) : null}
                     </div>
                     <div className='mt-3 flex items-center gap-2 cursor-pointer' onClick={showBVNInfoModal}>
                        <div className="bg-[#2356431F] w-[18px] h-[18px] rounded-full flex items-center justify-center">
                            <img src={info_icon} alt="info icon" />
                        </div>
                        <div>
                            <p className="text-[#fff] text-xs font-Gilroy400">Why do you need my BVN?</p>
                        </div>

                     </div>


                    <div className='mt-7'>
                        <div>
                        <label htmlFor='dob' className="block mb-2 text-[#F1F5F6] text-xs">
                            Date Of Birth
                        </label>
                            <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            dateFormat="yyyy-MM-dd"
                        />
                        </div>
                      </div>
                    
                    
                    <div className="mt-6">
                    <button
                        type="submit"
                        disabled={upgradeLoader}
                        className="bg-[#FF8400] 
                        disabled:bg-[#FF8400] 
                        disabled:opacity-[0.7]
                        text-sm
                        rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                    >
                        Upgrade Account
                    </button>
                    </div>
                
                </Form>
                )}
            </Formik>

            </div>
        </ModalComponent>
        
        {/* Bvn Info Modal */}
        <ModalComponent
         title="Why we need your BVN?" show={bvnInfo} showModal={showBVNInfoModal}>
            <div className='mt-8 mb-4'>
                
                <p className='text-center text-[#fff] text-sm font-Gilroy400'>
                Your BVN is required for compliance purposes, to increase your withdrawal limit, and for you to have access to more features.
                </p>

                 <p className='text-center text-[#fff] mt-4 text-sm font-Gilroy400'>
                 Please note that your BVN is never shared and it does not give us access to your bank account. Your details are safe with us.
                </p>
                                  
                <div className="mt-6 flex justify-center">
                <button
                    type="submit"
                    onClick={showBVNInfoModal}
                    className="bg-[#FF8400] 
                    disabled:bg-[#FF8400] 
                    disabled:opacity-[0.7]
                    text-sm
                    rounded-md px-12 py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                >
                    Continue
                </button>
                </div>
            
            </div>
        </ModalComponent>

        {/* Level 3 Modal Component */}
        <ModalComponent
         title="Upgrade Account" show={showLevel3} showModal={showLevel3Modal}>
            <div className='mt-8 mb-4'>
                
                <p className='text-center text-[#797979] text-sm font-Gilroy400'>Upgrade your account with more personal information</p>
                    
                <Formik
                onSubmit={(values, { setSubmitting }) =>
                handleIDSubmit(values, setSubmitting)
                }
                validationSchema={kycUpgrade3Validator}
                initialValues={{
                    idType: '',
                    idNumber: ''
                }}
            >
                {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
                }) => (
                <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>
     
                   <div>
                        <label htmlFor='selfie' className="block mb-2 text-[#F1F5F6] text-xs">
                               Upload Selfie
                        </label>

                        <div className='mt-2 flex items-center gap-2 border border-[#454545] rounded-md  bg-transparent py-4 px-4'>
                            <img src={camera_upload} alt="camera icon" className='w-[25px] h-[25px]' />
                            <label className='text-[#EAECED80] cursor-pointer font-Gilroy400 text-sm'>
                                <input 
                                ref={selfieRef}
                                type="file"
                                accept="image/*"  
                                onChange={() => changedSelfieHandler()}
                                className='hidden'/>
                               Please upload or take a current passport photograph
                            </label>    
                        </div>   
                   </div>
                  {selfiePicName && 
                  <div className='mt-2 flex items-center gap-2 cursor-pointer'>
                        <div className="bg-[#2356431F] w-[18px] h-[18px] rounded-full flex items-center justify-center">
                            <img src={info_icon} alt="info icon" />
                        </div>
                        <div>
                            <p className="text-[#fff] text-xs font-Gilroy400">{selfiePicName}</p>
                        </div>

                     </div>             
                    }   
                 
                 <div className='mt-6'>
                    <label htmlFor='idType' className="block mb-2 text-[#F1F5F6] text-xs">
                        Identification Type
                        </label>
                        <div className='relative'>
                        <select
                            name="idType"
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            id="idType"
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            values={values.idType}
                        >
                         <option selected value="" disabled>
                              Select ID Type
                        </option>

                        <option  value="NIN">
                             NIN
                        </option>

                        <option  value="INTERNATIONAL_PASSPORT" >
                              International Passport
                        </option>
                        <option  value="DRIVERS_LICENSE" >
                              Driver's License
                        </option>

                        <option  value="VOTERS_CARD" >
                              Voter's Card
                        </option>
                          
                        </select>
                        <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                        </div>
                        
                    {touched.idType && errors.idType ? (
                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                            {touched.idType && errors.idType}
                        </small>
                    ) : null}      
                  </div>

                     <div className='mt-6'>
                            <label htmlFor='idNumber' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Identification Number
                            </label>
                            <input
                                type="text"
                                name="idNumber"
                                placeholder="Enter ID Number"
                                className={
                                touched.idNumber && errors.idNumber
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.idNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.idNumber && errors.idNumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.idNumber && errors.idNumber}
                                </small>
                            ) : null}
                     </div>


                     <div className="mt-6">
                        <label htmlFor='IDImage' className="block mb-2 text-[#F1F5F6] text-xs">
                               Upload ID Image(Front)
                        </label>

                        <div className='mt-2 flex items-center gap-2 border border-[#454545] rounded-md  bg-transparent py-4 px-4'>
                            <img src={IDlabel} alt="IDlabel icon" className='w-[40px] h-[40px]' />
                            <label className='text-[#EAECED80] cursor-pointer font-Gilroy400 text-sm'>
                                <input 
                                ref={IDRef}
                                type="file"
                                accept="image/*"  
                                onChange={() => changedIDHandler()}
                                className='hidden'/>
                               Upload a copy of your International passport, Driver’s license, NIN card, or Voter’s card.
                            </label>    
                        </div>   
                   </div>
                  {IDPicName && 
                  <div className='mt-2 flex items-center gap-2 cursor-pointer'>
                        <div className="bg-[#2356431F] w-[18px] h-[18px] rounded-full flex items-center justify-center">
                            <img src={info_icon} alt="info icon" />
                        </div>
                        <div>
                            <p className="text-[#fff] text-xs font-Gilroy400">{IDPicName}</p>
                        </div>

                     </div>             
                    } 
                                
                     <div className="mt-6">
                        <button
                            type="submit"
                            disabled={selfieUrl === null || idUrl === null || upgradeLoader}
                            className="bg-[#FF8400] 
                            disabled:bg-[#FF8400] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                        >
                            Upgrade Account
                        </button>
                    </div>
                    
                </Form>
                )}
            </Formik>

            </div>
        </ModalComponent>

        {/* level 4 Modal Component */}
         <ModalComponent
         title="Upgrade Account" show={showLevel4} showModal={showLevel4Modal}>
            <div className='mt-8 mb-4'>

             <p className='text-center text-[#797979] text-sm font-Gilroy400'>Upgrade your account with more personal information</p>

                <p className='text-center text-[#fff] text-sm font-Gilroy400 mt-8'>
                You will be redirected to <span className='underline'>https://identity.dojah.io/?widget_id... </span>
                     to verify your address
                </p>
                                  
                <div className="mt-8">
                    <button
                        type="submit"
                        onClick={handleAddress}
                        className="bg-[#FF8400] 
                        disabled:bg-[#FF8400] 
                        disabled:opacity-[0.7]
                        text-sm
                        rounded-md w-full px-12 py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                    >
                        Proceed
                    </button>
                    <p onClick={showLevel4Modal} className='text-[#FF8400] text-sm font-Gilroy400 underline flex justify-center mt-2 cursor-pointer'>Go Back</p>
                </div>
            
            </div>
        </ModalComponent>
        


            <div className='hidden md:block'>
               <img src={profile_bg} alt="profile bg"  className='rounded-tr-[12px] rounded-tl-[12px]'/> 
            </div>
            <div className='mt-5 md:mt-20 max-w-2xl mx-auto'>
                <div className='flex flex-col md:flex-row gap-8'>
                    <div className='flex-shrink-0'>
                        {
                            !loading 
                            &&
                            <div className='flex justify-center'>
                            {
                             imageUrl && imageUrl !== ""
                                ?
                             <img src={imageUrl} alt="user" className='w-[180px] h-[180px] rounded-full border border-[#FF8400] p-2' />
                                :
                             <img src={user} alt="user" className='w-[180px] h-[180px] rounded-full border border-[#FF8400] p-2' />
                          }
                         </div>
                        }

                        {
                            loading &&
                            <div className="animate-pulse">
                                 <div className="w-[180px] h-[180px] rounded-full bg-gray-200"></div>
                           </div>
                        }
                      
                        <div className='mt-5 flex gap-3 justify-center items-center'>
                            <img src={camera_icon} alt="camera icon" className='w-[20px] h-[20px]' />
                            <label className='text-[#3B95FD] cursor-pointer font-Gilroy400 text-sm'>
                                <input 
                                ref={fileRef}
                                type="file"
                                accept="image/*"  
                                onChange={() => changedHandler()}
                                className='hidden'/>
                                Change Photo
                            </label>    
                        </div>
                        <div className='mt-4 text-center'>
                            <h5 className='text-[#F1F5F6] font-Gilroy700 text-lg'>{userInfo ? userInfo.username: ''}</h5>
                            <p className='text-[#989898] text-sm font-Gilroy400'>Joined: {userInfo ?  moment(userInfo.createdAt).format("MMMM Do, YYYY") : 'N/A'}</p>
                        </div>
                        <div className='bg-[#F1F5F6] w-full h-[1px] mt-4 bg-opacity-[50%]'></div>
                        <div className='mt-6 text-center'>
                            <p className='text-[#F1F5F6] font-Gilroy500 text-base'>{completedTrade ? completedTrade: 0}</p>
                            <p className='text-[#F47D20] text-sm font-Gilroy400'>Trade(s) Completed</p>
                        </div>
                    </div>
                    <div className='flex-1'>

                    {
                        countryTag !== 'GH' &&        
                    <div className='rounded-md flex items-center justify-between font-Gilroy400 bg-[#2D2A28CC] py-3 px-4 mb-6'>
                        <div>
                            <p className='text-[#FFFFFF] text-sm font-Gilroy500'>KYC Level</p>
                        </div>
                        
                        <div className='flex gap-2 items-center'>
                                <div className='bg-[#211E1D] rounded-[25px] py-2 px-6 cursor-pointer' onClick={() => setShow(true)}>
                                    <p className='text-[#FF8400] text-xs font-Gilroy500'>Level {userKYCLevel}</p>
                                </div>
                                <div className='cursor-pointer' onClick={() => setShow(true)}>
                                    <img src={arrow_right} alt="arrow_right" className='w-[20px] h-[20px]' />
                                </div>
                            </div>
                       </div>
                     }

                        <div className=''>
                            <label htmlFor='username' className="block mb-2 text-[#F1F5F6] text-xs">
                                    Username
                            </label>
                            <input
                                type="text"
                                name="username"
                                value={userInfo ? userInfo.username: ''}
                                placeholder="Enter your username"
                                disabled
                                className='appearance-none w-full font-Gilroy400 placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F6] text-sm focus:outline-none rounded-md  bg-[#2D2A28CC] py-4 px-4'
                            />
                        </div>

                        <div className='mt-6'>
                            <label htmlFor='email' className="block mb-2 text-[#F1F5F6] text-xs">
                                    Email Address
                            </label>
                            <input
                                type="text"
                                name="email"
                                disabled
                                value={userInfo ? userInfo.email: ''}
                                placeholder="Enter your email address"
                                className='appearance-none w-full font-Gilroy400 placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F6] text-sm focus:outline-none rounded-md  bg-[#2D2A28CC] py-4 px-4'
                            />
                        </div>

                        <div className='mt-6'>
                            <label htmlFor='phoneNumber' className="block mb-2 text-[#F1F5F6] text-xs">
                                   Phone Number
                            </label>
                            <input
                                type="text"
                                name="phoneNumber"
                                disabled
                                placeholder="Enter your phone number"
                                value={userInfo ? userInfo.phoneNumber: ''}
                                className='appearance-none w-full font-Gilroy400 placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F6] text-sm focus:outline-none rounded-md  bg-[#2D2A28CC] py-4 px-4'
                            />
                        </div>

                        <div className='mt-6'>
                            <label htmlFor='country' className="block mb-2 text-[#F1F5F6] text-xs">
                                    Country
                            </label>
                            <input
                                type="text"
                                name="country"
                                disabled
                                value={userInfo ? userInfo.country: ''}
                                placeholder="Enter your country"
                                className='appearance-none w-full font-Gilroy400 placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F6] text-sm focus:outline-none rounded-md  bg-[#2D2A28CC] py-4 px-4'
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
      );
}
 
export default ProfilePage;