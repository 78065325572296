import React from 'react';
import close_icon from '../../assets/icons/x-circle.svg'
import { CSSTransition } from 'react-transition-group'
import './modal.css'

const ModalComponent = ({showModal, show, children, title}) => {
    return ( 
        <>
          <CSSTransition
            in={show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
         <div className="modal 
                    overflow-y-auto overflow-x-hidden bg-[#534F4A80]
                     fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full
                    opacity-0 transition-all duration-[0.3s]"  onClick={showModal}>
             <div onClick={(e) => e.stopPropagation()} class="relative p-4 w-full max-w-xl h-full md:h-auto mx-auto my-32 modal-content translate-y-[-200px] transition-all duration-[0.3s]">
                 {/* modal content */}
                 <div class="relative bg-[#1B1A20] rounded-lg py-4 px-5">

                       {/* modal header */}
                    <div class="flex justify-between items-center">
                        <div>
                            <h3 class="text-base font-Gilroy500 text-[#F1F5F6]">
                                {title}
                            </h3>
                        </div>
                       
                        <div className='cursor-pointer' onClick={showModal}>
                           <img src={close_icon} alt="close" className='w-[30px] h-[30px]' />
                        </div>
                    </div>

                    {/* modal body */}
                    <div>
                        {children}
                    </div>

                </div>

             </div>
        </div>  
        </CSSTransition> 
        </>
     );
}
 
export default ModalComponent;