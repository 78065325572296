import { createSlice } from '@reduxjs/toolkit'
import { getCoins, sellCoinTrade } from './rateActions'

const initialState = {
  value: 0,
  loader: false,
  coins: [],
  ghAmount: 0,
  ngAmount: 0,
  coinAmount: 0,
  usdAmount: 0,
  trade_result: {},
  walletAddress: "",
  tradeSuccess: false,
  trade_display: {}
}

export const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    clearTradeSuccess: (state) =>{
      state.tradeSuccess = false
    },
    clearTradeValues: (state) =>{
      state.trade_display = {}
      state.walletAddress = ""
      state.trade_result={}
    },
    Trade_Display: (state,{val}) =>{
      state.trade_display = val
    },
    CoinRateCalculation: (state,{payload}) =>{
      let usdAmount;
      // first find the object of the coin selected
      let coinDetails = state.coins.find((pro) => pro.id === payload.id);

      // then do the conversion
      let usdRate = coinDetails.usdRateCoin;
      let localCurrencyRate = coinDetails.localCurrencyRate

      usdAmount = parseFloat(payload.amount) * parseFloat(usdRate);
      console.log(usdAmount)

      // calcuate the rate for the specific range the usd amount calcuated falls into
      let cedisRate =  localCurrencyRate.find(x =>  usdAmount >= x.minimumUsdValue  && usdAmount <= x.maximumUsdValue)
     
      if( typeof cedisRate === 'undefined'){
        cedisRate = localCurrencyRate[localCurrencyRate.length - 1]
      }

      let nairaRate =  localCurrencyRate.find(x =>  usdAmount >= x.minimumUsdValue  && usdAmount <= x.maximumUsdValue)
      if( typeof nairaRate === 'undefined'){
        nairaRate = localCurrencyRate[localCurrencyRate.length - 1]
      }

      // calculate coins for both country
      state.ghAmount =
        usdAmount *
        cedisRate.cedisRateUsd;

      state.ngAmount =
      usdAmount *
      nairaRate.ngnRateUsd;

      state.usdAmount = usdAmount
      state.coinAmount = parseFloat(payload.amount)

    },
    CoinUSDRateCalculation: (state, {payload}) =>{
      let usdAmount = parseFloat(payload.amount)
  
      // first find the object of the coin selected
      let coinDetails = state.coins.find((pro) => pro.id === payload.id);
      console.log(usdAmount)
      // then do the conversion
      let usdRate = coinDetails.usdRateCoin;
      let localCurrencyRate = coinDetails.localCurrencyRate

      let coinAmt = parseFloat(payload.amount) / usdRate

      // calcuate the rate for the specific range the usd amount calcuated falls into
      let cedisRate =  localCurrencyRate.find(x => usdAmount >= x.minimumUsdValue  &&  usdAmount <= x.maximumUsdValue)
     
      if( typeof cedisRate === 'undefined'){
        cedisRate = localCurrencyRate[localCurrencyRate.length - 1]
      }

      let nairaRate =  localCurrencyRate.find(x =>  usdAmount >= x.minimumUsdValue  && usdAmount <= x.maximumUsdValue)
      if( typeof nairaRate === 'undefined'){
        nairaRate = localCurrencyRate[localCurrencyRate.length - 1]
      }

      // calculate coins for both country
      state.ghAmount =
      usdAmount *
        cedisRate.cedisRateUsd;

      state.ngAmount =
      usdAmount *
      nairaRate.ngnRateUsd;

      state.coinAmount = parseFloat(coinAmt)
      state.usdAmount = usdAmount
    },
    clearCoinValues: (state) =>{
      state.ngAmount = 0
      state.ghAmount = 0
      state.coinAmount = 0
      state.usdAmount = 0
    }
  },
  extraReducers:{
       // get coin rates  -------------------- ---------------------------------------------------------------
       [getCoins.pending]: (state) => {
      
      },
      [getCoins.fulfilled]: (state, { payload }) => {
        state.coins = payload.message
      },
      [getCoins.rejected]: (state, { payload }) => {
        
      },

      // create trade ----- --------
      [sellCoinTrade.pending]: (state) =>{
        state.loader = true
      },
      [sellCoinTrade.fulfilled]: (state,{payload}) =>{
        state.loader = false
        state.trade_result = payload
        state.tradeSuccess = true
        state.walletAddress = payload.walletAddress
      },
      [sellCoinTrade.rejected]: (state)=>{
          state.loader = false
      }
     
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement,CoinRateCalculation,CoinUSDRateCalculation,clearCoinValues, clearTradeSuccess, clearTradeValues} = rateSlice.actions

export default rateSlice.reducer