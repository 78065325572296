import React,{useState} from 'react'
import security_bg from '../../assets/images/security_bg.svg'
import chevron_left from '../../assets/icons/chevron_left.svg'
// import qrcode from '../../assets/images/qr-code.svg'
import email from '../../assets/images/email.svg'
import { Link, useNavigate } from 'react-router-dom'
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy_icon from '../../assets/icons/content-copy.svg'
import cogoToast from 'cogo-toast'
import QRCode from "react-qr-code";
import { useSelector, useDispatch } from 'react-redux'
import { enableTwoFA } from '../../redux/Auth/authActions'
import spin_circle from '../../assets/icons/spin-circle.svg'
import { clearTwoFaSuccess } from '../../redux/Auth/auth'

const TwoFASetupPage = () => {

    const { TwoFaSuccess, TwoFaInfo, userInfo, loading} = useSelector((state) => state.auth);
    const [type, setType] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const toggleType = (val) =>{
        setType(val)
    }

    const maskEmail = (email = "") => {
        const [name, domain] = email.split("@");
        const { length: len } = name;
        const maskedName = name.substring(0,5) + "****" + name[len - 1];
        const maskedEmail = maskedName + "@" + domain;
        return maskedEmail;
      };

    const handleCopy = () => {
        cogoToast.success("Copied to clipboard");
    };

    const handleTwoFASetup = () =>{
        if(type === ""){
            cogoToast.info("Please select an authentication type")
        }
        else{
            dispatch(enableTwoFA({
                type: type
            }))
        }
    }

    const handleConfirm = () =>{
        navigate('/security')
        dispatch(clearTwoFaSuccess())
    }

    return ( 
        <>
           <div>
                <img src={security_bg} alt="security bg" />
            </div>
            <div className='mt-12'>
                <div className='flex flex-row justify-between items-center'>
                    <p className='text-[#F1F5F6] font-Gilroy700 text-lg'>Secure your account</p>
                    <Link to="/security" className='cursor-pointer flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[10px] h-[10px]' />
                        <p className='text-[#EAECED80] text-sm font-Gilroy500'>Back</p>
                    </Link>
                </div>

                <div className='max-w-lg mx-auto mt-12'>
                    <h6 className='text-[#F1F5F6] font-Gilroy500 text-base text-center'>Enable Two-Step Verification </h6>
                    <p className='text-[#F1F5F6] font-Gilroy400 text-sm text-center'>Select Authentication type to Add an extra layer of security</p>
                </div>

                <div className='max-w-lg mx-auto mt-8'>
                    <div className='flex justify-center gap-8'>
                        <div className='flex gap-3 items-center'>
                          <input id="single" type="radio" value="AUTH_SECRET" name="type"  checked={type === "AUTH_SECRET"} onChange={(e) =>toggleType(e.target.value)}
                            className="w-5 h-5 cursor-pointer" />
                           <label for="single" className='text-[#F1F5F6] font-Gilroy400 text-sm'>Google Authenticator</label> 
                        </div>
                        <div className='flex gap-3 items-center'>
                        <input id="bulk" type="radio" value="EMAIL" name="type" checked={type === "EMAIL"} onChange={(e) =>toggleType(e.target.value)}
                            className="w-5 h-5 cursor-pointer" />
                           <label for="bulk" className='text-[#F1F5F6] font-Gilroy400 text-sm'>Email</label> 
                        </div>    
                    </div>

                  {
                    !TwoFaSuccess &&
                   <div className='flex justify-center mt-8'>
                            <button
                            type="submit"
                            disabled={loading}
                            onClick={handleTwoFASetup}
                            className="bg-[#FF8400] 
                            disabled:bg-[#FF8400] 
                            disabled:opacity-[0.7]
                            text-sm 
                            rounded-md px-6 py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                        >
                            Proceed
                        </button>
                   </div>
                    }
                </div>

                {/* loader or spinner */}
                {loading &&
                <div className='flex flex-col min-h-[300px] items-center justify-center'>
                        <img src={spin_circle} className="animate-spin" alt="spin cirlce" />
                        <div className='mt-5 flex justify-center'>
                        <p className='text-[#FFFFFF] font-Gilroy500 text-[15px] text-center'>Please wait while it's been processed.</p>
                        </div>
                    </div>
                    }
                
                    

                {/* Google Auth Layout */}
                {
                    type === "AUTH_SECRET" && TwoFaSuccess &&
                 <div className='mt-12 max-w-3xl mx-auto'>
                    <div className='flex flex-col md:flex-row gap-6'>
                        <div className='border flex flex-col justify-center border-[#454545] rounded-lg py-6 px-4 basis-[35%]'>
                            <div className='flex justify-center'>
                                <QRCode
                                    title="Google Auth"
                                    size="200"
                                    value={TwoFaInfo ? TwoFaInfo.otpauth_url: "otpauth://totp/SecretKey?secret=LNWVQ22DOBHUKY3MIJFEUKB6Idemosecretkey"}
                                />
                            </div>
                            <p className='text-[#F1F5F6] mt-3 text-center text-xs font-Gilroy400'>Use Google Authentication App to scan the QR code</p>
                        </div>
                        <div className='basis-[65%]'>
                          <p className='text-[#F1F5F6] mt-1 text-sm font-Gilroy400 leading-[24px]'>If you are having any problem with scanning the QR Code enter this code manually</p>
                            <div className='mt-4 relative'>
                            <input
                                type="text"
                                name="text"
                                value={TwoFaInfo ? TwoFaInfo.base32: ""}
                                placeholder="Google Auth Code"
                                className='appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F6] text-sm  focus:outline-none rounded-md  bg-[#2D2A28CC] py-4 px-4'
                            />
                             <div className='bg-[#FF8400] w-[54px] h-full rounded-[10px] absolute right-0 top-0 flex justify-center items-center cursor-pointer'>
                                <CopyToClipboard
                                    text={TwoFaInfo ? TwoFaInfo.base32: ""}
                                    onCopy={() => handleCopy()}
                                    >
                                <img src={copy_icon} alt="copy icon" />
                                  </CopyToClipboard>
                                </div>
                            </div>
                                <div>
                                    <button
                                    type="submit"
                                    disabled={false}
                                    onClick={handleConfirm}
                                    className="bg-[#FF8400] 
                                    disabled:bg-[#FF8400] 
                                    disabled:opacity-[0.7]
                                    text-sm mt-4
                                    rounded-md px-6 py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                                >
                                    Confirm
                            </button>
                                </div>
                           
                        </div>
                    </div>
                </div>
                }
                

                {/* Email */}
                {
                    type === "EMAIL" && TwoFaSuccess &&
                <div className='mt-8 max-w-3xl mx-auto'>
                    <div className='flex flex-col md:flex-row gap-6'>
                        <div className='border flex flex-col justify-center border-[#454545] rounded-lg py-6 px-4 basis-[35%]'>
                            <div className='flex justify-center'>
                               <img src={email} alt="email" className='' />
                            </div>
                        </div>
                        <div className='basis-[65%]'>
                            <p className='text-[#F1F5F6] text-sm font-Gilroy400 leading-[24px]'>
                           Your email address {userInfo ? maskEmail(userInfo.email): ""} has been configured for your 2fa authentication
                            </p>

                            <div>
                                    <button
                                    type="submit"
                                    disabled={false}
                                    onClick={handleConfirm}
                                    className="bg-[#FF8400] 
                                    disabled:bg-[#FF8400] 
                                    disabled:opacity-[0.7]
                                    text-sm mt-4
                                    rounded-md px-6 py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                                >
                                    Confirm
                            </button>
                                </div>

                           
                        </div>
                    </div>
                </div>
                }


            </div>
        </>
     );
}
 
export default TwoFASetupPage;