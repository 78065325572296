import React from "react";
import Dojah from "react-dojah";
import { useSelector, useDispatch } from "react-redux";
import cogoToast from "cogo-toast";
import { verifyAddress } from "../../redux/Auth/authActions";
import { useNavigate } from "react-router-dom";

const DojahComponent = () => {

    const dispatch = useDispatch();

    const userId = useSelector((state) => state.auth.userInfo.id);
    const username = useSelector((state) => state.auth.userInfo.username);
    const {kycSettings} = useSelector((state) => state.auth);
    const navigate = useNavigate()


    
  const appID = "653a35025f5c380040bbc95e";
  const publicKey = "test_pk_pFqz8c1LsZy7wSbb2RIkCPCzt";
  const type = "custom";

  const config = {
    widget_id: "653a35215f5c380040bbcd68",
    webhook: false
  };

  const userData = {
    first_name: username ? username : "",
    last_name: username ? username : "",
  };

  const metadata = {
    user_id: userId ? userId : "",
  };

  const response = (type, data) => {
    if (type === "success") {
         let userKYCID = kycSettings.find((val) => val.level === 4).id
      dispatch(
        verifyAddress({
          metadata: data.metadata,
          data: data.data,
          verificationUrl: data.verificationUrl,
          referenceId: data.referenceId,
          kycSettingId: userKYCID
        })
      );
      console.log(data);
      navigate("/profile");
    } else if (type === "error") {
        navigate("/profile");
      cogoToast.error('Oops! there was an error')
    } else if (type === "close") {
        navigate("/profile");
    } else if (type === "begin") {
        console.log('begin')
    } else if (type === "loading") {
        console.log('loading')
    }
  };

  return (
       <Dojah
        response={response}
        appID={appID}
        publicKey={publicKey}
        type={type}
        config={config}
        userData={userData}
        metadata={metadata}
      />
  );
};

export default DojahComponent;