import React from 'react'
import security_bg from '../../assets/images/security_bg.svg'
import chevron_left from '../../assets/icons/chevron_left.svg'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { ChangePasswordValidator } from '../../validationSchema/validator'
import { useDispatch, useSelector } from 'react-redux'
import { userChangePassword } from '../../redux/Auth/authActions'

const ChangePasswordPage = () => {

    const dispatch = useDispatch()
    const {loading} = useSelector((state) => state.auth);

    const handleSubmit = (values) => {
        console.log(values)
        dispatch(userChangePassword({
            password: values.password,
            newpassword: values.newpassword 
        }))
    }

    return (  
        <>
           <div>
                <img src={security_bg} alt="security bg" />
            </div> 
            <div className='mt-10'>
                <div className='flex flex-row justify-between items-center'>
                    <p className='text-[#F1F5F6] font-Gilroy700 text-lg'>Secure your account</p>
                    <Link to="/security" className='cursor-pointer flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[10px] h-[10px]' />
                        <p className='text-[#EAECED80] text-sm font-Gilroy500'>Back</p>
                    </Link>
                </div>

                <div className='max-w-lg mx-auto mt-12'>
                    <h5 className='text-center text-[#F1F5F6] text-base font-Gilroy500'>Change Password</h5>
                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={ChangePasswordValidator}
                        initialValues={{
                            password: "",
                            newpassword: "",
                            confirm_password: "",
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='password' className="block mb-2 text-[#F1F5F6] text-xs">
                            Current Password
                            </label>
                            <input
                                type="password"
                                name="password"
                                placeholder="Enter current password"
                                className={
                                touched.password && errors.password
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.password && errors.password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.password && errors.password}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='newpassword' className="block mb-2 text-[#F1F5F6] text-xs">
                            New Password
                            </label>
                            <div className="relative">
                                <input
                                type="password"
                                name="newpassword"
                                placeholder="Enter your new password"
                                className={
                                    touched.newpassword && errors.newpassword
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.newpassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.newpassword && errors.newpassword ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.newpassword && errors.newpassword}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='confirm_password' className="block mb-2 text-[#F1F5F6] text-xs">
                            Confirm Password
                            </label>
                            <div className="relative">
                                <input
                                type="password"
                                name="confirm_password"
                                placeholder="Confirm Password"
                                className={
                                    touched.confirm_password && errors.confirm_password
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            
                            </div>
                            {touched.confirm_password && errors.confirm_password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.confirm_password && errors.confirm_password}
                                </small>
                            ) : null}
                            </div>
                            
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Change Password
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>

                    </div>

            </div> 
        </>
    );
}
 
export default ChangePasswordPage;