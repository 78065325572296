import React, { useEffect, useState } from 'react'
import logo from '../../assets/icons/logo-white.svg'
import chevron_left from '../../assets/icons/chevron-left.svg'
import eye from '../../assets/icons/eye.svg'
import eye_off from '../../assets/icons/eye-slash.svg'
import world from '../../assets/images/world.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { loginValidator } from '../../validationSchema/validator'
import {useDispatch, useSelector} from 'react-redux'
import { userLogin } from '../../redux/Auth/authActions'

const LoginPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {isAuthenticated, loading, isVerified, isSetPin, isTwoFaEnabled, twoFaType} = useSelector((state) => state.auth);

    const [passwordShown, setPasswordShown] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const handleSubmit = (values) => {
        console.log(values)
        dispatch(userLogin(values))
    }

    useEffect(()=>{
        if(isAuthenticated){
            if(isVerified){
                if(isTwoFaEnabled){
                    if(twoFaType === 'EMAIL'){
                        navigate('/2fa-email')
                    }
                    else{
                        navigate('/2fa-google')
                    }
                }
                else{
                    if(isSetPin){
                        navigate('/dashboard')  
                    }
                    else{
                        navigate('/security/onboarding-complete')   
                    } 
                } 
            }
            else{
                navigate('/verifyemail/auth/activate')
            }   
        }
    },[isAuthenticated, navigate, isVerified,isSetPin, twoFaType, isTwoFaEnabled])


    

    return ( 
        <>
        <div className='bg-[#1B1A20] font-Gilroy400 min-h-screen'>
            <div className='py-6 px-4 container mx-auto'>
                <div className='flex items-center justify-between'>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                    <a href="https://dartafrica.io" target="_blank" rel='noreferrer' className='flex gap-3 items-center'>
                        <img src={chevron_left} alt="chevron left" className='w-[8px] h-[8px]' />
                        <p className='text-[#EAECED80] text-sm'>Back to Homepage</p>
                    </a>
                </div>

                <div className='grid md:grid-cols-2 mt-20 items-center gap-8'>
                    <div>
                        <h4 className='text-[#F1F5F6] text-xl md:text-3xl'>Sign In</h4>
                        <p className='text-[#F1F5F6] text-sm'>With your DartAfrica credentials</p>

                        <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={loginValidator}
                        initialValues={{
                        email: '',
                        password: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='email' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Email Address
                            </label>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter your email address"
                                className={
                                touched.email && errors.email
                                    ? 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                }
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.email && errors.email}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='password' className="block mb-2 text-[#F1F5F6] text-xs">
                                Password
                            </label>
                            <div className="relative">
                                <input
                                 type={passwordShown ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter your password"
                                className={
                                    touched.password && errors.password
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#F1F5F680] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-transparent py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4'
                                    }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                               <img
                                onClick={togglePasswordVisiblity}
                                src={passwordShown ? eye_off : eye}
                                alt="eye"
                                className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                            </div>
                            {touched.password && errors.password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.password && errors.password}
                                </small>
                            ) : null}
                            </div>
                                <div className="mt-1 text-right">
                                    <Link to="/forgotpassword"
                                    className="text-[#3B95FD] text-xs  cursor-pointer"
                                    >
                                    Forgot password?
                                    </Link>
                                </div>

                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Sign In
                            </button>
                            </div>

                           
                            <div className='mt-6 text-center md:text-left'>
                                <p className='text-[#F1F5F6] text-sm'>Don’t have an account? <Link to="/register" className='text-[#3B95FD] font-Satoshi500 text-sm'>Sign up here</Link> </p>
                            </div>

                        
                        </Form>
                        )}
                    </Formik>

                    </div>
                    <div className='hidden md:block'>
                        <div className='relative'>
                          <img src={world} alt="world" className='bg-opacity-50' />
                            <div className='max-w-sm mx-auto text-center absolute top-1/2 left-1/2 transform -translate-y-1/2  -translate-x-1/2'>
                                <h4 className='text-[#F1F5F6] text-3xl font-Gilroy700 leading-10'>Unlock a World of <br/> Endless Possibilities.</h4>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div className='mt-28'>
                    <p className='text-[#F1F5F6] text-center md:text-left text-sm'>&copy; {new Date().getFullYear()} DartAfrica All rights reserved</p>
                </div>
            </div>
           
        </div>
        </>
     );
}
 
export default LoginPage;