import React,{useEffect, useState} from 'react'
import arrow_right from '../../assets/icons/arrow-right-circle.svg'
import trending_up from '../../assets/icons/trending-up.svg'
import layers_icon from '../../assets/icons/layers.svg'
import earnings_icon from '../../assets/icons/earnings.svg'
import briefcase_icon from '../../assets/icons/briefcase.svg'
import DataTable from "react-data-table-component";
import align_left_icon from '../../assets/icons/align-left.svg'
import arrow_blockchain from '../../assets/icons/arrow_blockchain.svg'
import ModalComponent from '../../components/Modals/modal'
import { Form, Formik } from 'formik'
import { filterValidator } from '../../validationSchema/validator'
import chevron_down from '../../assets/icons/chevron_down.svg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux'
import { fetchTrades, filterTrades } from '../../redux/Trade/tradeActions'
import Moment from "react-moment";
import moment from "moment";
import { Link } from 'react-router-dom'
import { getUserSummary, getWalletBalance } from '../../redux/Auth/authActions'


const TradePage = () => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [tradeModal, setTradeModal] = useState(false)
    const {loading, trades} = useSelector((state) => state.trade);
    const {countryTag, walletBalance, pendingTrade, userSummary} = useSelector((state) => state.auth);


    const showModal = () =>{
        setShow(!show)
      }

      const showTradeModal = () =>{
        setTradeModal(!tradeModal)
      }

      const [startDate, setStartDate] = useState(new Date());

      const [endDate, setEndDate] = useState(new Date());

      const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
     }


    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Created":
            result = "pending-badge";
            break;
          case "Confirmed":
            result = "success-badge";
            break;
          case "Failed":
            result = "failed-badge";
            break;
          default:
            result = "failed-badge";
            break;
        }
        return result;
      };

    const columns = [
        {
            name: 'Coin',
            selector: (row) => <span>{`${row.amounttInCoin} ${row.coinType}`}</span>,
        },
        {
            name: 'Amount Due',
            cell: (row) => <span>{`${countryTag === "GH" ? "GH₵" : "NGN"} ${row.amountInLocalCurrency}`}</span>,
        },
        {
            name: 'Date',
            cell: (row) => (
                <span>
                  <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
                </span>
            )
        },
        {
            name: 'Status',
            cell: (row) => (
                <span className={getStatusColor(row.tradeStatus)}>{`${row.tradeStatus}`}</span>
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: (row) => (
                <button
                onClick={() => {
                    ViewTrade(row.id);
                  }}
                className="border transition-all hover:bg-[#FF8400] hover:text-white border-[#FF8400] text-[#FF8400] rounded-[4px] font-Gilroy500 text-xs py-2 px-4"
                >
                View
                </button>
            ),
        },
    ];

    const [tradeInfo, setTradeInfo] = useState({})

    const ViewTrade = (id) =>{
        setTradeModal(true)
        let res = trades.find((val)=> val.id === id)
        setTradeInfo(res)
        console.log(res)
    }
  

    const formatDate = (date) => {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
    };

    const handleSubmit = (values) => {
        let res;
        res = {
          from_date: formatDate(startDate),
          to_date: formatDate(endDate),
          status: values.status,
        };
        setShow(false)
        dispatch(filterTrades({
            status: res.status,
            from_date: res.from_date,
            to_date: res.to_date
        }))
    }

    useEffect(()=>{
        dispatch(fetchTrades())
        dispatch(getWalletBalance())
        dispatch(getUserSummary())
    },[dispatch])

    return ( 
        <>
         {/* Filter Modal */}
         <ModalComponent title="Filter" show={show} showModal={showModal}>
            <div className='mt-6'>
               
                  <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={filterValidator}
                        initialValues={{
                        status: '',
                        amount: ''
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10 md:max-w-lg" onSubmit={handleSubmit}>

                        <div className='grid grid-cols-2 gap-4'>
                           <div>
                            <label htmlFor='startDate' className="block mb-2 text-[#F1F5F6] text-xs">
                                  Start Date
                            </label>
                                <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                dateFormat="yyyy-MM-dd"
                            />
                            </div>

                            <div>
                            <label htmlFor='endDate' className="block mb-2 text-[#F1F5F6] text-xs">
                                  End Date
                            </label>
                            <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                            dateFormat="yyyy-MM-dd"
                          />
                            </div>
                        </div>
                           
                        <div className="mt-6">
                        <label htmlFor='status' className="block mb-2 text-[#F1F5F6] text-xs">
                              Status
                        </label>
                        <div className='relative'>
                            <select
                                name="status"
                                className="appearance-none cursor-pointer w-full placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-4 px-4"
                                id="status"
                                values={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="" disabled selected>--Select--</option>
                                <option
                                value="All"
                                >
                                All
                                </option>
                                <option
                                value="Created"
                                >
                                Created
                                </option>
                                <option
                                value="Confirmed"
                                >
                                Confirmed
                                </option>
                                <option
                                value="Failed"
                                >
                                Failed
                                </option>
                            </select>
                            <img src={chevron_down} alt="chevron down" className='absolute right-6 top-1/2 -translate-y-1/2 w-[12px] h-[12px] cursor-pointer' />
                        </div>
                            {touched.status && errors.status ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.status && errors.status}
                                </small>
                            ) : null}
                        
                        </div>

                          
                            
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={false}
                                className="bg-[#FF8400] 
                                disabled:bg-[#FF8400] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                            >
                                Filter
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>
            </div>
        </ModalComponent>

          {/* Trade details Modal */}
          <ModalComponent title="Trade Details" show={tradeModal} showModal={showTradeModal}>
            <div className='mt-10'>
                <div className='flex justify-between'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Trade Reference</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.id ? tradeInfo.id: 'N/A'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Date</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.createdAt ? moment(tradeInfo.createdAt).format("MMMM Do, YYYY"): 'N/A'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Currency Traded</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.coinType ? tradeInfo.coinType: 'N/A'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Trade Type</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>Sell</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Amount Traded</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.amounttInCoin ? tradeInfo.amounttInCoin: 'N/A'} {tradeInfo && tradeInfo.coinType ? tradeInfo.coinType: 'N/A'}</p>
                    </div>
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Amount</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{countryTag === "GH" ? "GH₵" : "NGN"} {tradeInfo && tradeInfo.amountInLocalCurrency ? tradeInfo.amountInLocalCurrency: '0.00'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Wallet Address</h6>
                        <p className='text-[#F1F5F6] font-Gilroy400 text-sm'>{tradeInfo && tradeInfo.walletAddress ? tradeInfo.walletAddress: 'N/A'}</p>
                    </div>
                </div>
                <div className='flex justify-between mt-6'>
                    <div>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Trade Status</h6>
                        <div className='flex mt-1'>
                            <p className={tradeInfo && tradeInfo.tradeStatus ? getStatusColor(tradeInfo.tradeStatus) : 'pending-badge'}>{tradeInfo && tradeInfo.tradeStatus ? tradeInfo.tradeStatus: 'Created'}</p>
                        </div>

                    </div>
                    {
                    tradeInfo && tradeInfo.hostedUrl && tradeInfo.hostedUrl !== "" ?
                    <div className='text-right'>
                        <h6 className='text-[#F1F5F6] font-Gilroy500 text-sm'>Block Confirmation</h6>
                        <a href={tradeInfo && tradeInfo.hostedUrl ? tradeInfo.hostedUrl : ""} target="_blank" rel="noreferrer" className='flex justify-end gap-1 mt-1 items-center cursor-pointer'>
                            <p className='text-[#3B95FD] text-xs font-Gilroy400'>View Blockchain</p>
                            <img src={arrow_blockchain} alt="arrow blockchain" />
                        </a>
                    </div>
                    :
                    ""
                    }
                </div>

                <div className="mt-10">
                        <Link
                        to="/sellcoin"
                            className="bg-[#FF8400] 
                            disabled:bg-[#FF8400] 
                            disabled:opacity-[0.7]
                            text-sm flex justify-center
                            rounded-md w-full py-4 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all"
                        >
                            Start Another Trade
                        </Link>
                        </div>
                 </div>
        </ModalComponent>


            <div className='grid md:grid-cols-4 gap-4'>
                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#09C1DD] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={trending_up} alt="trending" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Total Balance</h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{countryTag === "GH" ? "GH₵" : "NGN"} {walletBalance ? numberWithCommas(walletBalance): 0.00}</h4>
                            </div>
                    </div>
                    <div className='bg-[#02B15A26] rounded-[10px] py-1 px-4 h-fit'>
                            <p className='text-[#02B15A] text-xs font-Gilroy400'>+1.29%</p>
                    </div>  
                </div>

                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#9CC031] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={earnings_icon} alt="earnings_icon" />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>
                                Amount Traded
                                </h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{countryTag === "GH" ? "GH₵" : "NGN"} {userSummary ? userSummary.totalCompletedTradeAmount : 0}</h4>
                            </div>
                    </div>
                   
                </div>

                <div className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex gap-4'>
                            <div className='bg-[#3B95FD] w-[45px] h-[45px] flex justify-center items-center rounded-[10px]'>
                                 <img src={layers_icon} alt="layers " />
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>
                                Pending Trades
                                </h6>
                                <h4 className='text-[#FFFFFF] text-lg'>{pendingTrade ? pendingTrade : 0}</h4>
                            </div>
                    </div>
                    <div className='cursor-pointer'>
                      <img src={arrow_right} alt="arrow right" />
                    </div>  
                </div>

                <Link to="/sellcoin" className='bg-[#262422] flex items-end justify-between shadow-[#F1F5F60A] rounded-[10px] py-6 px-4'>
                    <div className='flex items-center gap-4'>
                            <div className='bg-[#FF8400] w-[54px] h-[54px] flex justify-center items-center rounded-full bg-[#FF840026] p-1'>
                                 <div className='bg-[#FF8400] w-[45px] h-[45px] flex justify-center items-center rounded-full'>
                                   <img src={briefcase_icon} alt="briefcase icon" />
                                 </div>
                            </div>
                            <div>
                                <h6 className='text-[#EAECED80] font-Gilroy400 text-sm'>Start Trade</h6>
                            </div>
                    </div>
                     
                </Link>

           </div>

           <div className='bg-[#262422] rounded-[12px] mt-5 py-6 px-4'>
                <div className='flex flex-col md:flex-row md:justify-end gap-4 md:items-center'>
                    <div>
                    <input
                        type="text"
                        name="text"
                        placeholder="Search for any coin"
                        className='appearance-none w-full md:min-w-[400px] placeholder:text-[#EAECED80] placeholder:text-[13px] text-[#F1F5F680] text-sm  border border-[#454545] focus:outline-none rounded-md  bg-transparent py-3 px-4'
                        />
                    </div>
                    <div>
                        <button onClick={() => showModal()} className='flex w-full justify-center items-center gap-2 border border-[#FF8400] text-[#F1F5F6] font-Gilroy500 text-sm py-3 px-6 rounded-[5px]'>
                            <img className='w-[16px] h-[16px]' src={align_left_icon} alt="align left" />
                            <span>Filter By</span>
                        </button>
                    </div>
                </div>

                <div className='mt-4 md:mt-2'>
                    <DataTable
                        title="Trades"
                        pagination
                        progressPending={loading}
                        persistTableHead
                        columns={columns}
                        data={trades}
                        />
                </div>
            </div>
        </>
     );
}
 
export default TradePage;