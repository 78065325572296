import React,{useState, useEffect, useRef} from 'react'
import avatar from '../../assets/icons/avatar.svg'
import search_icon from '../../assets/icons/search.svg'
import logo_white from '../../assets/icons/logo-white.svg'
import home_active from '../../assets/icons/home_active.svg'
import home_icon from '../../assets/icons/home.svg'
import transactions_icon from '../../assets/icons/transactions_icon.svg'
import transactions_active from '../../assets/icons/tickets.svg'
import withdraw_icon from '../../assets/icons/withdraw_icon.svg'
import withdraw_active from '../../assets/icons/ideas.svg'
import sellcoin_icon from '../../assets/icons/articles.svg'
import sellcoin_active from '../../assets/icons/articles_active.svg'
import profile_icon from '../../assets/icons/agents.svg'
import profile_active from '../../assets/icons/agents_active.svg'
import account_icon from '../../assets/icons/stack-overflow.svg'
import account_active from '../../assets/icons/stack_active.svg'
import trade_icon from '../../assets/icons/brief.svg'
import trade_active from '../../assets/icons/brief_active.svg'
import security_icon from '../../assets/icons/security-safe.svg'
import security_active from '../../assets/icons/security-safe_active.svg'
import logout_icon from '../../assets/icons/logout.svg'
import money_transfer from '../../assets/icons/Money_transfer.svg'
import menu_icon from '../../assets/icons/hamburger.svg'
import { Link,useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Logout } from '../../redux/Auth/auth'
import './sidebar.css'
import close_icon from '../../assets/icons/x-circle.svg'


const Sidebar = ({title, children}) => {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {userInfo, imageUrl, isSetPin} = useSelector((state) => state.auth);
    const ref = useRef()
    const [sideShow, setSideShow] = useState(false);

    const handleLogout = () => {
        dispatch(Logout())
        navigate('/')
    }

    const toggleMobileMenu = () =>{
        setSideShow(sideShow ? false : true);
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setSideShow(false);
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, setSideShow])

      useEffect(()=>{
            setSideShow(false)
      },[location])



    return (  
        <>
            <div className='flex h-full min-h-screen bg-[#17161B]'>
               <div className='bg-[#262422] md:w-64 flex-shrink-0 oveflow-y-scroll py-6 hidden lg:block'>
                     <div className='flex justify-center'>
                        <img src={logo_white} alt="logo" className='h-[50px]' />
                    </div>

                <div className='flex flex-col justify-between mt-10'>
                    <div className=''>
                        <Link to="/dashboard" 
                            className={
                                location.pathname.includes('/dashboard')
                                ?
                                "flex items-center w-full py-4 px-6 bg-[#FF8400] bg-opacity-[8%] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                            `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/dashboard')
                                    ?
                                    home_active : home_icon
                                    } alt="dashboard icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Dashboard</span>
                        </Link>

                        <Link to="/transactions" 
                            className={
                                location.pathname.includes('/transactions')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/transactions')
                                    ?
                                    transactions_active
                                    :
                                    transactions_icon
                                } alt="transactions icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Transactions</span>
                        </Link>

                        <Link to="/withdraw" 
                            className={
                                location.pathname.includes('/withdraw')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                            `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                      location.pathname.includes('/withdraw')
                                      ?
                                      withdraw_active
                                      :
                                    withdraw_icon} alt="withdraw icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Withdraw</span>
                        </Link>

                        <Link to="/sellcoin" 
                            className={
                                location.pathname.includes('/sellcoin')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/sellcoin') ?
                                    sellcoin_active :
                                    sellcoin_icon
                                    } alt="sellcoin icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Sell Coins</span>
                        </Link>

                        <Link to="/profile" 
                            className={
                                location.pathname.includes('/profile')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                     location.pathname.includes('/profile')
                                     ?
                                     profile_active :
                                    profile_icon} alt="profile icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Profile</span>
                        </Link>

                        <Link to="/account-details" 
                            className={
                                location.pathname.includes('/account-details')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/account-details')
                                    ?
                                    account_active :
                                    account_icon} alt="account icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Account Details</span>
                        </Link>

                        <Link to="/trades" 
                            className={
                                location.pathname.includes('/trades')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/trades')
                                    ?
                                    trade_active :
                                    trade_icon} alt="trade icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Trade</span>
                        </Link>

                        <Link to="/security" 
                            className={
                                location.pathname.includes('/security')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/security')
                                    ?
                                    security_active :
                                    security_icon} alt="security icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Security</span>
                        </Link>

                    </div>
                    <div className='mt-20'>
                        <div className='px-4'>
                            <div className='bg-[#FFFFFF14] shadow-[#0000000A] rounded-[10px] py-6 px-4 min-h-[100px] relative'>
                                <a href="https://blog.dartafrica.io/" target="_blank" rel="noreferrer" className='bg-[#FF8400] 
                                    disabled:bg-[#FF8400] 
                                    disabled:opacity-[0.7]
                                    text-sm flex justify-center
                                    rounded-md w-full py-4 px-6 text-[#fff] font-Gilroy500 hover:bg-[#FF8400] hover:opacity-[0.9] transition-all'>Visit Blog</a>

                                <div className='mt-4 max-w-[110px]'>
                                    <h4 className='text-[#F1F5F6] opacity-[0.7] font-Gilroy700 text-lg'>
                                    Trending Crypto Updates
                                    </h4>
                                </div>

                                <img src={money_transfer} alt="money_transfer" className='absolute -bottom-4 -right-3' />
                            </div>
                        </div>
                      
                        <div className='bg-[#F1F5F6] w-full h-[1px] mt-10'></div>

                            <div  onClick={handleLogout} 
                                className="flex cursor-pointer items-center w-full mt-3 py-4 px-6 font-Satoshi400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300"
                                >
                                    <img src={logout_icon} alt="logout icon" className='w-[18px] h-[18px]' />
                                    <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Logout</span>
                            </div>
                       
                    </div>
                </div>
               </div>

                {/* mobile toggle */}      
                <div ref={ref} 
                    className={
                        sideShow ?
                        'bg-[#262422] w-64 fixed z-10 userside show flex-shrink-0 oveflow-y-scroll py-6 md:hidden'
                        :
                        'bg-[#262422] w-64 fixed z-10 userside flex-shrink-0 oveflow-y-scroll py-6 md:hidden'
                        }
                >
                 <div  className='flex justify-between items-center px-4'>
                    <div>
                       <img src={logo_white} alt="logo" className='h-[40px]' />
                    </div>
                     <div
                    onClick={toggleMobileMenu}
                     className='cursor-pointer bg-[#EDEDED] w-[25px] h-[25px] rounded-full flex items-center justify-center' >
                            <img src={close_icon} alt="close" />
                    </div>
                </div>

                 <div className='flex flex-col justify-between mt-8'>
                    <div className=''>
                        <Link to="/dashboard" 
                            className={
                                location.pathname.includes('/dashboard')
                                ?
                                "flex items-center w-full py-4 px-6 bg-[#FF8400] bg-opacity-[8%] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                            `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/dashboard')
                                    ?
                                    home_active : home_icon
                                    } alt="dashboard icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Dashboard</span>
                        </Link>

                        <Link to="/transactions" 
                            className={
                                location.pathname.includes('/transactions')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/transactions')
                                    ?
                                    transactions_active
                                    :
                                    transactions_icon
                                } alt="transactions icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Transactions</span>
                        </Link>

                        <Link to="/withdraw" 
                            className={
                                location.pathname.includes('/withdraw')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                            `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                      location.pathname.includes('/withdraw')
                                      ?
                                      withdraw_active
                                      :
                                    withdraw_icon} alt="withdraw icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Withdraw</span>
                        </Link>

                        <Link to="/sellcoin" 
                            className={
                                location.pathname.includes('/sellcoin')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/sellcoin') ?
                                    sellcoin_active :
                                    sellcoin_icon
                                    } alt="sellcoin icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Sell Coins</span>
                        </Link>

                        <Link to="/profile" 
                            className={
                                location.pathname.includes('/profile')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                     location.pathname.includes('/profile')
                                     ?
                                     profile_active :
                                    profile_icon} alt="profile icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Profile</span>
                        </Link>

                        <Link to="/account-details" 
                            className={
                                location.pathname.includes('/account-details')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/account-details')
                                    ?
                                    account_active :
                                    account_icon} alt="account icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Account Details</span>
                        </Link>

                        <Link to="/trades" 
                            className={
                                location.pathname.includes('/trades')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/trades')
                                    ?
                                    trade_active :
                                    trade_icon} alt="trade icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Trade</span>
                        </Link>

                        <Link to="/security" 
                            className={
                                location.pathname.includes('/security')
                                ?
                                "flex items-center w-full py-4 mt-2 px-6 bg-[#FF8400] bg-opacity-[8%] text-[#F1F5F6] font-Gilroy400 border-r-2 border-[#FF8400]"
                                :
                                `${isSetPin ? "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300" : "flex items-center w-full mt-2 py-4 px-6 text-[#F1F5F6] font-Gilroy400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300 pointer-events-none"}`
                            }>
                                <img src={
                                    location.pathname.includes('/security')
                                    ?
                                    security_active :
                                    security_icon} alt="security icon" className='w-[18px] h-[18px]' />
                                <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Security</span>
                        </Link>

                    </div>
                    <div className='mt-6'>
                        <div className='bg-[#F1F5F6] w-full h-[1px] mt-8'></div>
                            <div  onClick={handleLogout} 
                                className="flex cursor-pointer items-center w-full mt-3 py-4 px-6 font-Satoshi400 hover:bg-[#F7F8FC] hover:bg-opacity-[8%] duration-300"
                                >
                                    <img src={logout_icon} alt="logout icon" className='w-[18px] h-[18px]' />
                                    <span className="ml-4 text-sm text-[#F1F5F6] font-Gilroy400">Logout</span>
                            </div>
                    </div>
                </div>

              </div>
                
              

               <div className='py-6 flex-grow flex-auto flex-shrink overflow-y-scroll'>
                    {/* navbar */}
                    <div className='flex px-4 md:px-6 justify-between items-center'>
                        <div className='flex gap-3 items-center'>
                            <div onClick={toggleMobileMenu} className='cursor-pointer block md:hidden'>
                                <img src={menu_icon} alt="menu" />
                            </div>
                            <div>
                                <h6 className='text-[#F1F5F6] font-Gilroy500 text-lg'>{title}</h6>
                            </div>
                        </div>
                       
                        <div className='flex gap-4 items-center'>
                            <div className='cursor-pointer'>
                                <img src={search_icon} alt="search" />
                            </div>
                            <div>
                                <p className='text-[#F1F5F6] text-sm font-Gilroy400'>{userInfo ? userInfo.username: 'N/A'}</p>
                            </div>
                            <div className='relative'>
                                {
                                    imageUrl && imageUrl !== ""
                                    ?
                                    <img src={imageUrl} alt="avatar" className='w-[44px] h-[44px] rounded-full border border-[#F7F8FC] p-1' />
                                    :
                                    <img src={avatar} alt="avatar" className='w-[44px] h-[44px] rounded-full' />
                                }
                                <div className='bg-[#FF8400] w-[6px] h-[6px] rounded-full absolute right-1 top-0 border border-[#F7F8FC] p-1'></div>
                            </div>

                        </div>  
                    </div>

                    <div className='px-4 md:px-6 py-6'>
                        {children}
                    </div>

               </div>

            </div>
        </>
    );  
}
 
export default Sidebar;